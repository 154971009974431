// Library Imports
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router-dom";
import ta from "time-ago";

// Component Imports
import VideoCard from "../../components/VideoCard";
import FolderCard from "../../components/FolderCard";
import VideoLoader from "../../components/VideoLoader";
import NewInterviewBtn from "../../components/NewInterviewBtn";

const Dashboard = ({ loading, interviews, folders, openDialog }) => {
  const navigate = useNavigate();

  return (
    <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
      <main className="flex-1 mt-16 md:mt-0 py-8 md:py-40 px-6 md:px-32 2xl:px-60">
        <div className="hidden md:flex absolute top-5 right-12 flex space-x-2">
          <>
            <button
              onClick={() => openDialog()}
              data-tip="New folder"
              className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-700 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                />
              </svg>
            </button>
            <ReactTooltip
              effect="solid"
              backgroundColor="#475569"
              className="tooltip"
              arrowColor="transparent"
            />
          </>
          <NewInterviewBtn/>
        </div>
        <div className="w-full">
          <div className="md:mb-20 mb-4 flex">
            <h1 className="font-sans text-3xl font-semibold text-gray-900 flex-7 w-90">
              Personal Library
            </h1>
            <div className="flex-1 w-10 ">
              <button
                onClick={() => openDialog()}
                data-tip="New folder"
                className=" md:hidden flex justify-end relative top-1.5 p-2 w-full  md:hover:bg-gray-100 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-700 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                  />
                </svg>
              </button>
              <ReactTooltip
                effect="solid"
                backgroundColor="#475569"
                className="tooltip hidden md:flex"
                arrowColor="transparent"
              />

          </div>
        </div>

          {folders.length > 0 && (
            <div className="w-full flex flex-col">
              <div className="w-full md:pb-4 border-b border-gray-100 flex justify-start font-sans font-medium text-gray-900">
                Folders
                {/* <>
                  <button
                    onClick={() => openDialog()}
                    data-tip="New folder"
                    className=" md:hidden flex justify-end relative bottom-1.5 p-2 w-full  hover:bg-gray-100 "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-700 "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                      />
                    </svg>
                  </button>
                  <ReactTooltip
                    effect="solid"
                    backgroundColor="#475569"
                    className="tooltip"
                    arrowColor="transparent"
                  />
                </> */}
              </div>
              <div className="mt-4 w-full pb-20 grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4">
                {folders.map((folder) => (
                  <FolderCard
                    numberOfInterviews={folder.interviews.length}
                    title={folder.name}
                    date={ta.ago(folder.created)}
                    id={folder._id}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="w-full flex flex-col">
            <div className="w-full md:pb-4 border-b border-gray-100 flex justify-start font-sans font-medium text-gray-900">
              Interviews
              <div className="flex md:hidden w-full justify-end">
                <NewInterviewBtn />
              </div>
            </div>
          </div>

          {/* If data is loading */}
          {loading && <VideoLoader />}

          {/* If data has loaded, but there are not interviews */}
          {!loading && interviews.length < 1 && (
            <div className="w-full bg-primary rounded-md py-32 mt-6">
              <div className="flex flex-col px-4 justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 text-gray-900 mb-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="font-sans text-lg text-gray-900 font-semibold mb-2">
                  Your library is currently empty.
                </span>
                <span className="font-sans text-sm text-gray-500 font-normal mb-4">
                  Record and share your first interview or start by checking the
                  example below.
                </span>
                <a
                  href="https://app.usehaste.io/answer/61fd59c437c330f58f712d83"
                  target="_blank"
                >
                  <button className="py-2 px-4 bg-white border border-gray-200 rounded-full text-xs text-gray-700 font-sans font-semibold  hover:border-gray-300 hover:text-gray-900">
                    View demo
                  </button>
                </a>
              </div>
            </div>
          )}

          {/* If data has loaded, and there are more than 0 interviews */}
          {!loading && interviews.length > 0 && (
            <div className="mt-4 w-full pb-20 grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-5 gap-y-10">
              {interviews.map((interview) => (
                <VideoCard
                  title={interview.title}
                  date={ta.ago(interview.time)}
                  thumbnailImage={interview.thumbnails.image}
                  thumbnailGif={interview.thumbnails.gif}
                  url={`/interviews/${interview._id}`}
                  live={interview.live}
                  responses={interview.answerSets.length}
                />
              ))}
            </div>
          )}

          {/* {interviews.length > 0 ? (
            <div className="mt-4 w-full pb-20 grid grid-cols-3 2xl:grid-cols-4 gap-5 gap-y-10">
              {interviews.map((interview) => (
                <VideoCard
                  title={interview.title}
                  date={ta.ago(interview.time)}
                  thumbnailImage={interview.thumbnails.image}
                  thumbnailGif={interview.thumbnails.gif}
                  url={`/interviews/${interview._id}`}
                  live={interview.live}
                  responses={interview.answerSets.length}
                />
              ))}
            </div>
          ) : (
            <div className="w-full bg-primary rounded-md py-32 mt-6">
              {loading ? (
                <div className="flex flex-col justify-center items-center py-20">
                  <svg
                    className="animate-spin h-8 w-8 text-gray-900"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 text-gray-900 mb-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="font-sans text-lg text-gray-900 font-semibold mb-2">
                    Your library is currently empty.
                  </span>
                  <span className="font-sans text-sm text-gray-500 font-normal mb-4">
                    Record and share your first interview or start by checking
                    the example below.
                  </span>
                  <a
                  // href="https://app.usehaste.io/answer/61d590ab644e14c90061c0c7"
                  // target="_blank"
                  >
                    <button className="py-2 px-4 bg-white border border-gray-200 rounded-full text-xs text-gray-700 font-sans font-semibold  hover:border-gray-300 hover:text-gray-900">
                      View demo
                    </button>
                  </a>
                </div>
              )}
            </div>
          )} */}
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
