// Library Imports
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// Component Imports
import SideMenu from "../../components/SideMenu";
import Dashboard from "./dashboard";
import QuestionDialog from "../../components/QuestionDialog";
import MoveFolderDialog from "./MoveFolderDialog";
import { AuthContext } from "../../context/Auth";
import AnswerDialog from "./AnswerDialog";
import DeleteAnswerDialog from "./DeleteAnswerDialog";
import ActionDialog from "../../components/ActionDialog";

const IndividualInterview = () => {
  // Variables
  const navigate = useNavigate();
  const { interviewId } = useParams();
  const { currentUser } = useContext(AuthContext);

  // States
  const [interview, setInterview] = useState({});
  const [answerSets, setAnswerSets] = useState([]);
  const [questionDialog, setQuestionDialog] = useState(false);

  // Folder States
  const [moveFolderDialog, setMoveFolderDialog] = useState(false);
  const [folders, setFolders] = useState([]);

  // View questions states
  const [showIndex, setShowIndex] = useState(0);

  const [selected, setSelected] = useState();

  // Toggle states
  const [enabled, setEnabled] = useState();

  // Answer States
  const [answerDialog, setAnswerDialog] = useState(false);
  const [answerData, setAnswerData] = useState();
  const [showAnswerIndex, setShowAnswerIndex] = useState(0);
  const [showDeleteAnswerDialog, setShowDeleteAnswerDialog] = useState(false);
  const [answerToBeDeleted, setAnswerToBeDeleted] = useState();

  // Dialog States
  const [deleteInterviewDialog, setDeleteInterviewDialog] = useState(false);
  const [archiveInterviewDialog, setArchiveInterviewDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  const selectFolder = (id) => {
    setSelected(id);
  };

  // Function 1 - Fetch interview data on load.
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/interviews/${interviewId}`)
      .then((res) => res.json())
      .then((data) => {
        setInterview(data.interview);

        if (data.subscriptionStatus === "cancelled") {
          setEnabled(false);
        } else {
          setEnabled(data.interview.live);
        }
      });
  }, []);

  // Function 2 - Fetch the answer data from the database on first render using useEffect
  useEffect(() => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/answerSets?interviewId=${interviewId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setAnswerSets(data);
        setLoading(false);
      });
  }, [answerDialog]);

  // Function 3 - Fetch all folders
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/folders/all-folders?userId=${currentUser.uid}`
    )
      .then((res) => res.json())
      .then((data) => {
        setFolders(data);
      });
  }, []);

  // Function 3 - Move interview to a folder
  const moveToFolder = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_SERVER_URL}/folders/${selected}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ interviewId }),
    })
      .then((res) => res.json())
      .then(() => {
        toast.success("Interview moved!");
        navigate("/interviews");
        setLoading(false);
      });
  };

  // Archive an interview
  const openArchiveInterviewDialog = () => {
    setArchiveInterviewDialog(true);
  };

  const closeArchiveInterviewDialog = () => {
    setArchiveInterviewDialog(false);
  };

  const onArchiveInterview = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/folders/default-folders/archive?userId=${currentUser.uid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ interviewId }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        toast.success("Interview archived!");
        navigate("/interviews");
        setLoading(false);
      });
  };

  // Question Dialog
  const openQuestionDialog = () => {
    setQuestionDialog(true);
  };

  const closeQuestionDialog = () => {
    setQuestionDialog(false);
    setTimeout(() => {
      setShowIndex(0);
    }, [1000]);
  };

  // Open Move Folder Dialog
  const openMoveFolderDialog = () => {
    setMoveFolderDialog(true);
  };

  // Close Move Folder Dialog
  const closeMoveFolderDialog = () => {
    setMoveFolderDialog(false);
    setTimeout(() => {
      setSelected();
    }, [1000]);
  };

  // Question Index Functions

  // Function 1 - Go next question
  const onNextQuestion = () => {
    setShowIndex(showIndex + 1);
  };

  // Function 2 - Go previous question
  const onPreviousQuestion = () => {
    setShowIndex(showIndex - 1);
  };

  // Toggle states

  const handleEnabled = () => {
    setEnabled(!enabled);

    fetch(`${process.env.REACT_APP_SERVER_URL}/interviews/${interviewId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ enabled }),
    });
  };

  // ANSWER DIALOG FUNCTIONS

  // Answer Dialog
  const openAnswerDialog = (answerSetId) => {
    setAnswerDialog(true);

    fetch(`${process.env.REACT_APP_SERVER_URL}/answerSets/${answerSetId}`)
      .then((res) => res.json())
      .then((data) => setAnswerData(data));

    markAsWatched(answerSetId);
  };

  const closeAnswerDialog = () => {
    setAnswerDialog(false);
    setShowIndex(0);
    setShowAnswerIndex(0);
  };

  // Answer Indexes
  const onNextAnswer = () => {
    setShowAnswerIndex(showAnswerIndex + 1);
    onNextQuestion();
  };

  // Function 2 - Go previous question
  const onPreviousAnswer = () => {
    setShowAnswerIndex(showAnswerIndex - 1);
    onPreviousQuestion();
  };

  // Delete an answerSet
  const onDeleteAnswer = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/answerSets/${answerToBeDeleted}?interviewId=${interviewId}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        toast.success("Answer deleted!");
        setAnswerSets(data);
        closeDeleteAnswerDialog();
        setAnswerToBeDeleted();
      });
  };

  const openDeleteAnswerDialog = (answerSetId) => {
    setAnswerToBeDeleted(answerSetId);
    setShowDeleteAnswerDialog(true);
    setAnswerDialog(false);
  };

  const closeDeleteAnswerDialog = () => {
    setShowDeleteAnswerDialog(false);
    setAnswerToBeDeleted();
  };

  // Set watched to answerSet
  const markAsWatched = async (answerSetId) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/answerSets/${answerSetId}`, {
      method: "PATCH",
    });
  };

  // Delete an interview

  const openDeleteInterviewDialog = () => {
    setDeleteInterviewDialog(true);
  };

  const closeDeleteInterviewDialog = () => {
    setDeleteInterviewDialog(false);
  };

  const onDeleteInterview = () => {
    setLoading(true);

    fetch(
      `${process.env.REACT_APP_SERVER_URL}/interviews/${interviewId}?userId=${currentUser.uid}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        toast.success("Interview deleted!");
        navigate("/interviews");
        setLoading(false);
      });
  };

  return (
    <div>
      <ActionDialog
        title={"Archive interview"}
        subtitle={
          "If you continue, this interview will become private and it won't receive any responses."
        }
        isOpen={archiveInterviewDialog}
        closeDialog={closeArchiveInterviewDialog}
        btnTitle={"Archive"}
        btnStyle={"bg-blue-600 hover:bg-blue-700"}
        action={onArchiveInterview}
        loading={loading}
        loadingStyle={"bg-blue-300 pointer-events-none"}
        loadingText={"Archiving"}
      />
      <ActionDialog
        title={"Delete interview"}
        subtitle={
          "If you continue, this interview and everything previously recorded will be deleted."
        }
        btnTitle={"Delete"}
        btnStyle={"bg-red-600 hover:bg-red-700"}
        isOpen={deleteInterviewDialog}
        closeDialog={closeDeleteInterviewDialog}
        action={onDeleteInterview}
        loading={loading}
        loadingStyle={"bg-red-300 pointer-events-none"}
        loadingText={"Deleting"}
      />
      <DeleteAnswerDialog
        isOpen={showDeleteAnswerDialog}
        closeDialog={closeDeleteAnswerDialog}
        onDeleteAnswer={onDeleteAnswer}
        loading={loading}
      />
      <SideMenu current={"interviews"} />
      <Dashboard
        interview={interview}
        answerSets={answerSets}
        moveToFolder={moveToFolder}
        openQuestionDialog={openQuestionDialog}
        openMoveFolderDialog={openMoveFolderDialog}
        enabled={enabled}
        handleEnabled={handleEnabled}
        openAnswerDialog={openAnswerDialog}
        openDeleteInterviewDialog={openDeleteInterviewDialog}
        openArchiveInterviewDialog={openArchiveInterviewDialog}
        loading={loading}
      />
      <AnswerDialog
        answerData={answerData}
        isOpen={answerDialog}
        closeDialog={closeAnswerDialog}
        showAnswerIndex={showAnswerIndex}
        onNextAnswer={onNextAnswer}
        onPreviousAnswer={onPreviousAnswer}
        interview={interview}
        showIndex={showIndex}
        openDeleteAnswerDialog={openDeleteAnswerDialog}
      />
      <Toaster position="top-center" reverseOrder={false} />
      <MoveFolderDialog
        isOpen={moveFolderDialog}
        closeDialog={closeMoveFolderDialog}
        folders={folders}
        selected={selected}
        selectFolder={selectFolder}
        moveToFolder={moveToFolder}
        interview={interview}
        loading={loading}
      />
      <QuestionDialog
        isOpen={questionDialog}
        closeDialog={closeQuestionDialog}
        onNextQuestion={onNextQuestion}
        onPreviousQuestion={onPreviousQuestion}
        showIndex={showIndex}
        interview={interview}
      />
    </div>
  );
};

export default IndividualInterview;
