import React, { useEffect, useState } from 'react';
import SideMenu from '../../components/SideMenu';
import AccessControl from './AccessControl';


const Index = () => {


  return (
    <div>
      <SideMenu current={"admin"} />
      <AccessControl />
    </div>
  );
}

export default Index;