import React, { useEffect, useState } from 'react';
import SideMenu from '../../components/SideMenu';
import Subscriptionmanage from './subscription_manage';



const Index = () => {


  return (
    <div>
      <SideMenu current={"subscription"} />
      <Subscriptionmanage/>
     
    </div>
  );
}

export default Index;