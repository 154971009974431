const VideoLoader = () => {
  return (
    <div className="mt-4 w-full pb-20 grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-5 gap-y-10">
      <div className="w-full h-80 rounded-xl bg-white border border-gray-100 flex flex-col relative animate-pulse">
        <div className="w-full h-44 bg-gray-200 rounded-tr-xl rounded-tl-xl"></div>
        <div className="w-full p-4 flex flex-col">
          <div className="mt-4 w-40 h-2 rounded-full bg-gray-200"></div>
          <div className="mt-3 w-56 h-2 rounded-full bg-gray-200"></div>
          <div className="absolute bottom-4 w-20 h-2 rounded-full bg-gray-200"></div>
        </div>
      </div>

      {/* Second */}
      <div className="w-full h-80 rounded-xl bg-white border border-gray-100 flex flex-col relative animate-pulse">
        <div className="w-full h-44 bg-gray-200 rounded-tr-xl rounded-tl-xl"></div>
        <div className="w-full p-4 flex flex-col">
          <div className="mt-4 w-40 h-2 rounded-full bg-gray-200"></div>
          <div className="mt-3 w-56 h-2 rounded-full bg-gray-200"></div>
          <div className="absolute bottom-4 w-20 h-2 rounded-full bg-gray-200"></div>
        </div>
      </div>

      {/* Third */}
      <div className="w-full h-80 rounded-xl bg-white border border-gray-100 flex flex-col relative animate-pulse">
        <div className="w-full h-44 bg-gray-200 rounded-tr-xl rounded-tl-xl"></div>
        <div className="w-full p-4 flex flex-col">
          <div className="mt-4 w-40 h-2 rounded-full bg-gray-200"></div>
          <div className="mt-3 w-56 h-2 rounded-full bg-gray-200"></div>
          <div className="absolute bottom-4 w-20 h-2 rounded-full bg-gray-200"></div>
        </div>
      </div>

      {/* Fourth */}
      <div className="w-full h-80 rounded-xl bg-white border border-gray-100 flex flex-col relative animate-pulse">
        <div className="w-full h-44 bg-gray-200 rounded-tr-xl rounded-tl-xl"></div>
        <div className="w-full p-4 flex flex-col">
          <div className="mt-4 w-40 h-2 rounded-full bg-gray-200"></div>
          <div className="mt-3 w-56 h-2 rounded-full bg-gray-200"></div>
          <div className="absolute bottom-4 w-20 h-2 rounded-full bg-gray-200"></div>
        </div>
      </div>
    </div>
  );
};

export default VideoLoader;
