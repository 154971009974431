import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import { UserContext } from "../context/User";

const PrivateRouteAdmin = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { userType } = useContext(UserContext);
  console.log(currentUser,userType,"currentUser")

  return userType=='admin' ? children : <Navigate to="/" />;
};

export default PrivateRouteAdmin;
