import { useState } from "react";

const NameScreen = ({ showEmailScreen }) => {
  const [name, setName] = useState();

  const handleNameInput = (e) => {
    setName(e.target.value);
  };

  return (
    <div className="flex flex-col max-h-full w-full">
      <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2 px-8">
        <span>Interview</span>
        <span>·</span>
        <span>User Details</span>
      </div>
      <div className="font-sans font-semibold text-lg text-gray-900 mb-5 px-8">
        What is your name?
      </div>
      <div className="px-8 w-full">
        <input
          onChange={(e) => handleNameInput(e)}
          placeholder="John Doe"
          className="h-12 w-full mt-2 border border-gray-200 rounded-xl font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
        />
      </div>
      <div className="absolute bottom-0 w-full border-t border-gray-200 p-4">
        <button
          onClick={() => showEmailScreen(name)}
          className={` ${
            !name
              ? "bg-blue-200 pointer-events-none"
              : "bg-blue-600 hover:bg-blue-700"
          } w-full h-10 rounded-full  flex space-x-1 justify-center items-center `}
        >
          <span className="font-sans font-semibold text-white text-sm">
            Next
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NameScreen;
