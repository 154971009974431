import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ButtonLoader from "./ButtonLoader";

const ActionDialog = ({
  title,
  subtitle,
  isOpen,
  closeDialog,
  btnTitle,
  btnStyle,
  action,
  loading,
  loadingStyle,
  loadingText,
}) => {
  return (
    <Transition
      as={Fragment}
      show={isOpen}
      enter="ease-out duration-300"
      enterFrom="opacity-10"
      enterTo="opacity-100"
      leave="ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        open={isOpen}
        onClose={() => closeDialog()}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-20 z-40" />
          <div className="w-10/12 md:w-full max-w-md bg-white z-50 rounded-2xl">
            <div className="w-full px-4 py-2 flex justify-between items-center border-b border-gray-200">
              <span className="font-sans font-semibold text-gray-900 text-sm">
                {title}
              </span>
              <div
                onClick={() => closeDialog()}
                className="px-3 py-2 rounded-full hover:bg-gray-100 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="p-4 flex flex-col">
              <span className="font-sans font-medium text-gray-900 text-sm leading-6">
                {subtitle}
              </span>
              <div className="mt-10 w-full flex justify-center space-x-2 items-center">
                <button
                  onClick={() => closeDialog()}
                  className="w-1/2 bg-gray-100 rounded-full h-10 font-sans font-medium text-gray-900 text-sm hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  onClick={() => action()}
                  className={`${
                    loading ? loadingStyle : btnStyle
                  } w-1/2  rounded-full h-10 font-sans font-semibold text-white text-sm flex justify-center items-center`}
                >
                  {loading ? <ButtonLoader text={loadingText} /> : btnTitle}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ActionDialog;
