// Library Imports
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";

// Component Imports
import SideMenu from "../../components/SideMenu";
import Dashboard from "./dashboard";
import { AuthContext } from "../../context/Auth";
import ActionDialog from "../../components/ActionDialog";

const Folder = () => {
  const { folderId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [folderData, setFolderData] = useState({});
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  // Function 1 - Fetch folder data on load.

  useEffect(async () => {
    setFetchLoading(true);
    await fetch(
      `${process.env.REACT_APP_SERVER_URL}/folders/folder/${folderId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setFolderData(data.folder);
        setFetchLoading(false);
      });
  }, []);

  const deleteFolder = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/folders/${folderId}?userId=${currentUser.uid}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then(() => {
        navigate("/interviews");
        toast.success("Folder deleted!");
        setLoading(false);
      });
  };

  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => {
    setDialog(false);
  };

  return (
    <div>
      <ActionDialog
        title={"Delete folder"}
        subtitle={
          "If you delete this folder, the folder and all interviews in it will be permanently deleted."
        }
        isOpen={dialog}
        closeDialog={closeDialog}
        btnTitle={"Delete"}
        btnStyle={"bg-red-600 hover:bg-red-700"}
        action={deleteFolder}
        loading={loading}
        loadingStyle={"bg-red-300 pointer-events-none"}
        loadingText={"Deleting"}
      />
      <Toaster position="top-center" reverseOrder={false} />
      <SideMenu current={"interviews"} />
      <Dashboard
        folder={folderData}
        openDialog={openDialog}
        loading={fetchLoading}
      />
    </div>
  );
};

export default Folder;
