import ta from "time-ago";
import { useState } from "react";

const NoteCard = ({ note, edit, editNote, updateNote }) => {
  const [newNote, setNewNote] = useState();

  const handleNewNote = (e) => {
    setNewNote(e.target.value);
  };

  return (
    <div className="bg-white rounded-lg p-6 max-h-full shadow-sm relative mb-6">
      <div className="mb-8 flex justify-between items-start">
        <div className="flex flex-col space-y-1">
          <span className="font-sans font-medium text-gray-900 text-sm">
            Quick note
          </span>
          <span className="font-sans font-normal text-gray-500 text-xs">
            So you don't forget later.
          </span>
        </div>
        {edit ? (
          <button
            onClick={() => updateNote(newNote)}
            className="px-4 py-2 rounded-md bg-gray-100 font-sans font-medium text-gray-900 text-xs hover:bg-gray-200"
          >
            Save
          </button>
        ) : (
          <button
            onClick={() => editNote()}
            className="px-4 py-2 rounded-md items-center bg-gray-100 font-sans font-medium text-gray-900 text-xs hover:bg-gray-200"
          >
            New
          </button>
        )}
      </div>
      {edit ? (
        <textarea
          onChange={(e) => handleNewNote(e)}
          placeholder="Add a new note..."
          className="w-full h-40 md:h-56 mb-8 font-sans font-medium text-gray-700 text-xs leading-6 overflow-auto no-scrollbar outline-none resize-none"
        ></textarea>
      ) : (
        <div className=" relative">
          <div className="h-40 md:h-56 mb-8 overflow-auto no-scrollbar font-sans font-medium text-gray-700 text-xs leading-6 ">
            {note.content}
          </div>
          <div className="fade"></div>
        </div>
      )}
      <div className="absolute font-sans font-medium text-gray-400 text-xs bottom-5">
        Last edited {ta.ago(note.lastEdit)}
      </div>
    </div>
  );
};

export default NoteCard;
