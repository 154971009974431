import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../context/Auth";
import Api from "../../Api";
import { toast } from "react-hot-toast";

const api = Api();
const SupscribeModal = ({ isVisible, onClose, subscription }) => {
  const [isTrialChecked, setTrialChecked] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const handleCheckboxChange = () => {
    setTrialChecked(!isTrialChecked);
  };

  const handleConfirmAction = () => {
    api
      .postApi("subscriptions/free-plan", {
        userId: currentUser.uid,
        subscription: subscription,
      })
      .then((data) => {
        onClose();
        toast.success("Activated free plan");
      })
      .then((error) => {
        error && console.log(error);
      });
  };

  return (
    isVisible && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-4xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {/* {modalHeaderText} */}
                  Confirmation
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={onClose}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-8 gap-6">
                  <div className="col-span-12 sm:col-span-12">
                    <div className="subscription-popup">
                      <p>
                        {/* Upgrade to our {subscription.subscriptionname} plan.
                        Initially, enjoy a <strong>21-day free trial</strong>. */}

                        Upgrade to our Pro plan today and enjoy an exclusive <strong> 21-day free trial </strong>.
                        Following the trial period, the Pro plan will be auto-renewed.
                      </p>

                      {/* {isTrialChecked && ( */}
                      <p>
                        {" "}
                        After the free trial, {
                          subscription.subscriptionname
                        }{" "}
                        plan will auto-renew.
                      </p>
                      {/* )} */}
                      <br></br>
                      <label>
                        <input
                          type="checkbox"
                          checked={isTrialChecked}
                          onChange={handleCheckboxChange}
                        />{" "}
                        I confirm and agree to the auto renew.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  type="submit"
                  className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => handleConfirmAction()}
                  disabled={!isTrialChecked}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default SupscribeModal;
