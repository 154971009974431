import { useNavigate } from "react-router-dom";

const FolderCard = ({ title, date, numberOfInterviews, id }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/interviews/folders/${id}`)}
      className="w-full border rounded-md border-gray-100 p-4 flex flex-col cursor-pointer hover:bg-gray-50 hover:scale-105 transform transition duration-200"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-10 h-10 fill-current text-blue-400"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M22 8v12a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7h19a1 1 0 0 1 1 1zm-9.586-3H2V4a1 1 0 0 1 1-1h7.414l2 2z" />
        </svg>
      </div>
      <span className="mt-6 font-sans font-medium text-gray-900 text-sm max-w-full truncate">
        {title}
      </span>
      <div className="flex justify-between items-center mt-2">
        <span className="font-sans font-medium text-gray-400 text-xs">
          Created {date}
        </span>
        <span className="font-sans font-medium text-xs text-gray-400">
          ({numberOfInterviews})
        </span>
      </div>
    </div>
  );
};

export default FolderCard;
