// Library Imports
import { useEffect, useState, useContext } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// Component Imports
import Dashboard from "./Dashboard";
import SideMenu from "../../components/SideMenu";
import Mobile from "../../components/Mobile";
import Api from "../../Api";
import { AuthContext } from "../../context/Auth";
import { UserContext } from "../../context/User";
import { useUserStatus } from "../../context/UserStatusContext";
const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const { userType } = useContext(UserContext);

  const { userStatus, updateUserStatus } = useUserStatus();
  const api = Api();
  const navigate = useNavigate();

  // Note States
  const [note, setNote] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState();

  // Interviews States
  const [interviews, setInterviews] = useState([]);

  // AnswerSets States
  const [answerSets, setAnswerSets] = useState([]);

  useEffect(async () => {
    getuser();
    currentUser &&
      (await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/${currentUser.uid}`
      )
        .then((res) => res.json())
        .then((data) => {
          setSubscriptionStatus(data.subscriptionStatus);
        }));
  }, [loading]);

  const getuser = async () => {
    const user = await api.getApi(`users/${currentUser.uid}`);

    console.log(user, " userStatus");
    updateUserStatus();
    if(user.subscriptionStatus == 'Inactive'){
      navigate("/settings");
    }
  };

  useEffect(async () => {
    currentUser &&
      (await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/${currentUser.uid}/note`
      )
        .then((res) => res.json())
        .then((data) => {
          setNote(data);
        }));
  }, [loading]);

  useEffect(async () => {
    currentUser &&
      (await fetch(
        `${process.env.REACT_APP_SERVER_URL}/interviews?userId=${currentUser.uid}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("interviews api", data.interviews)
          setInterviews(data.interviews);
        }));
  }, []);

  useEffect(async () => {
    currentUser &&
      (await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/${currentUser.uid}/answerSets`
      )
        .then((res) => res.json())
        .then((data) => {
          setAnswerSets(data.answerSets);
        }));
  }, []);

  // -------------------------
  // NOTE FUNCTIONS
  // -------------------------

  // Note Functions
  const editNote = () => {
    setEdit(true);
  };

  const updateNote = async (newNote) => {
    setLoading(true);
    setEdit(false);

    await fetch(
      `${process.env.REACT_APP_SERVER_URL}/users/${currentUser.uid}/note`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newNote }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setNote(data);
        setLoading(false);
      });
  };

  return (
    <div>
      {/* <div className="flex md:hidden">
        <Mobile />
      </div> */}
      {/* <div className="hidden md:flex"> */}
      <div>
        <Toaster position="top-center" reverseOrder={false} />
        <SideMenu current={"home"} />
        <Dashboard
          note={note}
          edit={edit}
          editNote={editNote}
          updateNote={updateNote}
          interviews={interviews}
          answerSets={answerSets}
          subscriptionStatus={subscriptionStatus}
          userType={userType}
        />
      </div>
    </div>
  );
};

export default Home;
