// Library Imports
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Component Imports
import IntroScreen from "./IntroScreen";
import NameScreen from "./NameScreen";
import EmailScreen from "./EmailScreen";
import QuestionScreen from "./QuestionScreen";
import RecordingScreen from "./RecordingScreen";
import ReviewScreen from "./ReviewScreen";
import SuccessScreen from "./SuccessScreen";
import ErrorScreen from "./ErrorScreen";
import Mobile from "../../components/Mobile";

// Background Wallpaper
import Background from "../../assets/background.jpg";

const Index = () => {
  const { interviewId } = useParams();

  // Loader State
  const [loadingBar, setLoadingBar] = useState(0);
  const [maxLoadingBar, setMaxLoadingBar] = useState(0);

  // Screen States
  const [errorScreen, setErrorScreen] = useState(false);
  const [introScreen, setIntroScreen] = useState(false);
  const [nameScreen, setNameScreen] = useState(false);
  const [emailScreen, setEmailScreen] = useState(false);
  const [questionScreen, setQuestionScreen] = useState(false);
  const [recordingScreen, setRecordingScreen] = useState(false);
  const [reviewScreen, setReviewScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  // Data States
  const [responderName, setResponderName] = useState();
  const [responderEmail, setResponderEmail] = useState();
  const [questions, setQuestions] = useState([]);
  const [showIndex, setShowIndex] = useState(0);
  const [uploading, setUploading] = useState(false);

  // Recording States
  const [recordings, setRecordings] = useState([]);
  const [timer, setTimer] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(false);
  const [videoRecording, setVideoRecoridng] = useState(true);

  // Fetch questions on load
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/questions/answer/status?interviewId=${interviewId}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setErrorScreen(true);
        } else {
          setIntroScreen(true);
          setQuestions(data.questions);
          const numberQuestions = data.questions.length;
          const maxValue = numberQuestions * 10;
          setMaxLoadingBar(20 + maxValue);
        }
      });
  }, []);

  // Change screens functions

  // Screen 2
  const showNameScreen = () => {
    setLoadingBar(loadingBar + 10);
    setIntroScreen(false);
    setNameScreen(true);
  };

  // Screen 3
  const showEmailScreen = (name) => {
    setNameScreen(false);
    setEmailScreen(true);
    setResponderName(name);
    setLoadingBar(loadingBar + 10);
  };

  // Screen 4
  const showQuestionScreen = (email) => {
    setEmailScreen(false);
    setQuestionScreen(true);
    setResponderEmail(email);
  };

  // Screen 6
  const showRecordingScreen = () => {
    setQuestionScreen(false);
    setRecordingScreen(true);
  };

  // --------------------------
  // Recording Screen Functions
  // --------------------------

  // Function 1
  const onRecordingComplete = (blobUrl, blob) => {
    const newBlob = { blob, blobUrl };
    setRecordings((recordings) => [...recordings, newBlob]); // Set the blob
    setRecordingScreen(false); // Turn off recording screen
    setReviewScreen(true); // Turn on review screen
  };

  // --------------------------
  // Review Screen Functions
  // --------------------------

  // Button - Next Question
  const onNextQuestion = () => {
    setReviewScreen(false);
    setQuestionScreen(true);
    setShowIndex(showIndex + 1);
    setLoadingBar(loadingBar + 10);
  };

  // Button - Re-take Interview
  const onRetakeAnswer = (blobUrl) => {
    setRecordings(
      recordings.filter((recording) => recording.blobUrl !== blobUrl)
    );
    setReviewScreen(false);
    setRecordingScreen(true);
  };

  // Button - Submit Interview
  const submitIndividualVideo = async (answerSetId, blob) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/answers?answerSetId=${answerSetId}&interviewId=${interviewId}`,
      {
        method: "POST",
      }
    );

    const { url } = await response.json();

    try {
      await fetch(url, {
        method: "PUT",
        body: blob,
        headers: { "content-type": blob.type },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const submitInterview = async (e) => {
    e.preventDefault();
    setUploading(true);
    setLoadingBar(loadingBar + 10);

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/answerSets?interviewId=${interviewId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ responderName, responderEmail }),
      }
    );

    const answerSet = await response.json();

    for (const recording of recordings) {
      await submitIndividualVideo(answerSet._id, recording.blob);
    }

    setReviewScreen(false);
    setSuccessScreen(true);
    setUploading(false);
  };

  const turnOnTimer = () => {
    setTimer(true);
  };

  const turnOffTimer = () => {
    setTimer(false);
  };

  const turnOnCountdownTimer = () => {
    setCountdownTimer(true);
  };

  const turnOffCountdownTimer = () => {
    setCountdownTimer(false);
  };

  const toggleVideoRecording = () => {
    setVideoRecoridng(!videoRecording);
  };

  return (
    <div>
      {/* <div className="flex md:hidden">
        <Mobile />
      </div> */}
      <div className="flex md:flex w-screen h-screen justify-center items-center relative">
        <a
          href="https://usehaste.io"
          target={"_blank"}
          className="fixed top-8 left-8 text-white z-20 flex items-center cursor-pointer"
        >
          <span className="mr-2 mt-1 font-sans font-medium text-gray-300 text-xs">
            BY
          </span>
          <svg
            className="w-5 h-5 fill-current text-black"
            viewBox="0 0 397 397"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="91.8848" width="243.155" height="61.2565" fill="white" />
            <rect
              width="244.741"
              height="60.8634"
              transform="matrix(0.396184 0.918171 -0.915936 0.401325 222.816 0)"
              fill="white"
            />
            <rect
              width="244.938"
              height="60.8218"
              transform="matrix(0.263409 -0.964684 -0.963644 -0.26719 93.9548 366.096)"
              fill="white"
            />
            <rect
              width="243.964"
              height="61.0582"
              transform="matrix(0.755927 0.654656 0.648909 -0.760866 75.9858 236.588)"
              fill="white"
            />
            <rect
              width="243.917"
              height="61.0799"
              transform="matrix(-0.786052 0.61816 0.612299 0.790627 358.901 130.006)"
              fill="white"
            />
            <rect
              x="136.775"
              y="137.827"
              width="121.578"
              height="122.513"
              fill="white"
            />
            <ellipse cx="189.965" cy="191.427" rx="37.993" ry="38.2853" />
          </svg>
          <span className="ml-1 font-sans font-semibold text-white text-lg">
            Haste
          </span>
        </a>
        <img
          className="object-stretch h-full w-full absolute inset-0 z-10 pointer-events-none fade-in"
          src={Background}
        />
        <div className="w-11/12 mt-5 md:w-full md:mt-0 max-w-md h-full container-sm-height md:container-height bg-white rounded-3xl shadow-sm relative z-50">
          {!errorScreen && !successScreen && (
            <div className="w-full md:mb-8 px-8 mb-5 pt-5 md:pt-8">
              <ProgressBar
                completed={loadingBar}
                maxCompleted={maxLoadingBar}
                bgColor={"#2563EB"}
                baseBgColor={"#dbeafe"}
                height={"7px"}
                isLabelVisible={false}
              />
            </div>
          )}
          {introScreen && (
            <IntroScreen
              showNameScreen={showNameScreen}
              questions={questions}
            />
          )}
          {nameScreen && <NameScreen showEmailScreen={showEmailScreen} />}
          {emailScreen && (
            <EmailScreen showQuestionScreen={showQuestionScreen} />
          )}
          {questionScreen && (
            <QuestionScreen
              showRecordingScreen={showRecordingScreen}
              showIndex={showIndex}
              questions={questions}
            />
          )}
          {recordingScreen && (
            <RecordingScreen
              onRecordingComplete={onRecordingComplete}
              showIndex={showIndex}
              questionLength={questions.length}
              turnOnTimer={turnOnTimer}
              turnOffTimer={turnOffTimer}
              timer={timer}
              turnOnCountdownTimer={turnOnCountdownTimer}
              turnOffCountdownTimer={turnOffCountdownTimer}
              countdownTimer={countdownTimer}
              videoRecording={videoRecording}
              toggleVideoRecording={toggleVideoRecording}
            />
          )}
          {reviewScreen && (
            <ReviewScreen
              recordings={recordings}
              showIndex={showIndex}
              onNextQuestion={onNextQuestion}
              submitInterview={submitInterview}
              onRetakeAnswer={onRetakeAnswer}
              questionLength={questions.length}
              uploading={uploading}
              videoRecording={videoRecording}
            />
          )}
          {successScreen && <SuccessScreen />}
          {errorScreen && <ErrorScreen />}
        </div>
      </div>
    </div>
  );
};

export default Index;
