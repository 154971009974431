// Library Imports
import { useNavigate } from "react-router-dom";

const VideoCard = ({
  title,
  date,
  thumbnailImage,
  thumbnailGif,
  url,
  live,
  responses,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(url)}
      className="w-full flex flex-col group relative hover:bg-gray-50 cursor-pointer border border-gray-100 rounded-lg"
    >
      {thumbnailImage && thumbnailGif ? (
        <div className="w-full">
          <img
            className="relative object-contain inset-0 rounded-tl-lg rounded-tr-lg opacity-100 group-hover:opacity-0"
            src={thumbnailImage}
          />
          <img
            className="absolute w-full inset-0 rounded-tl-lg rounded-tr-lg opacity-0 group-hover:opacity-100"
            src={thumbnailGif}
          />
        </div>
      ) : (
        <div className="w-full h-40 relative rounded-md bg-gray-100 flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-gray-900"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
      )}
      <div className="p-4">
        <div className="2xl:mt-12 pb-10">
          <span className=" font-sans font-medium text-gray-900 text-sm">
            {title}{" "}
            <span className="font-sans font-medium text-xs text-gray-400">
              ({responses})
            </span>
          </span>
        </div>
        <div className="absolute bottom-4 mt-10 w-full flex justify-between items-center pr-8">
          <span className="font-sans font-medium text-gray-400 text-xs">
            {date}
          </span>

          <div className="flex items-center space-x-4">
            {live && (
              <div className="w-2 h-2 rounded-full bg-green-300 ring ring-green-100"></div>
            )}
            {!live && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 text-gray-500 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
