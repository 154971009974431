import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../context/Auth";
import ActionDialog from "./ActionDialog";

const NewInterviewBtn = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [disable, setDisable] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState();
  const [subscriptionStatus, SetSubscriptionStatus] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/users/${currentUser.uid}`)
      .then((res) => res.json())
      .then((user) => {
        SetSubscriptionStatus(user.subscriptionStatus);
        if (user.subscriptionStatus === "cancelled") {
          setDisable(true);
        }
      });
  }, []);

  const onCreateInterview = () =>{
    if(subscriptionStatus == 'Inactive'){
      setDialog(true);
    }else{
      navigate("/create");
    }
  }

  const upgradeSubscription = async () => {
    window.location.href =   `https://buy.stripe.com/8wMaHbcgp0Du0SYdQS?client_reference_id=${currentUser.uid}`;

    //`https://buy.stripe.com/test_aEU6qEc8b2Q7digbIJ?client_reference_id=${currentUser.uid}`;
    // setLoading(true);
    // const response = await fetch(
    //   `${process.env.REACT_APP_SERVER_URL}/subscriptions`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       userId: currentUser.uid,
    //     }),
    //   }
    // );
    // const { newSubscription, url } = await response.json();

    // if (newSubscription) {
    //   setDialog(false);
    //   window.location.href = url;
    //   setLoading(false);
    // } else {
    //   setDialog(false);
    //   toast.success("Subscription renewed!");
    //   setLoading(false);
    // }
  };

  const closeDialog = () => {
    setDialog(false);
  };

  return (
    <>
      <ActionDialog
        title={"Subscribe PRO plan"}
        subtitle={
          "Subscribe the PRO plan to create unlimited new interviews or get more responses"
        }
        btnTitle={"Subscribe"}
        btnStyle={"bg-blue-600 hover:bg-blue-700"}
        isOpen={dialog}
        closeDialog={closeDialog}
        action={upgradeSubscription}
        loading={loading}
        loadingStyle={"bg-blue-300 pointer-events-none"}
        loadingText={"Subscribe"}
      />
        <button
          onClick={() => onCreateInterview()}
          data-tip="New interview"
          className={` ${
            disable && "pointer-events-none"
          } md:h-10 md:px-4 p-2 relative bottom-1.5 md:bottom-0 rounded-full bg-blue-600 flex justify-center items-center hover:bg-blue-700`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 fill-current text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
              d="M12 4v16m8-8H4"
            />
          </svg>
        </button>
        <ReactTooltip
          effect="solid"
          backgroundColor="#475569"
          className="tooltip"
          arrowColor="transparent"
        />
    </>
  );
};

export default NewInterviewBtn;
