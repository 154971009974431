// Absolute Imports
import { useState, useContext, useEffect } from "react";
import { useNavigate , useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as moment from "moment";
import SupscribeModal from "./supscribemodal";
// Relative imports
import {
  auth,
  signOut,
  updateEmail,
  sendPasswordResetEmail,
} from "../../firebase";
import { AuthContext } from "../../context/Auth";
import { UserContext } from "../../context/User";
import ActionDialog from "../../components/ActionDialog";
import Api from "../../Api";
import { useUserStatus } from "../../context/UserStatusContext";

const Dashboard = ({
  onChangeStatus,
  upgradeSubscription,
  openCancelSubscriptionDialog,
  openRenewSubscriptionDialog,
  subscription,
}) => {
  const navigate = useNavigate();

  const { session_id } = useParams();
  const { userStatus, updateUserStatus } = useUserStatus();

  const { currentUser } = useContext(AuthContext);
  const { userType } = useContext(UserContext);
  const [cancelSubscriptionDialog, setCancelSubscriptionDialog] =
    useState(false);
  // Email States
  const [emailChange, setEmailChange] = useState(false);
  const [newEmail, setNewEmail] = useState();
  const [emailError, setEmailError] = useState();

  // Password States
  const [passwordChange, setPasswordChange] = useState(false);
  const [passwordError, setPasswordError] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  //First NameStates
  const [firstNameChange, setFirstNameChange] = useState(false);
  const [newFirstName, setNewFirstName] = useState();
  const [firstNameError, setFirstNameError] = useState();
  // Last Name States
  const [lastNameChange, setLastNameChange] = useState(false);
  const [newLastName, setNewLastName] = useState();
  const [lastNameError, setLastNameError] = useState();
  // Phone States
  const [phoneChange, setPhoneChange] = useState(false);
  const [newPhone, setNewPhone] = useState();
  const [phoneError, setPhoneError] = useState();
  // Company States
  const [companyChange, setCompanyChange] = useState(false);
  const [newCompany, setNewCompany] = useState();
  const [companyError, setCompanyError] = useState();

  const [subscriptionData, setSubscriptionData] = useState([]);
  const [userSubscription, setUserSubscription] = useState(subscription);
  const [selectSubscription, setSelectSubscription] = useState([]);
  const [userData, setUserData] = useState([]);

  const [loading, setLoading] = useState();
  const [isSubscripeChecked, setSubscripeChecked] = useState(true);

  const openModal = () => {
    setIsModalVisible(true);
  };
  const api = Api();
  const closeModal = async () => {
    console.log("close modal");
    setIsModalVisible(false);
    await getuser();
    await getsubscription();
    onChangeStatus();
  };

  // Function 1 - Update the email address
  const handleNewEmail = (e) => {
    setNewEmail(e.target.value);
  };

  const handleEmailChange = () => {

    setEmailChange(!emailChange);
    setEmailError();
  };

  const handleUpdateEmail = () => {
    updateEmail(auth.currentUser, newEmail)
      .then(() => {
        setEmailError();
        toast.success("Email changed!");
        setEmailChange(false);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            setEmailError(
              "This is not a valid email address. Please try again."
            );
            break;

          case "auth/requires-recent-login":
            setEmailError(
              "Changing your email requires a recent login. Please sign out and back in to try again."
            );
            break;

          default:
            setEmailError(error.message);
        }
      });
  };

  // Function 1 - Update the First Name
  const handleNewFirstName = (e) => {
    setNewFirstName(e.target.value);
  };

  const handleFirstNameChange = () => {
    setFirstNameChange(!firstNameChange);
    setFirstNameError();

    console.log(userSubscription,"userSubscription");
    console.log(subscriptionData,"subscriptionData");
  };

  const handleUpdateFirstName = async () => {
    if (await validateFeild(newFirstName)) {
      const result = await UpgradeData(newFirstName, "firstName");

      if (result) {
        setFirstNameError();
        toast.success(`First Name changed!`);
        setFirstNameChange(false);
      } else {
        setFirstNameError("First Name not changed!.");
      }
    } else {
      setFirstNameError("Please Enter First Name.");
    }
  };

  // Function 1 - Update the Last Name
  const handleNewLastName = (e) => {
    setNewLastName(e.target.value);
  };

  const handleLastNameChange = () => {
    setLastNameChange(!lastNameChange);
    setLastNameError();
  };

  const handleUpdateLastName = async () => {
    console.log(newLastName, "lastname ");
    console.log(await validateFeild(newLastName), "lastname result");
    if (await validateFeild(newLastName)) {
      const result = await UpgradeData(newLastName, "lastName");

      if (result) {
        setLastNameError();
        toast.success(`Last Name changed!`);
        setLastNameChange(false);
      } else {
        setLastNameError("Last Name not changed!.");
      }
    } else {
      setLastNameError("Please Enter Last Name.");
    }
  };

  // Function 1 - Update the Phone
  const handleNewPhone = (e) => {
    let newValue = e.target.value;

    // newValue = newValue.replace(/\D/g, "");
    newValue = newValue.replace(/[^\d\s]/g, "");

    newValue = newValue.slice(0, 13);

    setNewPhone(newValue);
  };

  const handlePhoneChange = () => {
    setPhoneChange(!phoneChange);
    setPhoneError();
  };

  const handleUpdatePhone = async () => {
    if (await validatePhone(newPhone)) {
      const result = await UpgradeData(newPhone, "phone");

      if (result) {
        setPhoneError();
        toast.success(`Phone Number changed!`);
        setPhoneChange(false);
      } else {
        setPhoneError("Phone Number not changed!.");
      }
    } else {
      // setPhoneError("Please Enter Phone Number.");
    }
  };

  // Function 1 - Update the Company
  const handleNewCompany = (e) => {
    setNewCompany(e.target.value);
  };

  const handleCompanyChange = () => {
    setCompanyChange(!companyChange);
    setCompanyError();
  };

  const handleUpdateCompany = async () => {
    if (await validateFeild(newCompany)) {
      const result = await UpgradeData(newCompany, "company");

      if (result) {
        setCompanyError();
        toast.success(`Company changed!`);
        setCompanyChange(false);
      } else {
        setCompanyError("Company not changed!.");
      }
    } else {
      setCompanyError("Please Enter Company.");
    }
  };

  const validateFeild = (item) => {
    console.log(item);
    if (!item || item.trim() == "") {
      return false;
    } else {
      return true;
    }
  };

  const validatePhone = (item) => {
    console.log(item, "phone");
    if (!item || item.trim() === "") {
      setPhoneError("Please Enter Phone Number.");
    } else if (item.length < 9) {
      setPhoneError("Phone number must be at least 9 digits.");
    } else {
      return true;
    }
    return false;
  };

  const UpgradeData = async (value, field) => {
    const result = await api.postApi("users/UpgradeData", {
      userId: currentUser.uid,
      value: value,
      field: field,
    });

    getuser();
    return result;
  };

  const CancelSubscriptionModal = (data) => {
    setCancelSubscriptionDialog(true);
  };

  const closeCancelSubscriptionDialog = () => {
    setCancelSubscriptionDialog(false);
  };

  const cancelSubscription = async () => {
    setLoading(true);
    const result = await api.patchApi(
      `subscriptions/cancel?userId=${currentUser.uid}`
    );

    if (result) {

      await getuser();
      await getsubscription();
      await setLoading(false);
      await closeCancelSubscriptionDialog();
      toast.success("Subscription cancelled");

    }
  };

  // Function 2 - Reset the password

  const handlePasswordChange = () => {
    setPasswordChange(true);
    sendPasswordResetEmail(auth, currentUser.email).catch((error) =>
      setPasswordError(error.message)
    );
  };

  // Function 3 - Stripe subscription

  // Function 4 - Log out of dashboard
  const handleSignOut = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("email");

    localStorage.removeItem("password");

    localStorage.removeItem("method");
    localStorage.removeItem("userType");

    signOut(auth).then(() => navigate("/login"));
  };

  const handleClick = (value) => {
    openModal();
    setSelectSubscription(value);
  };

  const upgradeSubscripe = async (value) => {
    window.location.href =  `https://buy.stripe.com/8wMaHbcgp0Du0SYdQS?client_reference_id=${currentUser.uid}`;
  //`https://buy.stripe.com/test_aEU6qEc8b2Q7digbIJ?client_reference_id=${currentUser.uid}`; // without free trial


    // setSubscripeChecked(true);
    // const result = await api.postApi("subscriptions/UpgradePlan", {
    //   userId: currentUser.uid,
    //   subscriptionId: value.subid,
    // });

    // if (result) {
    //   closeModal();
    //   toast.success(
    //     `Successfully subscribed to the ${value.subscriptionType} plan!`
    //   );
    //   setSubscripeChecked(false);
    // }
  };
  useEffect(() => {
    getsubscription();
    getuser();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if(session_id){
      const response = await api.getApi(`users/subscribe/${session_id}`);
      // const response = await axios.get('your_api_endpoint');
      // setPaymentData(response);
      console.log(response);
      if(response){
        getuser()
        getsubscription()
      }

    }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const getuser = async () => {
    const user = await api.getApi(`users/${currentUser.uid}`);

    console.log(userStatus, " userStatus");
    updateUserStatus();
    setUserData(user);
    console.log("getuser",user)
    setUserSubscription({
      type: user.subscriptionType,
      periodEnd: user.subscriptionPeriodEnd,
      status: user.subscriptionStatus,
    });
  };

  const getsubscription = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/subscrtiptionmanage/getsubscription`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
        } else {
          let Subscriptions = data
            .slice(0)
            .reverse()
            .map((element) => {
              return element;
            });
          console.log("getsubscription",Subscriptions)
          setSubscriptionData(Subscriptions);
        }
      });
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
      <main className="flex-1 mt-16 md:mt-0 py-8 px-6 md:py-40 md:px-32 flex justify-center">
        <div className="w-full max-w-2xl">
          <h1 className="font-sans text-3xl font-semibold text-gray-900">
            Settings
          </h1>
          <div className="md:mt-20 mt-4 flex flex-col">
            <div className="flex space-x-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="font-sans font-bold text-lg text-gray-900">
                Account
              </span>
            </div>
            <span className="mt-8 font-sans font-semibold text-gray-900 text-sm">
              Email Address
            </span>
            <div className="mt-2 flex space-x-2 items-center">
              <span className="font-sans font-normal text-gray-900 text-sm">
                {currentUser.email}
              </span>
              <span className="font-sans font-semibold text-base text-gray-900">
                ·
              </span>
              <span
                onClick={() => handleEmailChange()}
                className="font-sans font-medium text-sm text-blue-600 cursor-pointer hover:text-blue-700"
              >
                {emailChange ? "Cancel" : "Change"}
              </span>
            </div>

            {emailChange && (
              <div className="mt-2 flex flex-col w-full items-end">
                <input
                  onChange={(e) => handleNewEmail(e)}
                  value={newEmail}
                  placeholder="Update your email address"
                  className="h-12 w-full mt-2 border border-gray-200 rounded-lg font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
                />
                <button
                  onClick={() => handleUpdateEmail()}
                  className="mt-4 max-w-max px-4 py-2 rounded-lg border border-gray-200 hover:border-gray-300 group flex space-x-2 items-center"
                >
                  <span className="font-sans font-semibold text-xs text-gray-700 group-hover:text-gray-900">
                    Save
                  </span>
                </button>
              </div>
            )}

            {emailError && (
              <div className="mt-6 w-full py-3 px-4 bg-red-50 rounded-lg flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-red-700"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                </svg>
                <span className="font-sans font-medium text-red-700 text-sm">
                  {emailError}
                </span>
              </div>
            )}

            <span className="mt-8 font-sans font-semibold text-gray-900 text-sm">
              Password
            </span>
            <div className="mt-2 flex space-x-2 items-center">
              <span className=" font-sans font-normal text-gray-600 text-base">
                ····································
              </span>
              <span className="font-sans font-semibold text-base text-gray-900">
                ·
              </span>
              <span
                onClick={() => handlePasswordChange()}
                className="font-sans font-medium text-sm text-blue-600 cursor-pointer hover:text-blue-700"
              >
                {passwordChange ? "Re-send" : "Reset"}
              </span>
            </div>
            {passwordChange && (
              <div className="mt-6 w-full  py-3 px-4 bg-green-50 rounded-lg flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-green-700"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
                </svg>
                <span className="font-sans font-medium text-green-700 text-sm">
                  An email will be send out to you. Please click on the link to
                  reset your password.
                </span>
              </div>
            )}
            {passwordError && (
              <div className="mt-6 w-full py-3 px-4 bg-red-50 rounded-lg flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-800"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.3}
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="font-sans font-medium text-red-700 text-sm">
                  {passwordError}
                </span>
              </div>
            )}


         {userType != "admin" && (
          <>
            {/* first name  */}
            <span className="mt-8 font-sans font-semibold text-gray-900 text-sm">
              First Name
            </span>
            <div className="mt-2 flex space-x-2 items-center">
              <span className="font-sans font-normal text-gray-900 text-sm">
                {userData.firstName}
              </span>
              <span className="font-sans font-semibold text-base text-gray-900">
                ·
              </span>
              <span
                onClick={() => handleFirstNameChange()}
                className="font-sans font-medium text-sm text-blue-600 cursor-pointer hover:text-blue-700"
              >
                {firstNameChange ? "Cancel" : "Change"}
              </span>
            </div>

            {firstNameChange && (
              <div className="mt-2 flex flex-col w-full items-end">
                <input
                  onChange={(e) => handleNewFirstName(e)}
                  value={newFirstName}
                  placeholder="Update your First Name"
                  className="h-12 w-full mt-2 border border-gray-200 rounded-lg font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
                />
                <button
                  onClick={() => handleUpdateFirstName()}
                  className="mt-4 max-w-max px-4 py-2 rounded-lg border border-gray-200 hover:border-gray-300 group flex space-x-2 items-center"
                >
                  <span className="font-sans font-semibold text-xs text-gray-700 group-hover:text-gray-900">
                    Save
                  </span>
                </button>
              </div>
            )}

            {firstNameError && (
              <div className="mt-6 w-full py-3 px-4 bg-red-50 rounded-lg flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-red-700"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                </svg>
                <span className="font-sans font-medium text-red-700 text-sm">
                  {firstNameError}
                </span>
              </div>
            )}

            {/* Last name */}

            <span className="mt-8 font-sans font-semibold text-gray-900 text-sm">
              Last Name
            </span>
            <div className="mt-2 flex space-x-2 items-center">
              <span className="font-sans font-normal text-gray-900 text-sm">
                {userData.lastName}
              </span>
              <span className="font-sans font-semibold text-base text-gray-900">
                ·
              </span>
              <span
                onClick={() => handleLastNameChange()}
                className="font-sans font-medium text-sm text-blue-600 cursor-pointer hover:text-blue-700"
              >
                {lastNameChange ? "Cancel" : "Change"}
              </span>
            </div>

            {lastNameChange && (
              <div className="mt-2 flex flex-col w-full items-end">
                <input
                  onChange={(e) => handleNewLastName(e)}
                  value={newLastName}
                  placeholder="Update your Last Name"
                  className="h-12 w-full mt-2 border border-gray-200 rounded-lg font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
                />
                <button
                  onClick={() => handleUpdateLastName()}
                  className="mt-4 max-w-max px-4 py-2 rounded-lg border border-gray-200 hover:border-gray-300 group flex space-x-2 items-center"
                >
                  <span className="font-sans font-semibold text-xs text-gray-700 group-hover:text-gray-900">
                    Save
                  </span>
                </button>
              </div>
            )}

            {lastNameError && (
              <div className="mt-6 w-full py-3 px-4 bg-red-50 rounded-lg flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-red-700"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                </svg>
                <span className="font-sans font-medium text-red-700 text-sm">
                  {lastNameError}
                </span>
              </div>
            )}

            {/* phone */}

            <span className="mt-8 font-sans font-semibold text-gray-900 text-sm">
              Phone Number
            </span>
            <div className="mt-2 flex space-x-2 items-center">
              <span className="font-sans font-normal text-gray-900 text-sm">
                {userData.phone}
              </span>
              <span className="font-sans font-semibold text-base text-gray-900">
                ·
              </span>
              <span
                onClick={() => handlePhoneChange()}
                className="font-sans font-medium text-sm text-blue-600 cursor-pointer hover:text-blue-700"
              >
                {phoneChange ? "Cancel" : "Change"}
              </span>
            </div>

            {phoneChange && (
              <div className="mt-2 flex flex-col w-full items-end">
                <input
                  onChange={(e) => handleNewPhone(e)}
                  maxLength={13}
                  value={newPhone}
                  placeholder="Update your Phone Number"
                  className="h-12 w-full mt-2 border border-gray-200 rounded-lg font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
                />
                <button
                  onClick={() => handleUpdatePhone()}
                  className="mt-4 max-w-max px-4 py-2 rounded-lg border border-gray-200 hover:border-gray-300 group flex space-x-2 items-center"
                >
                  <span className="font-sans font-semibold text-xs text-gray-700 group-hover:text-gray-900">
                    Save
                  </span>
                </button>
              </div>
            )}

            {phoneError && (
              <div className="mt-6 w-full py-3 px-4 bg-red-50 rounded-lg flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-red-700"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                </svg>
                <span className="font-sans font-medium text-red-700 text-sm">
                  {phoneError}
                </span>
              </div>
            )}

            {/* company */}

            <span className="mt-8 font-sans font-semibold text-gray-900 text-sm">
              Company
            </span>
            <div className="mt-2 flex space-x-2 items-center">
              <span className="font-sans font-normal text-gray-900 text-sm">
                {userData.company}
              </span>
              <span className="font-sans font-semibold text-base text-gray-900">
                ·
              </span>
              <span
                onClick={() => handleCompanyChange()}
                className="font-sans font-medium text-sm text-blue-600 cursor-pointer hover:text-blue-700"
              >
                {companyChange ? "Cancel" : "Change"}
              </span>
            </div>

            {companyChange && (
              <div className="mt-2 flex flex-col w-full items-end">
                <input
                  onChange={(e) => handleNewCompany(e)}
                  value={newCompany}
                  placeholder="Update your Company"
                  className="h-12 w-full mt-2 border border-gray-200 rounded-lg font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
                />
                <button
                  onClick={() => handleUpdateCompany()}
                  className="mt-4 max-w-max px-4 py-2 rounded-lg border border-gray-200 hover:border-gray-300 group flex space-x-2 items-center"
                >
                  <span className="font-sans font-semibold text-xs text-gray-700 group-hover:text-gray-900">
                    Save
                  </span>
                </button>
              </div>
            )}

            {companyError && (
              <div className="mt-6 w-full py-3 px-4 bg-red-50 rounded-lg flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-red-700"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                </svg>
                <span className="font-sans font-medium text-red-700 text-sm">
                  {companyError}
                </span>
              </div>
            )}
            </>
          )}

            {/* subscription */}
            {userType != "admin" && (
              <>
                <div className="mt-12 w-full h-px bg-gray-200"></div>
                {/* Free trial element */}

                {/* Pro plan element */}
                {userSubscription.type === "Pro" &&
                (userSubscription.status === "Active" ||
                  userSubscription.status === "Inactive") ? (
                  <div>
                    <div className="mt-12 flex justify-between items-start">
                      <div className="flex space-x-2 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                          />
                        </svg>
                        <span className=" font-sans font-bold text-lg text-gray-900">
                          Subscription
                        </span>
                      </div>

                    </div>

                    {subscriptionData.map((data, index) => (
                      <div key={data._id}>
                        <div className="mt-12 w-full flex justify-between items-start">
                          <div className="flex flex-col">
                            <div className="flex space-x-3 items-center">
                              <span className="font-sans font-semibold text-gray-900 text-base">
                                {data.subscriptionname}
                              </span>

                            </div>
                            <span className="mt-3 font-sans font-normal text-gray-600 text-sm">
                              Unlimited interviews, unlimited questions per
                              interview and unlimited responses.
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <div>
                              {" "}
                              <span className="font-sans font-medium text-gray-900 text-lg">
                              £{data.price}{" "}
                                <span className="font-normal text-gray-600 text-sm">
                                  /{data.duration}
                                </span>
                              </span>
                            </div>
                            <div>
                              <button
                                onClick={() => handleClick(data)}
                                className="px-4 py-2 rounded-md bg-blue-600 font-sans font-semibold text-xs text-white hover:bg-blue-700"
                              >
                                Subscribe
                              </button>
                            </div>
                          </div>

                        </div>


                        <div className="mt-12 w-full py-3 px-4 rounded-lg bg-gray-100 flex space-x-2 items-start">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="w-5 h-5 fill-current text-gray-700 "
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
                          </svg>
                          <span className="font-sans font-medium text-gray-700 text-sm">
                            Kindly consider subscribing to enjoy new interviews
                            and take advantage of a 21-day free trial.
                          </span>
                        </div>

                      </div>
                    ))}
                  </div>
                )
                : userSubscription.type === "Free" &&
                  (userSubscription.status === "Active" ||
                    userSubscription.status === "Inactive") ? (
                  subscriptionData.map(
                    (data, index) =>
                      // userData.upgradeId == data.subid    &&
                       (
                        <div key={data.id}>
                          <div className="mt-12 flex justify-between items-start">
                            <div className="flex space-x-2 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                />
                              </svg>
                              <span className=" font-sans font-bold text-lg text-gray-900">
                                Subscription
                              </span>
                            </div>

                          </div>
                          <div className="mt-12 w-full flex justify-between items-start">
                            <div className="flex flex-col">
                              <div className="flex space-x-3 items-center">
                                <span className="font-sans font-semibold text-gray-900 text-sm">
                                  {data.subscriptionname}
                                </span>

                              </div>
                              <span className="mt-3 font-sans font-normal text-gray-600 text-sm">
                              Including unlimited interviews and unlimited
                              responses.
                              </span>
                            </div>
                            <span className="font-sans font-medium text-gray-900 text-lg">
                            £{data.price}{" "}
                              <span className="font-normal text-gray-600 text-sm">
                                /{data.duration}
                              </span>
                            </span>
                          </div>

                          <div className="mt-12 w-full py-3 px-4 rounded-lg bg-gray-100 flex space-x-2 items-start">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              className="w-5 h-5 fill-current text-gray-700 "
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
                            </svg>
                            <span className="font-sans font-medium text-gray-700 text-sm">

                              Experience the benefits of our subscription
                              service in free trial period! Your current free
                              trial period will be upgraded to Pro plan once
                              the trial concludes.
                            </span>
                          </div>

                        </div>
                      )
                  )
                )
                 : userSubscription.type != null &&
                  userSubscription.type.trim() !== "" &&
                  (userSubscription.status === "Active" ||
                    //  ( userSubscription.status === "Inactive" && !userData.upgradeId    )||
                    userSubscription.status === "cancelling") ? (
                  <div>
                    <div className="mt-12 flex justify-between items-start">
                      <div className="flex space-x-2 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                          />
                        </svg>
                        <span className=" font-sans font-bold text-lg text-gray-900">
                          Subscription
                        </span>
                      </div>

                    </div>

                    {subscriptionData.map((data, index) => (
                      <>
                        <div className="mt-12 w-full flex justify-between items-start">
                          <div className="flex flex-col">
                            <div className="flex space-x-3 items-center">
                              <span className="font-sans font-semibold text-gray-900 text-base">
                                {data.subscriptionname}
                              </span>
                              <div className="flex space-x-2 items-center">
                                {
                                // userData.upgradeId == data.subid &&
                                 (
                                  <>
                                    <div className="font-sans font-medium text-xs text-green-800 flex space-x-1 items-center px-2 py-1 rounded-md bg-green-100">
                                      Active
                                    </div>
                                    {userSubscription.status ===
                                      "cancelling" && (
                                      <div className="flex space-x-1 items-center px-2 py-1 rounded-md bg-gray-100">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          className="w-4 h-4 fill-current text-gray-500"
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm1-10V7h-2v7h6v-2h-4z" />
                                        </svg>

                                        <span className="font-sans font-medium text-xs text-gray-700">
                                          {" "}
                                          Cancels{" "}
                                          {moment(
                                            subscription.periodEnd
                                          ).format("MMM Do YYYY")}
                                          .
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <span className="mt-3 font-sans font-normal text-gray-600 text-sm">
                              Including unlimited interviews and unlimited
                              responses.
                            </span>
                          </div>

                          <div className="flex flex-col">
                            <div style={{ textAlign: "right" }}>
                              {" "}
                              <span className="font-sans font-medium text-gray-900 text-lg">
                              £{data.price}{" "}
                                <span className="font-normal text-gray-600 text-sm">
                                  /{data.duration}
                                </span>
                              </span>
                            </div>
                            <div>

                              {
                              // userData.upgradeId == data.subid &&
                                userSubscription.status !== "cancelling" && (
                                  <button
                                    onClick={() =>
                                      CancelSubscriptionModal(data)
                                    }
                                    className="px-4 py-2 rounded-md bg-blue-600 font-sans font-semibold text-xs text-white hover:bg-blue-700"
                                  >
                                    Cancel Subscription
                                  </button>
                                )}
                            </div>
                          </div>


                        </div>
                      </>
                    ))}
                  </div>
                ) : (
                  userSubscription.type != null &&
                  userSubscription.type.trim() !== "" &&
                  //  userSubscription.status === "cancelled"
                  userSubscription.status === "Inactive"
                  //  &&    userData.upgradeId

                   && (
                    <div>
                      <div className="mt-12 flex justify-between items-start">
                        <div className="flex space-x-2 items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                            />
                          </svg>
                          <span className=" font-sans font-bold text-lg text-gray-900">
                            Subscription
                          </span>
                        </div>
                        {/* <button
                        onClick={() => openRenewSubscriptionDialog()}
                        className="px-4 py-2 rounded-md bg-blue-600 font-sans font-semibold text-xs text-white hover:bg-blue-700"
                      >
                        Renew subscription
                      </button> */}
                      </div>

                      {subscriptionData.map((data, index) => (
                        <>
                          <div className="mt-12 w-full flex justify-between items-start">
                            <div className="flex flex-col">
                              <div className="flex space-x-3 items-center">
                                <span className="font-sans font-semibold text-gray-900 text-base">
                                  {data.subscriptionname}
                                </span>
                                {
                                userData.upgradeId == data.subid &&
                                (
                                  <div className="font-sans font-medium text-xs text-gray-900 px-2 py-1 rounded-md bg-gray-100">
                                    Cancelled
                                  </div>
                                )}
                              </div>

                              <span className="mt-3 font-sans font-normal text-gray-600 text-sm">
                                Including unlimited interviews and unlimited
                                responses.
                              </span>
                            </div>

                            <div className="flex flex-col">
                              <div>
                                {" "}
                                <span className="font-sans font-medium text-gray-900 text-lg">
                                £{data.price}{" "}
                                  <span className="font-normal text-gray-600 text-sm">
                                    /{data.duration}
                                  </span>
                                </span>
                              </div>
                              <div>
                                <button
                                  onClick={() => upgradeSubscripe(data)}
                                  disabled={!isSubscripeChecked}
                                  className="px-4 py-2 rounded-md bg-blue-600 font-sans font-semibold text-xs text-white hover:bg-blue-700"
                                >
                                  Subscribe
                                </button>
                              </div>
                            </div>

                            {/* <span className="font-sans font-medium text-gray-900 text-lg">
                        $49{" "}
                        <span className="font-normal text-gray-600 text-sm">
                          /mo
                        </span>
                      </span> */}
                          </div>
                          {
                          // userData.upgradeId == data.subid &&
                           (
                            <div className="mt-12 w-full py-3 px-4 rounded-lg bg-gray-100 flex space-x-2 items-start">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                className="w-5 h-5 fill-current text-gray-700 "
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
                              </svg>
                              <span className="font-sans font-medium text-gray-700 text-sm">
                                Your subscription has been cancelled, which
                                means you won't be able to see any new
                                interviews or receive new answers.Kindly
                                consider subscribing again to enjoy new
                                interviews.
                              </span>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )
                )}


              </>
            )}

            <div className="mt-12 w-full h-px bg-gray-200"></div>
            <div className="mt-12 w-full flex justify-end">
              <button
                onClick={() => handleSignOut()}
                className=" px-4 py-2 rounded-lg border border-gray-200 hover:border-gray-300 group flex space-x-2 items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-gray-700 group-hover:text-gray-900"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z" />
                </svg>
                <span className="font-sans font-semibold text-xs text-gray-700 group-hover:text-gray-900">
                  Log out
                </span>
              </button>
            </div>
          </div>

          <SupscribeModal
            subscription={selectSubscription}
            isVisible={isModalVisible}
            onClose={closeModal}
          />

          <ActionDialog
            title={"Cancel subscription"}
            subtitle={
              "If you cancel your subscription, the current active plan's validity will persist until its end date. However, the upcoming plan will be canceled and auto-renewal will not occur"
            }
            btnTitle={"Ok"}
            btnStyle={"bg-red-600 hover:bg-red-700"}
            isOpen={cancelSubscriptionDialog}
            closeDialog={closeCancelSubscriptionDialog}
            action={cancelSubscription}
            loading={loading}
            loadingStyle={"bg-red-300 pointer-events-none"}
            loadingText={"Cancelling"}
          />
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
