// Library Imports
import ReactTooltip from "react-tooltip";
import ta from "time-ago";
import { useNavigate } from "react-router-dom";

// Component Imports
import VideoCard from "./VideoCard";
import VideoLoader from "../../components/VideoLoader";

const Dashboard = ({
  archiveFolder,
  openDeleteModal,
  onRecoverInterview,
  loading,
}) => {
  const navigate = useNavigate();

  return (
    <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
      <main className="flex-1 mt-16 md:mt-0 py-8 px-6 md:py-40 md:px-32 2xl:px-60">
        <div className="w-full">
          <div className="flex flex-col space-y-4 md:mb-20 mb-4">
            <h1 className="font-sans text-3xl font-semibold text-gray-900">
              Archived
            </h1>
          </div>
          {/* Interview Container */}
          <div className="w-full flex flex-col">
            <div className="w-full pb-4 border-b border-gray-100 flex justify-start font-sans font-medium text-gray-900">
              Interviews
            </div>

            {loading && <VideoLoader />}

            {!loading && archiveFolder && archiveFolder.interviews.length > 0 && (
              <div className="mt-4 w-full pb-20 grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-5 gap-y-10">
                {archiveFolder.interviews.map((interview) => (
                  <VideoCard
                    interviewId={interview._id}
                    title={interview.title}
                    date={ta.ago(interview.time)}
                    thumbnailImage={interview.thumbnails.image}
                    thumbnailGif={interview.thumbnails.gif}
                    openDeleteModal={openDeleteModal}
                    onRecoverInterview={onRecoverInterview}
                  />
                ))}
              </div>
            )}

            {!loading && archiveFolder && archiveFolder.interviews.length < 1 && (
              <div className="w-full bg-primary rounded-md py-32 mt-4">
                <div className="flex px-4 text-center flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-12 w-12 fill-current text-gray-900 mb-4"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M3 10h18v10.004c0 .55-.445.996-.993.996H3.993A.994.994 0 0 1 3 20.004V10zm6 2v2h6v-2H9zM2 4c0-.552.455-1 .992-1h18.016c.548 0 .992.444.992 1v4H2V4z" />
                  </svg>
                  <span className="font-sans text-lg text-gray-900 font-semibold mb-2">
                    Archive to keep your workspace tidy.
                  </span>
                  <span className="font-sans text-sm text-gray-500 font-normal mb-8">
                    When you archive an interview, you get the option to restore
                    it or delete it permanently.
                  </span>
                  <a
                    href="https://app.usehaste.io/answer/61d590ab644e14c90061c0c7"
                    target="_blank"
                  ></a>
                </div>
              </div>
            )}

            {/* {archiveFolder && archiveFolder.interviews.length > 0 ? (
              <div className="mt-4 w-full pb-20 grid grid-cols-3 2xl:grid-cols-4 gap-5 gap-y-10">
                {archiveFolder.interviews.map((interview) => (
                  <VideoCard
                    interviewId={interview._id}
                    title={interview.title}
                    date={ta.ago(interview.time)}
                    thumbnailImage={interview.thumbnails.image}
                    thumbnailGif={interview.thumbnails.gif}
                    openDeleteModal={openDeleteModal}
                    onRecoverInterview={onRecoverInterview}
                  />
                ))}
              </div>
            ) : (
              <div className="w-full bg-primary rounded-md py-32 mt-4">
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-12 w-12 fill-current text-gray-900 mb-4"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M3 10h18v10.004c0 .55-.445.996-.993.996H3.993A.994.994 0 0 1 3 20.004V10zm6 2v2h6v-2H9zM2 4c0-.552.455-1 .992-1h18.016c.548 0 .992.444.992 1v4H2V4z" />
                  </svg>
                  <span className="font-sans text-lg text-gray-900 font-semibold mb-2">
                    Archive to keep your workspace tidy.
                  </span>
                  <span className="font-sans text-sm text-gray-500 font-normal mb-8">
                    When you archive an interview, you get the option to restore
                    it or delete it permanently.
                  </span>
                  <a
                    href="https://app.usehaste.io/answer/61d590ab644e14c90061c0c7"
                    target="_blank"
                  ></a>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
