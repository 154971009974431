// Library Imports
import { useState, useEffect, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";

// Component Imports
import SideMenu from "../../components/SideMenu";
import Dashboard from "./dashboard";
import { AuthContext } from "../../context/Auth";
import NewFolderDialog from "../../components/NewFolderDialog";

const Interviews = () => {
  // Variables
  const { currentUser } = useContext(AuthContext);

  // States
  const [loading, setLoading] = useState(false);
  const [interviews, setInterviews] = useState([]);

  // Folder States
  const [folders, setFolders] = useState([]);
  const [folderDialog, setFolderDialog] = useState(false);
  const [folderName, setFolderName] = useState();

  useEffect(async () => {
    setLoading(true);
    currentUser &&
      (await fetch(
        `${process.env.REACT_APP_SERVER_URL}/folders/library/${currentUser.uid}`
      )
        .then((res) => res.json())
        .then((data) => {
          setInterviews(data.interviews);
          setLoading(false);
        }));
  }, [currentUser]);

  // Function 2 - GET all folders for this user.
  useEffect(async () => {
    currentUser &&
      (await fetch(
        `${process.env.REACT_APP_SERVER_URL}/folders/user-folders?userId=${currentUser.uid}`
      )
        .then((res) => res.json())
        .then((data) => {
          setFolders(data.reverse());
        }));
  }, [currentUser]);

  //-----------------
  // Folder Funtions
  //-----------------

  // Folder Dialog - Open
  const openFolderDialog = () => {
    setFolderDialog(true);
  };

  // Folder Dialog - Close
  const closeFolderDialog = () => {
    setFolderDialog(false);
    setFolderName("");
  };

  // Handle New Folder Name
  const handleFolderName = (e) => {
    setFolderName(e.target.value);
  };

  // Create New Folder
  const createNewFolder = async () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_SERVER_URL}/folders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: currentUser.uid, name: folderName }),
    })
      .then((res) => res.json())
      .then((data) => {
        setFolders(data.folders.reverse());
        toast.success("Folder created!");
        closeFolderDialog();
        setLoading(false);
      });
  };

  return (
    <div className="relative">
      <Toaster position="top-center" reverseOrder={false} />
      <SideMenu current={"interviews"} />
      <Dashboard
        loading={loading}
        interviews={interviews}
        folders={folders}
        openDialog={openFolderDialog}
      />
      <NewFolderDialog
        isOpen={folderDialog}
        closeDialog={closeFolderDialog}
        handleFolderName={handleFolderName}
        createNewFolder={createNewFolder}
        folderName={folderName}
        loading={loading}
      />
    </div>
  );
};

export default Interviews;
