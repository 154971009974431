import axios from "axios";

const Api = () => {
  const getApi = async (url) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/${url}`);
      return response.data; // Return only the data property
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Re-throw the error to propagate it
    }
  }

  const postApi = async (url, data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/${url}`, data);
      return response.data; // Return only the data property
    } catch (error) {
      console.error('Error posting data:', error);
      throw error; // Re-throw the error to propagate it
    }
  }

  const patchApi = async (url) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/${url}`);
      return response.data; // Return only the data property
    } catch (error) {
      console.error('Error posting data:', error);
      throw error; // Re-throw the error to propagate it
    }
  }



  return {
    getApi,
    postApi,
    patchApi
  };
}

export default Api;
