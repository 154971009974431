import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <Confetti
        recycle={false}
        numberOfPieces={500}
        gravity={0.2}
        width={window.innerWidth}
        height={window.innerHeight}
      />
      <div className="w-screen h-screen flex flex-col justify-center items-center bg-gray-50">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-20 w-20 text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span className="mt-4 font-sans font-medium text-gray-900 text-lg">
          Payment was successful
        </span>
        <span className="mt-2 font-sans font-normal text-gray-600 text-sm max-w-sm text-center leading-6">
          You are now a Haste Pro user, which means that you have unlocked all
          premium features.
        </span>
        <div
          onClick={() => navigate("/")}
          className="mt-6 px-4 py-2 rounded-full bg-blue-600 hover:bg-blue-700 flex space-x-1 cursor-pointer"
        >
          <span className="font-sans font-semibold text-white text-xs">
            Go back to dashboard
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
