import React, { useEffect, useState } from 'react';
import SideMenu from '../../components/SideMenu';

import Mailtemplate from './mailtemplate';


const Index = () => {


  return (
    <div>
      <SideMenu current={"template"} />
      <Mailtemplate />
    </div>
  );
}

export default Index;