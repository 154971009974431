import { useState } from "react";

import { TailSpin } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";

const ReviewInterviewScreen = ({
  recordings,
  interviewName,
  submitInterview,
  uploading,
  addMoreQuestions,
  onRetakeAnswer,
  moveVideoUp,
  moveVideoDown,
}) => {
  const [videoPlaying, setVideoPlaying] = useState();

  const playVideo = (blobUrl) => {
    setVideoPlaying(blobUrl);
  };

  return (
    <div className="flex flex-col max-h-full w-full">
      <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2 pt-8 px-8">
        <span>Interview</span>
        <span>·</span>
        <span>Final review</span>
      </div>
      <div className="font-sans font-semibold text-xl text-gray-900 mb-5 px-8">
        {interviewName}{" "}
        <span className="ml-1 font-sans font-medium text-base text-gray-400">
          ({recordings.length})
        </span>
      </div>
      <div
        className={`${
          uploading && "pointer-events-none"
        } w-full custom-max-h  mt-4 overflow-auto`}
      >
        {/* Individual Container */}
        {recordings.map((recording, index) => (
          <div className="w-full px-6 py-6 h-40 border-t border-gray-200 flex justify-start space-x-10 items-center ">
            <div
              onClick={() => playVideo(recording.blobUrl)}
              className="relative group cursor-pointer"
            >
              <video
                className="h-28 rounded-md z-10"
                autoPlay={videoPlaying === recording.blobUrl ? true : false}
                controls={videoPlaying === recording.blobUrl ? true : false}
                src={recording.blobUrl}
              />
              <div
                className={` ${
                  videoPlaying === recording.blobUrl ? "hidden" : "flex"
                } absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-white ring-4 ring-white ring-opacity-40 justify-center items-center group-hover:scale-110 transition duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-5 h-5 fill-current text-gray-900"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                </svg>
              </div>
            </div>
            <div className="flex flex-col justify-center h-full space-y-2 max-h-full">
              <span className="font-sans font-medium text-gray-400 text-sm">
                Q{index + 1}
              </span>
              <span className="font-sans font-semibold text-sm text-gray-700">
                {recording.name}
              </span>{" "}
              <div className="flex items-center">
                <span
                  onClick={() => onRetakeAnswer(recording.blobUrl)}
                  className="font-sans font-medium text-gray-700 text-xs hover:text-red-500 cursor-pointer"
                >
                  Delete
                </span>

                <span
                  className={`${
                    recordings.length < 2 && "hidden"
                  } font-sans font-medium text-gray-700 text-xl ml-3 mr-1`}
                >
                  ·
                </span>
                <>
                  <ReactTooltip
                    effect="solid"
                    backgroundColor="#475569"
                    className="tooltip"
                    arrowColor="transparent"
                  />
                  <div
                    data-tip="Move question up"
                    onClick={() => moveVideoUp(index)}
                    className={` ${
                      index === 0 ? "hidden pointer-events-none" : "flex"
                    } p-2 rounded-full group hover:bg-gray-100  cursor-pointer`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-700 group-hover:text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 11l5-5m0 0l5 5m-5-5v12"
                      />
                    </svg>
                  </div>
                </>
                <>
                  <div
                    data-tip="Move question down"
                    onClick={() => moveVideoDown(index)}
                    className={` ${
                      index === recordings.length - 1 || index === 7
                        ? "hidden pointer-events-none"
                        : "flex"
                    } p-2 rounded-full group hover:bg-gray-100 cursor-pointer`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-700 transform rotate-180 group-hover:text-gray-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 11l5-5m0 0l5 5m-5-5v12"
                      />
                    </svg>
                  </div>
                  <ReactTooltip
                    effect="solid"
                    backgroundColor="#475569"
                    className="tooltip"
                    arrowColor="transparent"
                  />
                </>
              </div>
            </div>
          </div>
        ))}

        {/* End of an individual container */}
        {recordings.length < 8 && (
          <div className="w-full pb-8 pt-3 border-t border-gray-200 flex justify-center">
            <div
              onClick={() => addMoreQuestions()}
              className="px-4 mt-2 h-8 rounded-lg bg-gray-100 hover:bg-gray-200 cursor-pointer flex justify-center items-center space-x-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-3 h-3 fill-current text-gray-900"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
              </svg>
              <span className="font-sans font-medium text-gray-700 text-xs">
                Add more
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="absolute bottom-0 w-full border-t border-gray-200 p-4">
        <button
          onClick={(e) => submitInterview(e)}
          className={` ${
            uploading || recordings.length < 1
              ? "bg-blue-300 pointer-events-none"
              : "bg-blue-600 hover:bg-blue-700"
          } w-full h-10 rounded-full flex space-x-2 justify-center items-center `}
        >
          <span className="font-sans font-semibold text-white text-sm">
            {uploading ? "Submitting" : "Submit"}
          </span>
          {uploading && (
            <TailSpin
              heigth="18"
              width="18"
              color="white"
              ariaLabel="loading"
            />
          )}
          {!uploading && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default ReviewInterviewScreen;
