import { ReactMediaRecorder } from "react-media-recorder";
import VideoPreview from "../../components/VideoPreview";
import TestTimer from "../../components/TestTimer";
import CountdownTimer from "../../components/CountdownTimer";
import { Bars } from "react-loader-spinner";

const RecordingScreen = ({
  onRecordingComplete,
  questionLength,
  showIndex,
  timer,
  turnOnTimer,
  turnOffTimer,
  countdownTimer,
  turnOnCountdownTimer,
  turnOffCountdownTimer,
  videoRecording,
  toggleVideoRecording,
}) => {
   console.log(videoRecording)
  return (
    <div>
      <ReactMediaRecorder
        onStop={(blobUrl, blob) => {
          onRecordingComplete(blobUrl, blob);
        }}
        audio={true}
        video={videoRecording}
        render={({
          error,
          status,
          startRecording,
          stopRecording,
          previewStream,
        }) => (
          <div>
            <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2 px-8">
              <span>Interview</span>
              <span>·</span>
              <span>
                Answer {showIndex + 1} of {questionLength}
              </span>
            </div>
            <div className="font-sans font-semibold text-lg text-gray-900 mb-5 px-8">
              Record your answer
            </div>
            <div className="flex flex-col">
              {status === "recording" ? (
                <div className="md:mt-16 max-w-full mx-8 md:h-36 rounded-xl flex justify-center items-center">
                  {videoRecording && <VideoPreview stream={previewStream} />}
                  {!videoRecording && (
                    <div className="w-full h-64 rounded-xl bg-gray-200 flex justify-center items-center">
                      <Bars color="#000000" height={100} width={100} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="mt-2 max-w-full mx-8 h-64  rounded-xl bg-gray-900 flex justify-center items-center">
                  {countdownTimer && (
                    <CountdownTimer
                      onStartRecording={startRecording}
                      turnOnTimer={turnOnTimer}
                      countdownTimer={countdownTimer}
                    />
                  )}
                  {!countdownTimer && (
                    <div className="px-4 h-10  rounded-xl flex justify-center items-center space-x-2 text-white bg-gray-700">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span className="font-sans font-semibold text-white text-xs">
                        Max recording length: 10 min
                      </span>
                    </div>
                  )}
                </div>
              )}

              {/* Recording Timer */}
              {status === "recording" && (
                <div className="max-w-full mt-4 md:mt-20 mx-8 flex justify-between items-center">
                  <div className="flex space-x-1 items-center animate-pulse">
                    <div className="w-2 h-2 rounded-full bg-red-500 "></div>
                    <span className="font-sans font-medium text-gray-900 text-xs">
                      REC
                    </span>
                  </div>
                  <TestTimer timer={timer} onStopRecording={stopRecording} />
                </div>
              )}

              {/* Error message */}

              {error && (
                <div className="max-w-full mt-4 mx-8 h-10 px-4 flex space-x-2 items-center bg-red-50 rounded-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="font-sans font-medium text-red-500 text-xs">
                    {error}
                  </span>
                </div>
              )}
              {!(status === "recording") && !error && (
                <div
                  onClick={() => toggleVideoRecording()}
                  className="mt-2 flex justify-end max-w-full mx-8 "
                >
                  <div className=" flex space-x-2 items-center px-4 py-2 rounded-md hover:bg-gray-100 cursor-pointer">
                    <span className="font-sans font-medium text-xs text-gray-900">
                      {videoRecording ? "Video on" : "Video off"}
                    </span>
                    {!videoRecording && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M16 16v1a2 2 0 01-2 2H3a2 2 0 01-2-2V7a2 2 0 012-2h2m5.66 0H14a2 2 0 012 2v3.34l1 1L23 7v10"></path>
                        <line x1="1" y1="1" x2="23" y2="23"></line>
                      </svg>
                    )}
                    {videoRecording && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <polygon points="23 7 16 12 23 17 23 7"></polygon>
                        <rect
                          x="1"
                          y="5"
                          width="15"
                          height="14"
                          rx="2"
                          ry="2"
                        ></rect>
                      </svg>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Button */}

            <div className="absolute bottom-0 w-full border-t border-gray-200 p-4">
              {!(status === "recording") && (
                <button
                  onClick={() => {
                    turnOnCountdownTimer();
                  }}
                  className="w-full h-10 rounded-full flex space-x-3 justify-center items-center bg-blue-600 hover:bg-blue-700"
                >
                  <div className="w-5 h-5 rounded-full bg-white flex justify-center items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-600"></div>
                  </div>
                  <span className="font-sans font-semibold text-white text-sm">
                    Start recording
                  </span>
                </button>
              )}
              {status === "recording" && (
                <button
                  onClick={() => {
                    turnOffTimer();
                    turnOffCountdownTimer();
                    stopRecording();
                  }}
                  className="w-full h-10 rounded-full flex space-x-3 justify-center items-center bg-red-600 hover:bg-red-700"
                >
                  <div className="w-5 h-5 rounded-full bg-white flex justify-center items-center">
                    <div className="w-2 h-2 rounded-sm bg-red-600"></div>
                  </div>
                  <span className="font-sans font-semibold text-white text-sm">
                    Stop recording
                  </span>
                </button>
              )}
            </div>
          </div>
        )}
      />

      {/* End of React Media Recorder Component */}
    </div>
  );
};

export default RecordingScreen;
