import { useState, useEffect } from "react";

const ReviewQuestion = ({
  recordings,
  showIndex,
  onRetakeAnswer,
  onReviewInterview,
  onNextQuestion,
  userSubscription,
}) => {
  const [videoControls, setVideoControls] = useState();
  const [playing, setPlaying] = useState();
  const [name, setName] = useState();

  useEffect(() => {
    setVideoControls(false);
    setPlaying(false);
  }, [showIndex]);

  const playVideo = () => {
    setPlaying(true);
    setVideoControls(true);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  return (
    <div>
      {recordings.map((recording, index) => (
        <>
          {index === showIndex && (
            <div>
              <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2 pt-6 md:pt-8 px-8">
                <span>Interview</span>
                <span>·</span>
                {userSubscription && userSubscription.length > 0 && (
                  <span>Question {recordings.length}/8</span>
                )}
                {/* {userSubscription === "Pro" && (
                  <span>Question {recordings.length}</span>
                )} */}
              </div>
              <div className="font-sans font-semibold text-xl text-gray-900 mb-4 md:mb-5 px-8">
                Review your response
              </div>
              <div className="flex flex-col">
                {/* Video */}
                <div
                  onClick={() => playVideo()}
                  className="relative group cursor-pointer z-50 max-w-full mx-8"
                >
                  {!playing && (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 h-16 rounded-full bg-white ring-4 ring-white ring-opacity-40 flex justify-center items-center group-hover:scale-110 transition duration-200">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-8 h-8 fill-current text-gray-900"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                      </svg>
                    </div>
                  )}
                  <video
                    src={recording.blobUrl}
                    controls={videoControls}
                    autoPlay={playing}
                    className="m-auto max-h-80 rounded-xl"
                  />

                </div>
                <div className="mt-4 md:mt-8 px-8 flex flex-col">
                  <input
                    onChange={(e) => handleNameChange(e)}
                    placeholder="Question name..."
                    className="h-12 w-full mb-6 border border-gray-200 rounded-xl font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
                  />
                </div>
              </div>
              <div className="absolute bottom-0 w-full border-t border-gray-200 p-4 flex space-x-2">
                <button
                  onClick={() => {
                    onReviewInterview(recording.blobUrl, name);
                    setName();
                  }}
                  className={` ${
                    !name ? "pointer-events-none" : "hover:bg-gray-100"
                  } w-full h-10 rounded-full `}
                >
                  <span
                    className={` ${
                      !name ? "text-gray-300" : "text-gray-800"
                    } font-sans font-medium text-sm`}
                  >
                    Save and review
                  </span>
                </button>

                {/* {userSubscription === "Pro" && (
                  <button
                    onClick={() => {
                      onNextQuestion(recording.blobUrl, name);
                      setName();
                    }}
                    className={` ${
                      !name
                        ? "bg-blue-300 pointer-events-none"
                        : "bg-blue-600 hover:bg-blue-700"
                    } w-full h-10 rounded-full flex space-x-1 justify-center items-center `}
                  >
                    <span className="font-sans font-semibold text-white text-sm">
                      Next
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </button>
                )} */}

                {userSubscription && userSubscription.length > 0 && recordings.length < 8 && (
                  <button
                    onClick={() => {
                      onNextQuestion(recording.blobUrl, name);
                      setName();
                    }}
                    className={` ${
                      !name
                        ? "bg-blue-300 pointer-events-none"
                        : "bg-blue-600 hover:bg-blue-700"
                    } w-full h-10 rounded-full flex space-x-1 justify-center items-center `}
                  >
                    <span className="font-sans font-semibold text-white text-sm">
                      Next
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default ReviewQuestion;
