import React from "react";
import { useEffect } from "react";
import { useTimer } from "use-timer";

const CountdownTimer = ({
  countdownTimer,
  reset,
  onStartRecording,
  turnOnTimer,
}) => {
  const { time, start } = useTimer({
    endTime: 0,
    initialTime: 3,
    timerType: "DECREMENTAL",
    onTimeOver: (time) => {
      onStartRecording();
      turnOnTimer();
    },
  });

  useEffect(() => {
    if (countdownTimer) {
      start();
    } else {
      reset();
    }
  }, [countdownTimer]);

  return (
    <div className="w-24 h-24 bg-white ring-8 ring-white ring-opacity-30 rounded-full font-sans font-semibold text-4xl text-black flex justify-center items-center">
      {time}
    </div>
  );
};

export default CountdownTimer;
