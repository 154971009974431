import React, { useState, useContext , useEffect } from 'react';
import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import Background from "./background.jpg";
import { loadStripe } from '@stripe/stripe-js';
import ReactModal from "react-modal";
import Successimage from "./successgif.gif"
import Api from '../../Api';
import { UserContext } from "../../context/User";
import { useNavigate } from "react-router-dom";
import Cards from 'react-credit-cards-2';

import {
  TextField,
  Grid,
  Typography
} from "@material-ui/core";
import StripeInput from '../../components/StripeInput';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import Modal from 'react-modal';
import CustomModal from './sucessmodal';

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import { Formik } from 'formik';
import { FieldSet, InputField } from 'fannypack';
// import 'react-payment-inputs/lib/styles.css';

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const api = Api()
  const navigate = useNavigate();

  // const {
  //   meta,
  //   getCardImageProps,
  //   getCardNumberProps,
  //   getExpiryDateProps,
  //   getCVCProps,
  //   wrapperProps
  // } = usePaymentInputs();

  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs();
  const { erroredInputs, touchedInputs } = meta;


  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [error,setError] = useState('');
  const [numbererror,setNumberError] = useState('Enter card number');
  const [cvverror,setCvvError] = useState('');
  const [dateerror,setDateError] = useState('');
  const [card,setCard] = useState('');
  const [spinner, setSpinner] = useState(false);

  const [disabledbutton,setDisabledbutton] = useState('');

  const [renew_subscription_modal, setRenew_subscription_modal] =
  useState(false);

  const { userType, changeUserType } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

 // Example of creating a PaymentIntent on the server



const createPaymentIntent = async () => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/payment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ amount: 1000 }), // Replace with your actual amount
  });

  return await response.json();
};



const handleChangeCardNumber = (e) =>{
   console.log("handleChangeCardNumber", e.target.value)

}

const handleCardNumberChange = (e) => {
  setCardNumber(e.complete ? e.value : '');
  console.log(e.value, "e.value");
  
};

const handleExpiryChange = (e) => {
  console.log(e.value, "e.value");
};

const handleCvcChange = (e) => {
  console.log(e.value, "e.value");
};



const closeModal = () => {
  setIsModalOpen(false);
};




// const paytest = () =>{
//   fetch(`${process.env.REACT_APP_SERVER_URL}/payment`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ amount:'333' }),
//         })
//         .then((res) => res.json())
//         .then(() => {
         
         
//         });
// }


const handleSubmit = async () => {

  console.log(cardNumber ,  "handleSubmit")
  console.log(expiryDate ,  "expiryDate")
  console.log(cvc ,  "cvc")

  let date = expiryDate.toString().split(' / ')[0];
let year = "20"+expiryDate.toString().split(' / ')[1];
console.log(year)
const card = { 
  cardNumber : cardNumber,
  expiryDate : date,
  expiryYear: year,
  cvc:cvc
}
  
  console.log("submit")

  // event.preventDefault();


    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   // card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement),
    //   card:{
    //     "number": cardNumber,
    //     "exp_month": date,
    //     "exp_year": year,
    //     "cvc": cvc
    //   }
    // });

    const validate =  await  api.postApi("users/validateCard",{
          "number": cardNumber,
          "exp_month": date,
          "exp_year": year,
          "cvc": cvc
        } )

        if(validate){
          if(validate.status == 200){
            console.log(validate, "validate")

              setSpinner(true);

  setErrorMessage(null);

  setLoading(true);

  const method = localStorage.getItem('method');

           if(method == 'google'){

    const userId = localStorage.getItem('userId');

    const email = localStorage.getItem('email');


     fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: userId , email:email }),
        })
          .then((res) => res.json())
          .then(() => {

            localStorage.setItem('userType', 'user');
            console.log("carddetails1 ==>")
            fetch(`${process.env.REACT_APP_SERVER_URL}/users/carddetails`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ userId: userId, stripecardDetails: card, email: email  }),
            })
            .then((res) => res.json())
            .then(() => {
            
              setRenew_subscription_modal(true);
              setLoading(false);
              setSpinner(false);
            
            });
           
          });



            }else{
    setSpinner(true);

    setLoading(true);

    console.log(cardNumber, "cardNumber")
    console.log(cvc, "cvc")
    console.log(expiryDate, "expiryDate")

    const email = localStorage.getItem('email');

    const password = localStorage.getItem('password');

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: userCredentials.user.uid, email: userCredentials.user.email }),
        })
        .then((res) => res.json())
        .then(() => {
          localStorage.setItem('userType', 'user');
          changeUserType('user');

          console.log("carddetails2 ==>")
                      fetch(`${process.env.REACT_APP_SERVER_URL}/users/carddetails`, {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({ userId: userCredentials.user.uid, stripecardDetails: card, email: email}),
                              })
                              .then((res) => res.json())
                              .then(() => {
                              
                                setRenew_subscription_modal(true);
                                setLoading(true);
                                setSpinner(false);
                              
                              });
          
        });

       

      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
       });

 

  

  
         }
          }else if((validate.status == 300)){
            console.log(validate.message, "validate")
            setErrorMessage(validate.message)
          }else{

          }
        }
    // if(error){

    // console.log(error, "error")

    // setErrorMessage(error.message);

    // console.log(error.message, "error")
    // }else{
    
    //   console.log(paymentMethod, "paymentMethod")

    // }

    // console.log(validate.status, "validate")
    
// if(paymentMethod){

//   setSpinner(true);

//   setErrorMessage(null);

//   setLoading(true);

//   const method = localStorage.getItem('method');

//   if(method == 'google'){

//     const userId = localStorage.getItem('userId');

//     const email = localStorage.getItem('email');


//      fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ userId: userId , email:email }),
//         })
//           .then((res) => res.json())
//           .then(() => {

//             localStorage.setItem('userType', 'user');

//             fetch(`${process.env.REACT_APP_SERVER_URL}/users/carddetails`, {
//               method: "POST",
//               headers: {
//                 "Content-Type": "application/json",
//               },
//               body: JSON.stringify({ userId: userId, stripecardDetails: paymentMethod.card, email: email, paymentMethod: paymentMethod }),
//             })
//             .then((res) => res.json())
//             .then(() => {
            
//               setRenew_subscription_modal(true);
//               setLoading(false);
//               setSpinner(false);
            
//             });
           
//           });



//   }else{
//     setSpinner(true);

//     setLoading(true);

//     console.log(cardNumber, "cardNumber")
//     console.log(cvc, "cvc")
//     console.log(expiryDate, "expiryDate")

//     const email = localStorage.getItem('email');

//     const password = localStorage.getItem('password');

//     createUserWithEmailAndPassword(auth, email, password)
//       .then((userCredentials) => {
//         fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ userId: userCredentials.user.uid, email: userCredentials.user.email }),
//         })
//         .then((res) => res.json())
//         .then(() => {
//           localStorage.setItem('userType', 'user');
//           changeUserType('user');


//                       fetch(`${process.env.REACT_APP_SERVER_URL}/users/carddetails`, {
//                                 method: "POST",
//                                 headers: {
//                                   "Content-Type": "application/json",
//                                 },
//                                 body: JSON.stringify({ userId: userCredentials.user.uid, stripecardDetails: paymentMethod.card, email: email, paymentMethod: paymentMethod }),
//                               })
//                               .then((res) => res.json())
//                               .then(() => {
                              
//                                 setRenew_subscription_modal(true);
//                                 setLoading(true);
//                                 setSpinner(false);
                              
//                               });
          
//         });

       

//       })
//       .catch((error) => {
//         setErrorMessage(error.message);
//         setLoading(false);
//        });

 

  

  
//   }
// }
};

const handleSubmit1 = async () => {

  
  console.log(cardNumber ,  "handleSubmit")
  console.log(expiryDate ,  "expiryDate")
  console.log(cvc ,  "cvc")

let date = expiryDate.toString().split(' / ')[0];
let year = "20"+expiryDate.toString().split(' / ')[1];
console.log(year)
// console.log(dateArray ,  "dateArray")
  // let iframe = document.querySelector('iframe[title="Secure card number input frame"]').contentWindow.document;

  //     // const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

  //     // // Accessing the value of an input inside the iframe
  //     // const inputValue = iframeDocument.getElementById('myInput').value;
  //     console.log('Value inside iframe:', iframe);
}

const [creditCardNumber, setCreditCardNumber] = useState('');

const handleChange = (value) => {

  setCardNumber(value)

};


const handleChangeExpiryDate= (value) => {

  setExpiryDate(value)

};

const handleChangeCVC = (value) => {

  setCvc(value)

};



  return (
      <div className="w-screen h-screen flex justify-center items-center bg-gray-900 relative px-8 lg:px-0">
        <img
          className="object-cover lg:object-stretch h-full w-full absolute inset-0 pointer-events-none"
          src={Background}
        />

        <div  className="flex flex-col items-center z-50 w-full" 
         isOpen={renew_subscription_modal}>
        {renew_subscription_modal && (
    

      <div className="w-full max-w-lg h-full bg-white rounded-3xl shadow-sm p-8 sm:max-w-sm">
        <div>
          <div align="center">
           
            <h1 style={{fontSize:'20px'}}><b>Registered Successfully</b></h1>
            <img  src={Successimage}></img>
            <h2 > Thanks for signing up in Use Haste.<br></br>
             Please check your e-mail to activate your account.
            </h2>

          
            <button
              // style={{
              //   width: "30%",
              //   height: 30,
              //   backgroundColor: "#2563EB",
              //   marginLeft: 10,
              // }}

              className="bg-blue-600 hover:bg-blue-700 mt-6 w-full h-10 text-white font-sans font-semibold text-sm rounded-full"
              onClick={() => {
              
                navigate("/login");
                setRenew_subscription_modal(false);
              }}
            >
             Ok
            </button>
          </div>
        </div>
        </div>
     
      )}

{!renew_subscription_modal && (
      
          // <div className="w-full max-w-lg h-full bg-white rounded-3xl shadow-sm p-8 sm:max-w-sm">

          //   {/* Single CardElement */}
          //   <div className="mb-6">
          //     <span className="font-sans text-gray-900 text-xs font-medium mb-2">Card Number <span style={{ color: 'red' }}>*</span></span>
          //     <CardNumberElement 
          //     className="h-12 w-full mt-2 mb-6 border border-gray-200 rounded-xl font-sans px-4 py-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
          //      options={{ style: { base: { fontSize: '14px' } } }} />

          
          //   </div>

          //   <div className="mb-6">
          //     <span className="font-sans text-gray-900 text-xs font-medium mb-2">Expiry Date <span style={{ color: 'red' }}>*</span></span>
          //     <CardExpiryElement 
          //     className="h-12 w-full mt-2 mb-6 border border-gray-200 rounded-xl font-sans px-4 py-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
          //      options={{ style: { base: { fontSize: '14px' } } }} />
          //   </div>

          //   <div className="mb-6">
          //     <span className="font-sans text-gray-900 text-xs font-medium mb-2">CVC <span style={{ color: 'red' }}>*</span></span>
          //     <CardCvcElement 
          //     className="h-12 w-full mt-2 border border-gray-200 rounded-xl font-sans px-4 py-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
          //      options={{ style: { base: { fontSize: '14px' } } }} />
          //   </div>
         
          //   {errorMessage && (
          //     <div className="mt-6 w-full flex items-center px-4 py-2 bg-red-50 border border-red-100 rounded-lg font-sans font-medium text-red-500 text-xs">
          //       {errorMessage}
          //     </div>
          //   )}
          //   <button
          //   onClick={handleSubmit}
          //     disabled={loading}
          //     className={`${
          //       loading
          //         ? "bg-blue-300 pointer-events-none"
          //         : "bg-blue-600 hover:bg-blue-700"
          //     } mt-6 w-full h-10 text-white font-sans font-semibold text-sm rounded-full `}
          //   >
          //     {spinner && ( <span><i className="fa fa-spinner fa-spin" ></i></span>)}Submit
          //   </button>
            
          // </div>

        
        //   <Formik
        //   initialValues={{
        //     cardNumber: '',
        //     expiryDate: '',
        //     cvc: ''
        //   }}
        //   onSubmit={data => console.log(data)}
        //   validate={() => {
        //     let errors = {};
        //     if (meta.erroredInputs.cardNumber) {
        //       errors.cardNumber = meta.erroredInputs.cardNumber;
        //     }
        //     if (meta.erroredInputs.expiryDate) {
        //       errors.expiryDate = meta.erroredInputs.expiryDate;
        //     }
        //     if (meta.erroredInputs.cvc) {
        //       errors.cvc = meta.erroredInputs.cvc;
        //     }
        //     return errors;
        //   }}
        // >
        //   {({ handleSubmit }) => (
        //     <form onSubmit={handleSubmit}>
        //       <div>
        //         <PaymentInputsWrapper {...wrapperProps}>
        //           {/* <svg {...getCardImageProps({ images })} /> */}
        //           <Field name="cardNumber">
        //             {({ field }) => (
        //               <input {...getCardNumberProps({ onBlur: field.onBlur, onChange: field.onChange })} />
        //             )}
        //           </Field>
        //           <Field name="expiryDate">
        //             {({ field }) => (
        //               <input {...getExpiryDateProps({ onBlur: field.onBlur, onChange: field.onChange })} />
        //             )}
        //           </Field>
        //           <Field name="cvc">
        //             {({ field }) => <input {...getCVCProps({ onBlur: field.onBlur, onChange: field.onChange })} />}
        //           </Field>
        //         </PaymentInputsWrapper>
        //       </div>
        //       <button marginTop="major-2" type="submit">
        //         Submit
        //       </button>
        //     </form>
        //   )}
        // </Formik>

     <>
        {/*  <form onSubmit={handleSubmit}> */}

      

  {/* Single CardElement

  <div className="mb-6">
    <span className="font-sans text-gray-900 text-xs font-medium mb-2">Card Number <span style={{ color: 'red' }}>*</span></span>
    <CardNumberElement 
    className="h-12 w-full mt-2 mb-6 border border-gray-200 rounded-xl font-sans px-4 py-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
     options={{ style: { base: { fontSize: '14px' } } }} />


  </div> */}
  <div className="w-full max-w-lg h-full bg-white rounded-3xl shadow-sm p-8 sm:max-w-sm">
        <FieldSet >
   

<div className="mb-3">
    <span className="font-sans text-gray-900 text-xs font-medium mb-2">Card Number <span style={{ color: 'red' }}>*</span></span>
    {/* <CardNumberElement 
    className="h-12 w-full mt-2 mb-6 border border-gray-200 rounded-xl font-sans px-4 py-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
     options={{ style: { base: { fontSize: '14px' } } }} /> */}
  <InputField
      
          {...getCardNumberProps({
           
            // onMouseEnter {handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            onBlur: () => {console.log("onBlur")}, // Ensure onBlur is always defined
             onChange: (e) =>  {handleChange(e.target.value)} //handleChange('cardNumber', e.target.value)
          })}
          placeholder="0000 0000 0000 0000"
          
          // label="Card number"
          inputRef={getCardNumberProps().ref}
          // You can retrieve error state by making use of the error & touched attributes in `meta`.
          state={erroredInputs.cardNumber && touchedInputs.cardNumber ? 'danger' : undefined}
          validationText={touchedInputs.cardNumber && erroredInputs.cardNumber}
          maxWidth="100%"
        />

  </div>

  <div className="mb-3">
          <span className="font-sans text-gray-900 text-xs font-medium mb-2">Expiry Date <span style={{ color: 'red' }}>*</span></span>
        <InputField
          // {...getExpiryDateProps()}
          {...getExpiryDateProps({
            onBlur: () => {}, // Ensure onBlur is always defined
             onChange: (e) =>  {handleChangeExpiryDate(e.target.value)} //handleChange('cardNumber', e.target.value)
          })}
     
          inputRef={getExpiryDateProps().ref}
          state={erroredInputs.expiryDate && touchedInputs.expiryDate ? 'danger' : undefined}
          validationText={touchedInputs.expiryDate && erroredInputs.expiryDate}
          maxWidth="100%"
        />

</div>
   <div className="mb-3">
         <span className="font-sans text-gray-900 text-xs font-medium mb-2">CVC <span style={{ color: 'red' }}>*</span></span>
        
        <InputField
          // {...getCVCProps()}
          {...getCVCProps({
            
            onBlur: () => {}, // Ensure onBlur is always defined
             onChange: (e) =>  {handleChangeCVC(e.target.value)} //handleChange('cardNumber', e.target.value)
          })}
          placeholder="123"
         
          // label="CVC"
          inputRef={getCVCProps().ref}
          state={erroredInputs.cvc && touchedInputs.cvc ? 'danger' : undefined}
          validationText={touchedInputs.cvc && erroredInputs.cvc}
          maxWidth="100%"
      
        />
        </div>
      </FieldSet>

         {errorMessage && (
               <div className="mt-6 w-full flex items-center px-4 py-2 bg-red-50 border border-red-100 rounded-lg font-sans font-medium text-red-500 text-xs">
                 {errorMessage}
               </div>
             )}
      {/* <button type="submit" onClick={handleSubmit}>Submit</button> */}
         <button
           onClick={handleSubmit}
           disabled={
            loading || // Disable if loading
            erroredInputs.cardNumber || 
            erroredInputs.expiryDate || 
            erroredInputs.cvc 
          }
             className={`${
               loading   ||
            erroredInputs.cardNumber || 
            erroredInputs.expiryDate || 
            erroredInputs.cvc 
                 ? "bg-blue-300 pointer-events-none"
                 : "bg-blue-600 hover:bg-blue-700"
             } mt-6 w-full h-10 text-white font-sans font-semibold text-sm rounded-full `}
           >
             {spinner && ( <span><i className="fa fa-spinner fa-spin" ></i></span>)}Submit
           </button>

    </div>


    </>
           )}
        </div>
       
      </div>
  );
};

export default PaymentForm;
