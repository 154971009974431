import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import ReactHlsPlayer from "react-hls-player";
import ReactTooltip from "react-tooltip";

function QuestionDialog({
  isOpen,
  closeDialog,
  onNextQuestion,
  onPreviousQuestion,
  showIndex,
  interview,
}) {
  const convertSeconds = (duration) => {
    if (duration <= 60) {
      const seconds = Math.round(duration);
      return `${seconds} sec`;
    } else {
      let minutes = Math.floor(duration / 60);
      minutes = Math.round(minutes);

      return `${minutes} min`;
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isOpen]);

  return (
    <Transition
      as={Fragment}
      show={isOpen}
      enter="ease-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100 "
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        open={isOpen}
        onClose={() => closeDialog()}
        className="fixed z-40 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-20 z-20" />

          {interview.questions &&
            interview.questions.map((question, index) => (
              <>
                {index === showIndex && (
                  <div className="w-11/12 md:w-full max-w-lg z-50 bg-white rounded-3xl">
                    <div className="h-auto md:h-96 w-full">
                      <ReactHlsPlayer
                        className="h-full w-full rounded-tl-3xl rounded-tr-3xl"
                        src={`https://stream.mux.com/${question.playbackId}.m3u8`}
                        autoPlay={false}
                        controls={true}
                      />
                    </div>
                    <div className="px-10 pt-8 pb-4 flex flex-col">
                      <span className="font-sans font-medium text-base text-gray-700">
                        {question.title}
                      </span>
                      <div className=" md:mt-14 flex justify-between items-center">
                        <div className="flex items-center">
                          <svg
                            className="w-5 h-5 text-gray-900"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.0266 4.27999C14.9474 3.9636 14.7862 3.6737 14.5591 3.43959C14.332 3.20548 14.0471 3.03545 13.7333 2.94666C12.5866 2.66666 7.99997 2.66666 7.99997 2.66666C7.99997 2.66666 3.41331 2.66666 2.26664 2.97332C1.95281 3.06211 1.66796 3.23215 1.44087 3.46626C1.21378 3.70037 1.0525 3.99026 0.973308 4.30666C0.763451 5.47036 0.660798 6.65086 0.666641 7.83332C0.65916 9.02469 0.761819 10.2142 0.973308 11.3867C1.06061 11.6932 1.22551 11.9721 1.45207 12.1963C1.67863 12.4205 1.95919 12.5825 2.26664 12.6667C3.41331 12.9733 7.99997 12.9733 7.99997 12.9733C7.99997 12.9733 12.5866 12.9733 13.7333 12.6667C14.0471 12.5779 14.332 12.4078 14.5591 12.1737C14.7862 11.9396 14.9474 11.6497 15.0266 11.3333C15.2349 10.1784 15.3375 9.00688 15.3333 7.83332C15.3408 6.64196 15.2381 5.45246 15.0266 4.27999V4.27999Z"
                              stroke="currentColor"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.5 10.0133L10.3333 7.83332L6.5 5.65332V10.0133Z"
                              stroke="currentColor"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span className="text-3xl text-gray-600 mx-3 mb-1">
                            ·
                          </span>
                          <span className="font-sans font-medium text-sm text-gray-900">
                            {convertSeconds(question.duration)}
                          </span>
                        </div>
                        <div className="flex items-center">
                          <>
                            <button
                              onClick={() => onPreviousQuestion()}
                              data-tip="Previous question"
                              className={` ${
                                showIndex == 0
                                  ? "pointer-events-none"
                                  : "hover:bg-gray-100"
                              } h-9 px-3 rounded-full flex justify-center items-center `}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={` ${
                                  showIndex == 0
                                    ? "text-gray-400"
                                    : "text-gray-900"
                                } h-5 w-5`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 19l-7-7 7-7"
                                />
                              </svg>
                            </button>
                            <ReactTooltip
                              effect="solid"
                              backgroundColor="#475569"
                              className="tooltip"
                              arrowColor="transparent"
                            />
                          </>
                          <div className="mx-2 font-sans font-medium text-gray-900 text-sm">
                            {showIndex + 1} / {interview.questions.length}
                          </div>
                          <>
                            <button
                              onClick={() => onNextQuestion()}
                              data-tip="Next question"
                              className={`${
                                showIndex == interview.questions.length - 1
                                  ? "pointer-events-none"
                                  : "hover:bg-gray-100"
                              } h-9 px-3 rounded-full flex justify-center items-center `}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  showIndex == interview.questions.length - 1
                                    ? "text-gray-400"
                                    : "text-gray-900"
                                } h-5 w-5 `}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </button>
                            <ReactTooltip
                              effect="solid"
                              backgroundColor="#475569"
                              className="tooltip"
                              arrowColor="transparent"
                            />
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
      </Dialog>
    </Transition>
  );
}

export default QuestionDialog;
