import { map } from "@firebase/util";

import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "../../components/Pagination";
// import CKEditor from '@ckeditor/ckeditor5-react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Api from "../../Api";

const api = Api();

const Mailtemplate = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [editShowModal, setEditShowModal] = React.useState(false);
  const [viewShowModal, setViewShowModal] = React.useState(false);
  const [status, setStatus] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [editorData, setEditorData] = useState("");
  const [templates, setTemplates] = useState([]);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    _id: "",
    title: "",
    subject: "",
    template: "",
    // Add more fields as needed
  });

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTemplates = templates.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    getmailTemplate();
  }, []);
  const getmailTemplate = () => {
    api
      .getApi("users/getMailTemplate")
      .then((data) => {
        let temp = data
          .slice(0)
          .reverse()
          .map((element) => {
            return element;
          });
        setTemplates(temp);
      })
      .then((error) => console.log(error));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    setFormData({
      ...formData,
      template: data,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    console.log(formData);
  };

  const validate = async () => {
    if (formData.title.trim() === "") {
      setErrorMessage("Please Enter the Title.");
      return false;
    } else if (formData.subject.trim() === "") {
      setErrorMessage("Please Enter the Subject.");
      return false;
    } else if (formData.template.trim() === "") {
      setErrorMessage("Please Select the Template.");
      return false;
    } else {
      setErrorMessage(null);
      return true;
    }
  };

  const saveModal = async () => {
    let valid = await validate();
    console.log("valid", errorMessage);
    if (valid) {
      console.log(formData);
      api
        .postApi("users/addMailTemplate", formData)
        .then((data) => {
          console.log("API response:", data);
          getmailTemplate();
          cancelModal();

          toast.success("Mail Template Added successfully!");
        })
        .catch((error) => console.error("API error:", error));
    }
  };

  const updateModal = async () => {
    let valid = await validate();
    if (valid) {
      api
        .postApi("users/updateMailTemplate", formData)
        .then((data) => {
          console.log("API response:", data);
          cancelModal();
          getmailTemplate();

          toast.success("Mail Template Edited successfully!");
        })
        .catch((error) => console.error("API error:", error));
    }
  };

  const onAdd = () => {
    setShowModal(true);
  };

  const editTemplate = (value) => {
    setEditorData(value.template);
    setFormData({
      _id: value._id,
      title: value.title,
      subject: value.subject,
      template: value.template,
    });
    console.log(value);
    setEditShowModal(true);
    console.log(formData);
  };

  const viewTemplate = (value) => {
    setEditorData(value.template);
    setFormData({
      _id: value._id,
      title: value.title,
      subject: value.subject,
      template: value.template,
    });
    console.log(value);
    setViewShowModal(true);
    console.log(formData);
  };

  const deleteTemplate = (value) => {
    const confirmed = window.confirm("Are you sure you want to delete?");

    if (confirmed) {
      api
        .postApi("users/deleteMailTemplate", value)
        .then((data) => {
          console.log(data);

          getmailTemplate();
          toast.success("Mail Template Deleted successfully!");
        })
        .then((error) => {
          console.log(error);
        });
      // fetch(`${process.env.REACT_APP_SERVER_URL}/subscrtiptionmanage/${subid}`, {
      //   method: "DELETE",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // })
      // .then(response => {
      //   if (!response.ok) {
      //     throw new Error(`HTTP error! Status: ${response.status}`);
      //   }
      //   // Successful deletion
      //   getsubscription();
      //   toast.success("Subscription Deleted successfully!")
      //   console.log("Deleted successfully");
      // })
      // .catch(error => {
      //   console.error("Error deleting:", error);
      //   // Handle error if the deletion fails
      // });
    }
  };

  const cancelModal = () => {
    setFormData({
      _id: "",
      title: "",
      subject: "",
      template: "",
    });
    setEditorData("");
    setErrorMessage(null);
    setShowModal(false);
    setEditShowModal(false);
    setViewShowModal(false);
  };

  const modalHeaderText = showModal
    ? "Add Template"
    : editShowModal
    ? "Edit Template"
    : "View Template";

  return (
    <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
      <Toaster position="top-center" reverseOrder={false} />

      <main className="px-6 pt-8">
        <div className="flex justify-between mb-4">
          <div></div>

          {/* <button className="px-4 py-2 bg-blue-500 text-white rounded">
            <a href="#" onClick={() => onAdd()}>
              Add
            </a>
          </button> */}
        </div>

        <div className="overflow-x-auto relative  sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-4">
                  No
                </th>
                <th scope="col" className="py-3 px-6">
                  Title
                </th>
                <th scope="col" className="py-3 px-6">
                  Subject
                </th>
                {/* <th scope="col" className="py-3 px-6">
                  Template
                </th> */}
                <th scope="col" className="py-3 px-6">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTemplates.map((temp, index) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="p-4 w-4">
                    {index + 1 + (currentPage - 1) * itemsPerPage}
                  </td>
                  <td
                    scope="row"
                    className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="text-base font-semibold">{temp.title}</div>
                  </td>
                  <td className="py-4 px-6">
                    <div className="text-base font-semibold">
                      {" "}
                      {temp.subject}{" "}
                    </div>
                  </td>
                  {/* <td className="py-4 px-6">
                        <div className="flex items-center">
                        <p dangerouslySetInnerHTML={{ __html: temp.template }}></p>

                       
                        </div>
                    </td> */}

                  {/* <td className="py-4 px-6" style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                    <a href="#" type="button" onClick={() => editTemplate(temp)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit </a>
                        <a href="#" type="button" onClick={() => viewTemplate(temp)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">View</a>
                        <a href="#" type="button" onClick={() => deleteTemplate(temp)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</a>
                    </td> */}

                  <td className="py-4 px-6">
                    <a
                      href="#"
                      onClick={() => viewTemplate(temp)}
                      type="button"
                      style={{ padding: "0px 10px 0px 0px" }}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      {" "}
                      View
                    </a>
                    <a
                      href="#"
                      onClick={() => editTemplate(temp)}
                      type="button"
                      style={{ padding: "0px 10px 0px 0px" }}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Edit
                    </a>
                    {/* <a href="#" onClick={() => deleteTemplate(temp)} type="button" style={{padding:'0px 10px 0px 0px'}} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</a> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={templates.length}
          paginateBack={paginateBack}
          paginateFront={paginateFront}
          currentPage={currentPage}
        />

        {showModal || editShowModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-4xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      {modalHeaderText}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => cancelModal(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="p-6 space-y-6">
                    <div className="grid grid-cols-8 gap-6">
                      <div className="col-span-12 sm:col-span-12">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Title <span style={{ color: "red" }}>*</span>
                        </label>

                        {viewShowModal ? (
                          <>
                            {" "}
                            <p>{formData.title}</p>{" "}
                          </>
                        ) : (
                          <>
                            <input
                              type="text"
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Title"
                              name="title"
                              value={formData.title}
                              onChange={handleChange}
                            />
                          </>
                        )}
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <label
                          htmlFor="subscriptionType"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Subject <span style={{ color: "red" }}>*</span>
                        </label>
                        {viewShowModal ? (
                          <>
                            {" "}
                            <p>{formData.subject}</p>{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            <input
                              type="text"
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Subject"
                              name="subject"
                              value={formData.subject}
                              onChange={handleChange}
                            />{" "}
                          </>
                        )}
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <label
                          htmlFor="template"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Template <span style={{ color: "red" }}>*</span>
                        </label>
                        {viewShowModal ? (
                          <>
                            <p
                              dangerouslySetInnerHTML={{ __html: editorData }}
                            ></p>
                          </>
                        ) : (
                          <>
                            <CKEditor
                              editor={ClassicEditor}
                              data={editorData}
                              onInit={(editor) => {
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={handleEditorChange}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {errorMessage && (
                    <div className="mt-6 w-full px-2 py-1 bg-red-50 border border-red-100 rounded-md font-sans font-medium text-red-500 text-xs">
                      {errorMessage}
                    </div>
                  )}

                  {!viewShowModal ? (
                    <>
                      {" "}
                      <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                        <button
                          type="submit"
                          className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                          onClick={() => cancelModal()}
                        >
                          Cancel
                        </button>
                        {showModal ? (
                          <>
                            <button
                              type="submit"
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              onClick={() => saveModal()}
                            >
                              Save
                            </button>
                          </>
                        ) : editShowModal ? (
                          <>
                            <button
                              type="submit"
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              onClick={() => updateModal()}
                            >
                              Update
                            </button>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : viewShowModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-4xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      {modalHeaderText}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => cancelModal()}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="p-6 space-y-6">
                    <div className="grid grid-cols-8 gap-6">
                      <div className="col-span-12 sm:col-span-12">
                        <label
                          for="name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          <b>
                            <span style={{ fontSize: "16px" }}>Title:</span>
                          </b>{" "}
                        </label>
                        <span style={{ fontSize: "16px" }}>
                          {" "}
                          {formData.title}
                        </span>
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <label
                          for="name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          <b>
                            <span style={{ fontSize: "16px" }}>Subject:</span>
                          </b>{" "}
                        </label>
                        <span style={{ fontSize: "16px" }}>
                          {" "}
                          {formData.subject}
                        </span>
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <label
                          for="name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          <b>
                            <span style={{ fontSize: "16px" }}>Template: </span>
                          </b>{" "}
                        </label>
                        {/* <span style={{fontSize:"16px"}}> {formData.template}</span> */}
                        <span
                          style={{ fontSize: "16px" }}
                          dangerouslySetInnerHTML={{ __html: editorData }}
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button
                      type="submit"
                      className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                      onClick={() => cancelModal()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </main>
    </div>
  );
};

export default Mailtemplate;
