import ReactHlsPlayer from "react-hls-player";
import { useState } from "react";

const QuestionScreen = ({ showRecordingScreen, questions, showIndex }) => {
  const [videoControls, setVideoControls] = useState(false);
  const [playing, setPlaying] = useState(false);

  const playVideo = () => {
    setPlaying(true);
    setVideoControls(true);
  };

  return (
    <div>
      {questions.map((question, index) => (
        <>
          {index === showIndex && (
            <div className="max-w-full px-8">
              <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2">
                <span>Interview</span>
                <span>·</span>
                <span>
                  Question {showIndex + 1} of {questions.length}
                </span>
              </div>
              <div className="font-sans font-semibold text-lg text-gray-900 md:mb-5 mb-3 max-w-full truncate">
                {question.title}
              </div>
              <div
                onClick={() => playVideo()}
                className="relative flex group cursor-pointer z-50"
              >
                {!playing && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 h-16 rounded-full bg-white ring-4 ring-white ring-opacity-40 flex justify-center items-center group-hover:scale-110 transition duration-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-8 h-8 fill-current text-gray-900"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                    </svg>
                  </div>
                )}
                <ReactHlsPlayer
                  className="rounded-2xl w-auto custom-max-h m-auto"
                  src={`https://stream.mux.com/${question.playbackId}.m3u8`}
                  controls={videoControls}
                  autoPlay={playing}
                />
              </div>
            </div>
          )}
        </>
      ))}
      <div className="absolute bottom-0 w-full border-t border-gray-200 p-4">
        <button
          onClick={() => showRecordingScreen()}
          className=" w-full h-10 rounded-full flex space-x-1 justify-center items-center bg-blue-600 hover:bg-blue-700"
        >
          <span className="font-sans font-semibold text-white text-sm">
            Record reply
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default QuestionScreen;
