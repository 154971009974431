import { TailSpin } from "react-loader-spinner";

const ButtonLoader = ({ text }) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="font-sans font-semibold text-white text-sm">{text}</span>
      <TailSpin heigth="18" width="18" color="white" ariaLabel="loading" />
    </div>
  );
};

export default ButtonLoader;
