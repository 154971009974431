// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  updateEmail,
  sendPasswordResetEmail,
} from "firebase/auth";

// Your web app's Firebase configuration


const firebaseConfig = {
  apiKey: "AIzaSyAzmtaBUCOFMiZe29Ojn_xeJCyAIX5MBIo",
  authDomain: "haste-3925c.firebaseapp.com",
  projectId: "haste-3925c",
  storageBucket: "haste-3925c.appspot.com",                //client
  messagingSenderId: "332206094081",
  appId: "1:332206094081:web:c01c84850d6e887f164d00",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAc2y_VHGTOyoan2okl-ZwldPSGlXmiE8Q",
//   authDomain: "usehaste.firebaseapp.com",
//   projectId: "usehaste",
//   storageBucket: "usehaste.appspot.com",                //bharathi
//   messagingSenderId: "346366680363",
//   appId: "1:346366680363:web:6100a71a98befb07d1796e"
// };

const app = initializeApp(firebaseConfig);

const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

// Initialize Firebase
export default app;
export {
  auth,
  googleProvider,
  signInWithPopup,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
  updateEmail,
  sendPasswordResetEmail,
};
