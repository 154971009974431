import { map } from '@firebase/util';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Pagination from '../../components/Pagination';


function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const AccessControl = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [status, setStatus] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginateFront = () => setCurrentPage(currentPage + 1);
  const paginateBack = () => setCurrentPage(currentPage - 1);

  useEffect(()=>{
    getUser();
  }, []);

  const getUser = () => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/users`)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
      } else {
        let users = data.slice(0).reverse().map(element => {return element;});
        console.log(users)
        setUsers(users);
      }
    });
  }

  const onEdit = (userId) => {
    let user = users.find((user)=> user.userId == userId);
    setCurrentUser(user);
    setShowModal(true);
    setStatus(user.subscriptionStatus);
  }

  const onUpdate = async () => {
    console.log(currentUser, "current")
    await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${currentUser.userId}/status`,{
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscriptionStatus : status}),
      })
      .then((res) => res.json())
      .then((data) => {
        getUser();
        setShowModal(false);
        toast.success("User data updated successfully!")
      });
  }

  return (

    <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
      <Toaster position="top-center" reverseOrder={false} />
      <main className="px-6 pt-8">
        <div className="overflow-x-auto relative  sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-4">
                  No
                </th>
                <th scope="col" className="py-3 px-6">
                    Name
                </th>
                <th scope="col" className="py-3 px-6">
                    Email
                </th>
                <th scope="col" className="py-3 px-6">
                    Phone Number
                </th>
                <th scope="col" className="py-3 px-6">
                    Company
                </th>
                <th scope="col" className="py-3 px-6">
                    Subscription Plan
                </th>
                <th scope="col" className="py-3 px-6">
                    Status
                </th>
                <th scope="col" className="py-3 px-6">
                    Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index)=>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="p-4 w-4">
                        {(index+1)+(currentPage-1)*itemsPerPage}
                    </td>
                    <td className="py-4 px-6">
                        {user.firstName + " " + user.lastName }
                    </td>
                    <th scope="row" className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap dark:text-white">
                      <div className="text-base font-semibold">{user.email}</div>
                    </th>
                    <td className="py-4 px-6">
                        {user.phone }
                    </td>
                    <td className="py-4 px-6">
                        {user.company }
                    </td>
                    <td className="py-4 px-6">
                        {user.subscriptionType == 'Pro' ? 'Unsubscribe' :  user.subscriptionType}
                    </td>
                    <td className="py-4 px-6">
                        <div className="flex items-center">
                            <div className={
                              user.subscriptionStatus.toLowerCase() == 'active' ?  "h-2.5 w-2.5 rounded-full mr-2 bg-green-400"
                            : user.subscriptionStatus.toLowerCase() == 'cencelling' ? " h-2.5 w-2.5 rounded-full mr-2 bg-yellow-500"
                            : user.subscriptionStatus.toLowerCase() == 'cancelled' ? " h-2.5 w-2.5 rounded-full mr-2 bg-red-500"
                            : " h-2.5 w-2.5 rounded-full mr-2 bg-red-500"
                            }></div>  {capitalizeFirstLetter(user.subscriptionStatus)}
                        </div>
                    </td>
                    <td className="py-4 px-6">
                        <a href="#" type="button" onClick={() => onEdit(user.userId)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit user</a>
                    </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={users.length}
            paginateBack={paginateBack}
            paginateFront={paginateFront}
            currentPage={currentPage}
            />

          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div className="relative w-auto my-6 mx-auto max-w-4xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Edit user
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"  onClick={() => setShowModal(false)}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-8 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email ID</label>
                                <input type="text" name="email" value={currentUser.email} id="email" disabled className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="xyz@gmail.com" />
                            </div>
                            <div className="col-span-12 sm:col-span-12">
                                <label for="subscriptionType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subscription Plan</label>
                                <input type="text" name="subscriptionType" value={currentUser.subscriptionType   == 'Pro' ? 'Unsubscribe' :  currentUser.subscriptionType } id="subscriptionType" disabled className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Subscription Plan"/>
                            </div>

                            <div className="col-span-12 sm:col-span-3">
                              <label for="subscriptionType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subscription Status</label>
                              <select id="countries" value={status} onChange={(e)=>setStatus(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                                <option value="Cancelled" disabled>Cancelled</option>
                                <option value="Cancelling" disabled>Cancelling</option>
                              </select>
                              {/* <label for="subscriptionType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subscription Status</label>
                              <div className="flex items-center w-full mb-12">
                                <label for="toogleA"  className="flex items-center cursor-pointer">
                                  <div className="relative">
                                    <input id="toogleA" type="checkbox" className="sr-only toggle" checked={status} onChange={()=>setStatus(!status)} />
                                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                                    <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                                  </div>
                                  <div className="ml-3 text-gray-700 font-medium">
                                    {status? 'Active' : 'Inactive'}
                                  </div>
                                </label>
                              </div> */}
                            </div>
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                      <button type="submit" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                      onClick={() => setShowModal(false)}>Cancel</button>

                      <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      onClick={() => onUpdate()}>Update</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
        </div>
      </main>
    </div>
  );
}

export default AccessControl;