import Waves from "./waves.png";

const AnswerCard = ({
  date,
  thumbnailGif,
  thumbnailImage,
  openAnswerDialog,
  answerSetId,
  type,
  watched,
  name,
}) => {
  return (
    <div
      onClick={() => openAnswerDialog(answerSetId)}
      className="w-full flex flex-col group relative hover:bg-gray-50 cursor-pointer border border-gray-100 rounded-2xl transform hover:scale-105 transition duration-200"
    >
      {type === "video" ? (
        <div className="w-full h-56 2xl:h-52 relative">
          <img
            className="max-h-full w-full absolute inset-0 rounded-tl-lg rounded-tr-lg opacity-100 group-hover:opacity-0"
            src={thumbnailImage}
          />
          <img
            className="max-h-full w-full absolute inset-0 rounded-tl-lg rounded-tr-lg opacity-0 group-hover:opacity-100"
            src={thumbnailGif}
          />
        </div>
      ) : (
        <div className="w-full h-56 2xl:h-52 relative rounded-tl-lg rounded-tr-lg bg-gray-200 flex justify-center items-center">
          <img className="h-16" src={Waves} />
        </div>
      )}
      <div className="p-5 mt-2 w-full">
        <div className="w-full flex items-start justify-between">
          <div className="flex flex-col space-y-3 max-w-1/2 truncate">
            <span className="font-sans font-medium text-gray-900 text-sm">
              {name}
            </span>
            <span className="font-sans font-medium text-gray-400 text-xs">
              {date}
            </span>
          </div>
          {watched ? (
            <div className="py-1 px-4 rounded-full bg-gray-50 font-sans font-medium text-gray-900 text-xs">
              Watched
            </div>
          ) : (
            <div className="py-1 px-4 rounded-full bg-blue-50 font-sans font-medium text-blue-600 text-xs">
              New
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnswerCard;
