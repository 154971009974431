import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";

const ReviewScreen = ({
  showIndex,
  recordings,
  onNextQuestion,
  submitInterview,
  onRetakeAnswer,
  questionLength,
  uploading,
  videoRecording,
}) => {
  const [videoControls, setVideoControls] = useState();
  const [playing, setPlaying] = useState();

  useEffect(() => {
    setVideoControls(false);
    setPlaying(false);
  }, [showIndex]);

  const playVideo = () => {
    setPlaying(true);
    setVideoControls(true);
  };

  return (
    <div>
      {recordings.map((recording, index) => (
        <>
          {index === showIndex && (
            <div>
              <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2 px-8">
                <span>Interview</span>
                <span>·</span>
                <span>
                  Answer {showIndex + 1} of {questionLength}
                </span>
              </div>
              <div className="font-sans font-semibold text-lg text-gray-900 mb-5 px-8">
                Review your response
              </div>
              <div className="flex flex-col space-y-8">
                {/* Video */}
                <div
                  onClick={() => playVideo()}
                  className="relative group cursor-pointer z-50 max-w-full mx-8"
                >
                  {!playing && videoRecording && (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 h-16 rounded-full bg-white ring-4 ring-white ring-opacity-40 flex justify-center items-center group-hover:scale-110 transition duration-200">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-8 h-8 fill-current text-gray-900"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                      </svg>
                    </div>
                  )}
                  {videoRecording && (
                    <video
                      src={recording.blobUrl}
                      controls={videoControls}
                      autoPlay={playing}
                      className="w-full max-h-80 object-cover rounded-xl"
                    />
                  )}
                  {!videoRecording && (
                    <video
                      src={recording.blobUrl}
                      controls={true}
                      className="w-full h-full max-h-80 object-cover rounded-xl"
                    />
                  )}
                </div>
              </div>
              <div className="absolute bottom-0 w-full border-t border-gray-200 p-4 flex space-x-2">
                <button
                  onClick={() => onRetakeAnswer(recording.blobUrl)}
                  className={` ${
                    uploading ? "pointer-events-none" : "hover:bg-gray-100"
                  } w-full h-10 rounded-full flex space-x-2 justify-center items-center`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-4 w-4 fill-current text-gray-700"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z" />
                  </svg>
                  <span className="font-sans font-semibold text-gray-700 text-sm">
                    Re-take
                  </span>
                </button>
                {recordings.length < questionLength && (
                  <button
                    onClick={() => onNextQuestion()}
                    className="w-full h-10 rounded-full flex space-x-1 justify-center items-center bg-blue-600 hover:bg-blue-700"
                  >
                    <span className="font-sans font-semibold text-white text-sm">
                      Next
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </button>
                )}
                {recordings.length >= questionLength && (
                  <button
                    onClick={(e) => submitInterview(e)}
                    className={`${
                      uploading
                        ? "bg-blue-300 pointer-events-none"
                        : "bg-blue-600 hover:bg-blue-700"
                    } w-full h-10 rounded-full flex space-x-1 justify-center items-center `}
                  >
                    <span className="font-sans font-semibold text-white text-sm">
                      {uploading ? "Submitting" : "Submit"}
                    </span>
                    {uploading ? (
                      <TailSpin
                        heigth="18"
                        width="18"
                        color="white"
                        ariaLabel="loading"
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    )}
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default ReviewScreen;
