// Library Imports
import { useEffect, useState, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";

// Component Imports
import SideMenu from "../../components/SideMenu";
import Dashboard from "./Dashboard";
import { AuthContext } from "../../context/Auth";
import DeleteModal from "./DeleteModal";

const Archived = () => {
  const { currentUser } = useContext(AuthContext);

  const [archiveFolder, setArchiveFolder] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteInterviewId, setDeleteInterviewId] = useState();
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  useEffect(async () => {
    setFetchLoading(true);
    currentUser &&
      (await fetch(
        `${process.env.REACT_APP_SERVER_URL}/folders/default-folders/archive?userId=${currentUser.uid}`
      )
        .then((res) => res.json())
        .then((data) => {
          setArchiveFolder(data);
          setFetchLoading(false);
        }));
  }, [currentUser, loading]);

  // Function - Delete Interview
  const onDeleteInterview = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/interviews/${deleteInterviewId}?userId=${currentUser.uid}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        toast.success("Interview deleted!");
        setDeleteModal(false);
      });
  };

  const openDeleteModal = (interviewId) => {
    setDeleteModal(true);
    setDeleteInterviewId(interviewId);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  // Function - Recover Interview
  const onRecoverInterview = (interviewId) => {
    toast.success("Interview recovered!");
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/folders/recover-interview/${interviewId}?userId=${currentUser.uid}`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <DeleteModal
        isOpen={deleteModal}
        closeDialog={closeDeleteModal}
        onDeleteInterview={onDeleteInterview}
        loading={loading}
      />
      <SideMenu current={"archived"} />
      <Dashboard
        archiveFolder={archiveFolder}
        openDeleteModal={openDeleteModal}
        onRecoverInterview={onRecoverInterview}
        loading={fetchLoading}
      />
    </div>
  );
};

export default Archived;
