// Library Imports
import ReactTooltip from "react-tooltip";
import ta from "time-ago";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";

// Component Imports
import AnswerCard from "./AnswerCard";
import Toggle from "./Toggle";
import toast from "react-hot-toast";
import VideoLoader from "../../components/VideoLoader";
import NewInterviewBtn from "../../components/NewInterviewBtn";

const Dashboard = ({
  interview,
  answerSets,
  archiveInterview,
  openQuestionDialog,
  openMoveFolderDialog,
  enabled,
  handleEnabled,
  openAnswerDialog,
  openDeleteInterviewDialog,
  openArchiveInterviewDialog,
  loading,
}) => {
  const navigate = useNavigate();

  return (
    <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
      <main className="flex-1 mt-16 md:mt-0 py-8 px-6 md:px-32 2xl:py-6 2xl:px-60">
        <div className="hidden md:flex absolute top-5 right-12 flex">
          <>
            <button
              onClick={() => openQuestionDialog()}
              data-tip="View questions"
              className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-5 w-5 text-gray-900 "
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
              </svg>
            </button>
            <ReactTooltip
              effect="solid"
              backgroundColor="#475569"
              className="tooltip"
              arrowColor="transparent"
            />
          </>
          <>
            <button
              onClick={() => openMoveFolderDialog()}
              data-tip="Add to folder"
              className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
            >
              <svg
                className="h-5 w-5 text-gray-900 "
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M12.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V7.75H17.25C18.3546 7.75 19.25 8.64543 19.25 9.75V12.25"
                ></path>
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M13.5 7.5L12.5685 5.7923C12.2181 5.14977 11.5446 4.75 10.8127 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V11"
                ></path>
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M17 14.75V19.25"
                ></path>
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M19.25 17L14.75 17"
                ></path>
              </svg>
            </button>
            <ReactTooltip
              effect="solid"
              backgroundColor="#475569"
              className="tooltip"
              arrowColor="transparent"
            />
          </>
          <>
            <button
              onClick={() => openArchiveInterviewDialog()}
              data-tip="Archive interview"
              className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-900 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                />
              </svg>
            </button>
            <ReactTooltip
              effect="solid"
              backgroundColor="#475569"
              className="tooltip"
              arrowColor="transparent"
            />
          </>
          <>
            <button
              onClick={() => openDeleteInterviewDialog()}
              data-tip="Delete interview"
              className="mr-2 h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-900 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
            <ReactTooltip
              effect="solid"
              backgroundColor="#475569"
              className="tooltip"
              arrowColor="transparent"
            />
          </>
          <NewInterviewBtn />
        </div>
        <div className="pb-4 md:pb-40 max-w-max flex space-x-2 items-center">
        <button
                onClick={() => navigate("/interviews")}
                data-tip="Back"
                className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
              >
        <svg
  xmlns="http://www.w3.org/2000/svg"
  className=" h-5 w-5 text-gray-900  "
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    d="M10 19l-7-7m0 0l7-7m-7 7h18"
  />
</svg>
</button>
          <div className="flex space-x-3 py-2 px-4 rounded-full border border-gray-200">
            <span className="font-sans font-medium text-sm text-gray-500">
              Live
            </span>
            <Toggle enabled={enabled} handleEnabled={handleEnabled} />
          </div>
          {enabled && (
            <div
              onClick={() => {
                copy(`https://app.usehaste.io/answer/${interview._id}`);
                toast.success("Link copied");
              }}
              className="flex items-center space-x-1 cursor-pointer group"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-4 h-4 fill-current text-blue-600 group-hover:text-blue-700"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z" />
              </svg>
              <span className="font-sans font-normal text-xs text-gray-500 tracking-wide w-32 truncate group-hover:text-gray-600">
                app.usehaste.io/answer/312383102
              </span>
            </div>
          )}
        </div>
        <div className="w-full">
          <div className="flex flex-col space-y-4 md:mb-20 mb-4">
            <div className="flex">
            {enabled ? (
              <div className="px-4 py-2 rounded-full bg-green-50 max-w-max flex justify-center items-center space-x-3 border border-green-100">
                <div className="w-1 h-1 rounded-full bg-green-600 ring-2 ring-green-200"></div>
                <span className="font-sans font-semibold text-green-600 text-xs tracking-wider">
                  Live
                </span>

              </div>
            ) : (
              <div className="px-4 py-2 rounded-full bg-gray-100 max-w-max flex space-x-2 items-center border border-gray-200">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 text-gray-900 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                  />
                </svg>
                <span className="font-sans font-semibold text-gray-900 text-xs tracking-wider">
                  Private
                </span>
              </div>
            )}
              <>
              <button
                onClick={() => openQuestionDialog()}
                data-tip="View questions"
                className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-5 w-5 text-gray-900 "
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                </svg>
              </button>
              <ReactTooltip
                effect="solid"
                backgroundColor="#475569"
                className="tooltip"
                arrowColor="transparent"
              />
            </>
            <>
              <button
                onClick={() => openMoveFolderDialog()}
                data-tip="Add to folder"
                className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
              >
                <svg
                  className="h-5 w-5 text-gray-900 "
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M12.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V7.75H17.25C18.3546 7.75 19.25 8.64543 19.25 9.75V12.25"
                  ></path>
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M13.5 7.5L12.5685 5.7923C12.2181 5.14977 11.5446 4.75 10.8127 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V11"
                  ></path>
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M17 14.75V19.25"
                  ></path>
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M19.25 17L14.75 17"
                  ></path>
                </svg>
              </button>
              <ReactTooltip
                effect="solid"
                backgroundColor="#475569"
                className="tooltip"
                arrowColor="transparent"
              />
            </>
            <>
              <button
                onClick={() => openArchiveInterviewDialog()}
                data-tip="Archive interview"
                className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-900 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                  />
                </svg>
              </button>
              <ReactTooltip
                effect="solid"
                backgroundColor="#475569"
                className="tooltip"
                arrowColor="transparent"
              />
            </>
            <>
              <button
                onClick={() => openDeleteInterviewDialog()}
                data-tip="Delete interview"
                className="mr-2 h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-900 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
              <ReactTooltip
                effect="solid"
                backgroundColor="#475569"
                className="tooltip"
                arrowColor="transparent"
              />
            </>
            <NewInterviewBtn />
            </div>
            <h1 className="font-sans text-3xl font-semibold text-gray-900">
              {interview.title}
            </h1>
          </div>
          {/* Interview Container */}
          <div className="w-full flex flex-col">
            <div className="w-full pb-4 border-b border-gray-100 flex justify-start font-sans font-medium text-gray-900">
              Responses
            </div>

            {/* Data is loading */}
            {loading && <VideoLoader />}

            {/* If data has loaded, but there are not interviews */}
            {!loading && answerSets.length < 1 && (
              <div className="w-full bg-primary rounded-md py-32 mt-4">
                <div className="flex flex-col p-4 justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-12 w-12 text-gray-900 mb-4"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M14 14.252v2.09A6 6 0 0 0 6 22l-2-.001a8 8 0 0 1 10-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6.586 6l-1.829-1.828 1.415-1.415L22.414 18l-4.242 4.243-1.415-1.415L18.586 19H15v-2h3.586z" />
                  </svg>
                  <span className="font-sans text-center text-xl text-gray-900 font-semibold mb-2">
                    You haven't received any answers yet.
                  </span>
                  <span className="font-sans text-center text-sm text-gray-500 font-normal mb-4">
                    Share your interview to start receiving answers.
                  </span>
                  <button
                    onClick={() => {
                      copy(`https://app.usehaste.io/answer/${interview._id}`);
                      toast.success("Link copied!");
                    }}
                    className="font-sans font-semibold text-gray-900 border border-gray-200 text-xs px-4 py-2 rounded-full bg-white hover:border-gray-300"
                  >
                    Copy link
                  </button>
                </div>
              </div>
            )}

            {/* If data has loaded, and there are more than 0 interviews */}
            {!loading && answerSets.length > 0 && (
              <div className="mt-4 w-full pb-20 grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-4">
                {answerSets.map((answerSet) => (
                  <AnswerCard
                    title={answerSet.title}
                    date={ta.ago(answerSet.time)}
                    thumbnailImage={
                      answerSet.thumbnails && answerSet.thumbnails.image
                    }
                    thumbnailGif={
                      answerSet.thumbnails && answerSet.thumbnails.gif
                    }
                    answerSetId={answerSet._id}
                    openAnswerDialog={openAnswerDialog}
                    type={answerSet.type}
                    watched={answerSet.watched}
                    name={answerSet.responderName}
                  />
                ))}
              </div>
            )}

            {/* {answerSets.length > 0 ? (
              <div className="mt-4 w-full pb-20 grid grid-cols-3 2xl:grid-cols-4 gap-4">
                {answerSets.map((answerSet) => (
                  <AnswerCard
                    title={answerSet.title}
                    date={ta.ago(answerSet.time)}
                    thumbnailImage={
                      answerSet.thumbnails && answerSet.thumbnails.image
                    }
                    thumbnailGif={
                      answerSet.thumbnails && answerSet.thumbnails.gif
                    }
                    answerSetId={answerSet._id}
                    openAnswerDialog={openAnswerDialog}
                    type={answerSet.type}
                    watched={answerSet.watched}
                    name={answerSet.responderName}
                  />
                ))}
              </div>
            ) : (
              <div className="w-full bg-primary rounded-md py-32 mt-4">
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-12 w-12 text-gray-900 mb-4"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M14 14.252v2.09A6 6 0 0 0 6 22l-2-.001a8 8 0 0 1 10-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6.586 6l-1.829-1.828 1.415-1.415L22.414 18l-4.242 4.243-1.415-1.415L18.586 19H15v-2h3.586z" />
                  </svg>
                  <span className="font-sans text-xl text-gray-900 font-semibold mb-2">
                    You haven't received any answers yet.
                  </span>
                  <span className="font-sans text-sm text-gray-500 font-normal mb-4">
                    Share your interview to start receiving answers.
                  </span>
                  <button
                    onClick={() => {
                      copy(`https://app.usehaste.io/answer/${interview._id}`);
                      toast.success("Link copied!");
                    }}
                    className="font-sans font-semibold text-gray-900 border border-gray-200 text-xs px-4 py-2 rounded-full bg-white hover:border-gray-300"
                  >
                    Copy link
                  </button>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
