const ErrorScreen = () => {
  return (
    <div className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-16 w-16 text-red-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <span className="mt-5 w-full font-sans font-medium text-gray-900 text-sm text-center">
        There seems to be an error.
      </span>
      <span className="mt-2 w-full font-sans font-medium text-gray-500 text-xs text-center">
        We are not able to find this interview.
      </span>
    </div>
  );
};

export default ErrorScreen;
