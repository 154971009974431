import React from "react";
import { useEffect } from "react";
import { useTimer } from "use-timer";
import * as moment from "moment";
import "moment-duration-format";

const TestTimer = ({ timer, reset, onStopRecording }) => {
  const { time, start } = useTimer({
    endTime: 600,
    timerType: "INCREMENTAL",
    onTimeOver: (time) => onStopRecording(),
  });

  useEffect(() => {
    if (timer) {
      start();
    } else {
      reset();
    }
  }, [timer]);

  return (
    <div className="font-sans font-medium text-xs text-gray-700">
      <span>{moment.duration(time, "second").format()}</span>
    </div>
  );
};

export default TestTimer;
