// UserStatusContext.js
import { createContext, useState, useContext } from 'react';
import { AuthContext } from "../context/Auth";
import Api from '../Api';
const UserStatusContext = createContext();

export const useUserStatus = () => {
  return useContext(UserStatusContext);
};

export const UserStatusProvider = ({ children }) => {
  const [userStatus, setUserStatus] = useState("Inactive");
  const { currentUser } = useContext(AuthContext);
  const api = Api();
  const updateUserStatus = async () => {
    try {
      console.log(currentUser.uid , "user currentUser.uid");
      const user = await api.getApi(`users/${currentUser.uid}`);
  
      console.log(user.subscriptionStatus , "user status");

      // Simulate an API call to update the user status
      // Replace this with your actual API call
      // For example, using fetch or axios
      // const response = await fetch('your-api-endpoint', { method: 'PUT', body: JSON.stringify({ status: 'NewStatus' }) });
      
      // Simulated response
      // const data = await response.json();
      
      // Assuming the response includes the updated user status
      // const data = { status: 'NewStatus' };

      setUserStatus(user.subscriptionStatus);
      console.log(userStatus , "user status");
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const contextValue = {
    userStatus,
    updateUserStatus,
  };

  return (
    <UserStatusContext.Provider value={contextValue}>
      {children}
    </UserStatusContext.Provider>
  );
};
