import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const SuccessScreen = ({ interviewId }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="w-full p-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 text-blue-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span className="mt-5 w-full font-sans font-medium text-gray-900 text-sm text-center">
          You have successfully created a new interview!
        </span>
        <span className="mt-2 w-full font-sans font-medium text-gray-500 text-xs text-center">
          Share your interview with the link below.
        </span>
        <div className="mt-6 w-full flex justify-center">
          <button
            onClick={() => {
              copy(`https://app.usehaste.io/answer/${interviewId}`);
              toast.success("Link copied!");
            }}
            className="font-sans font-semibold text-white text-xs px-4 py-2 rounded-full bg-blue-600 hover:bg-blue-700"
          >
            Copy link
          </button>
        </div>
      </div>
      <div className="absolute bottom-0 w-full border-t border-gray-200 p-4">
        <button
          onClick={() => navigate(`/interviews`)}
          className="w-full h-10 rounded-full flex space-x-2 justify-center items-center hover:bg-gray-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-gray-900"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 17l-5-5m0 0l5-5m-5 5h12"
            />
          </svg>
          <span className="font-sans font-medium text-gray-900 text-sm">
            Back to dashboard
          </span>
        </button>
      </div>
    </div>
  );
};

export default SuccessScreen;
