// Library Imports
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Context Imports
import { AuthProvider } from "./context/Auth";
import PrivateRoute from "./components/PrivateRoute";
import PrivateRouteStatus from "./components/PrivateRouteStatus";

// UI 2.0
import NotFound from "./pages/not-found";
import Register from "./pages/register";
import PaymentForm from "./pages/payment";
import Login from "./pages/login";
import Home from "./pages/home/index";
import Answer from "./pages/answer/index";
import Interviews from "./pages/interviews/index";
import Archived from "./pages/archived/index";
import Interview from "./pages/interview/index";
import Folder from "./pages/folder/index";
import NewInterview from "./pages/new-interview/index";
import Settings from "./pages/account-settings/index";
import PaymentSuccess from "./pages/payment-success/index";
import PaymentError from "./pages/payment-error/index";
import Admin from "./pages/admin/index";
import UserProvider from "./context/User";
import PrivateRouteAdmin from "./components/PrivateRouteAdmin";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// import Mailtemplate from "./pages/mail-template/mailtemplate";
import Mailtemplate from "./pages/mail-template/index";
import Subscriptionmanage from "./pages/subscription-manage/index";
import { UserStatusProvider } from './context/UserStatusContext';

import ForgotPassword from "./pages/forgot-password";



function App() {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  return (

    <AuthProvider>
      <UserProvider>
      <UserStatusProvider>
      <BrowserRouter>
        <Routes>
         <Route path="/register/:session_id" element={<Register />} />
          <Route
              path="/card-details"
              element={
                <Elements stripe={stripePromise}>
                  <PaymentForm />
                </Elements>
              }
            />
          <Route path="/login" element={<Login />} />
          <Route path="//forget_password" element={<ForgotPassword />} />
          <Route path="/answer/:interviewId" element={<Answer />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
        
                <Home />

              </PrivateRoute>
            }
          />
          <Route
            path="/interviews"
            element={
              <PrivateRoute>
                <PrivateRouteStatus>
                <Interviews />
                </PrivateRouteStatus>
              </PrivateRoute>
            }
          />
          <Route
            path="/archived"
            element={
              <PrivateRoute>
                <PrivateRouteStatus>
                <Archived />
                </PrivateRouteStatus>
              </PrivateRoute>
            }
          />
          <Route
            path="/interviews/:interviewId"
            element={
              <PrivateRoute>
                <Interview />
              </PrivateRoute>
            }
          />
          <Route
            path="/interviews/folders/:folderId"
            element={
              <PrivateRoute>
                <Folder />
              </PrivateRoute>
            }
          />
          <Route
            path="/create"
            element={
              <PrivateRoute>
                <NewInterview />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
         <Route
            path="/settings/:session_id"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
           <Route
            path='/admin'
            element={
              <PrivateRouteAdmin>
                <Admin />
              </PrivateRouteAdmin>
            }
          />

            <Route
            path='/mailtemplate'
            element={
              <PrivateRouteAdmin>
                <Mailtemplate/>
              </PrivateRouteAdmin>
            }
          />

            <Route
            path='/subscriptionmanage'
            element={
              <PrivateRouteAdmin>
                <Subscriptionmanage/>
              </PrivateRouteAdmin>
            }
          />

          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-error" element={<PaymentError />} />
          <Route path="*"  element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      </UserStatusProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
