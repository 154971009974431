import React, { useState, useEffect, createContext } from "react";

export const UserContext = createContext('');

const UserProvider = ({ children }) => {
  const [userType, setUserType] = useState('');

  const changeUserType = (value) => {
    setUserType(value);
  };

  useEffect(() => {
    let type = localStorage.getItem('userType');
    setUserType(type);
  }, []);

  return (
    <UserContext.Provider value={{ userType, changeUserType }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;