import ReactTooltip from "react-tooltip";

const VideoCard = ({
  title,
  date,
  thumbnailImage,
  thumbnailGif,
  openDeleteModal,
  onRecoverInterview,
  interviewId,
}) => {
  return (
    <div className="w-full flex flex-col group relative  border border-gray-100 rounded-lg">
      {thumbnailImage && thumbnailGif ? (
        <div className="w-full h-40 relative">
          <img
            className="absolute inset-0 rounded-tl-lg rounded-tr-lg opacity-100 group-hover:opacity-0 max-h-60 md:h-48 w-full"
            src={thumbnailImage}
          />
          <img
            className="absolute inset-0 rounded-tl-lg rounded-tr-lg opacity-0 group-hover:opacity-100 max-h-60 md:h-48 w-full"
            src={thumbnailGif}
          />
        </div>
      ) : (
        <div className="w-full h-40 relative rounded-md bg-gray-100 flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-gray-900"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
      )}
      <div className="p-4">
        <div className="mt-16 pb-6 md:pb-12">
          <span className="font-sans font-medium text-gray-700 text-sm">
            {title}
          </span>
        </div>
        <div className="absolute bottom-2 mt-10 w-full flex justify-between items-center pr-6">
          <span className="font-sans font-medium text-gray-400 text-xs">
            {date}
          </span>
          <div className="flex items-center">
            <>
              <div
                onClick={() => onRecoverInterview(interviewId)}
                data-tip="Recover interview"
                className="px-3 py-2 cursor-pointer hover:bg-gray-100 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-gray-700"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M22 13h-2V7h-8.414l-2-2H4v14h9v2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2H21a1 1 0 0 1 1 1v7zm-4 4v-3.5l5 4.5-5 4.5V19h-3v-2h3z" />
                </svg>
              </div>
              <ReactTooltip
                effect="solid"
                backgroundColor="#475569"
                className="tooltip"
                place="bottom"
                arrowColor="transparent"
              />
            </>
            <>
              <div
                onClick={() => openDeleteModal(interviewId)}
                data-tip="Delete interview"
                className="px-3 py-2 cursor-pointer hover:bg-gray-100 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 text-gray-900"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
              <ReactTooltip
                effect="solid"
                backgroundColor="#475569"
                className="tooltip"
                place="bottom"
                arrowColor="transparent"
              />
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
