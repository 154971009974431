import { useContext } from "react";
import { Navigate } from "react-router-dom";
// import { AuthContext } from "../context/Auth";
import { useUserStatus } from "../context/UserStatusContext";

const PrivateRouteStatus = ({ children }) => {
//   const { currentUser } = useContext(AuthContext);
const { userStatus } = useUserStatus();
  console.log(userStatus,"PrivateRoute status")



  return userStatus != "Inactive"  ? children : <Navigate to="/" />;
};

export default PrivateRouteStatus;
