const MoveFolderCard = ({
  folderName,
  selected,
  folderId,
  selectFolder,
  interview,
}) => {
  return (
    <div
      onClick={() => selectFolder(folderId)}
      className={`${selected === folderId && "border-2 border-blue-600"} ${
        interview.folder === folderId && "pointer-events-none"
      } w-full h-40 rounded-lg bg-gray-100 flex flex-col space-y-4 justify-center items-center cursor-pointer relative`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="w-16 h-16 fill-current text-blue-600 opacity-60"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M13.414 5H20a1 1 0 0 1 1 1v1H3V4a1 1 0 0 1 1-1h7.414l2 2zM3.087 9h17.826a1 1 0 0 1 .997 1.083l-.834 10a1 1 0 0 1-.996.917H3.92a1 1 0 0 1-.996-.917l-.834-10A1 1 0 0 1 3.087 9z" />
      </svg>
      <div className="flex items-center justify-center px-4 w-full space-x-2">
        <span className="font-sans font-medium text-gray-700 text-sm max-w-full truncate">
          {folderName}
        </span>
        <div
          className={` ${
            !(interview.folder === folderId) && "hidden"
          } absolute top-2 left-0 p-1 bg-blue-100 rounded-full`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-blue-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default MoveFolderCard;
