import { map } from '@firebase/util';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Pagination from '../../components/Pagination';


const Subscriptionmanage = () => {

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [showModal, setShowModal] = React.useState(false);
    const [subscription, setSubscription] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showModalview, setShowModalView] = React.useState(false);
    const [method, setMethod] = useState();

    const [currentsubscription, setCurrentSubscription] = useState([]);
    const [editcurrentsubscription, setEditCurrentSubscription] = useState([]);

    const [name, setName] = useState('');

    const [amount, setAmount] = useState('');

    const [duration, setDuration] = useState('');
    const [status, setStatus] = useState('');

    const [loading, setLoading] = useState(false);



    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;


    const paginateFront = () => setCurrentPage(currentPage + 1);
    const paginateBack = () => setCurrentPage(currentPage - 1);

    useEffect(()=>{
        getsubscription();
      }, []);


    const getsubscription = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/subscrtiptionmanage/getsubscription`)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
          } else {
            let Subscriptions = data.slice(0).reverse().map(element => {return element;});
            console.log(Subscriptions)
            setSubscription(Subscriptions);
          }
        });
      }



    const onAdd = () => {

        setMethod('add')

      setShowModal(true);

    }

    const onCancel = () => {
      setShowModal(false);
      setName("");
      setAmount("");
      setDuration("");
      setStatus("");

    }



    const onView = (viewsubscription) => {
      console.log(viewsubscription.subid, "subscription")
      setCurrentSubscription(viewsubscription)
        setShowModalView(true);

      }

      const onDelete = (id) => {
        console.log(id, "id")

        const subid = id;

        const confirmed = window.confirm("Are you sure you want to delete?");

        if (confirmed) {
          // Proceed with the delete operation using fetch
          fetch(`${process.env.REACT_APP_SERVER_URL}/subscrtiptionmanage/${subid}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Successful deletion
            getsubscription();
            toast.success("Subscription Deleted successfully!")
            console.log("Deleted successfully");
          })
          .catch(error => {
            console.error("Error deleting:", error);
            // Handle error if the deletion fails
          });
        }


        }


    const Addsubscription = () => {
        console.log("name", name);
        console.log("amount", typeof(amount));
        const intAmount = parseInt(amount);
        console.log("duration", duration);

        if (name === "") {
          setErrorMessage("Please Enter the Subscription Name.");
          return;
        } else if (amount === "" ) {
          setErrorMessage("Please Enter the Amount.");
          return;
        } else if (intAmount <= 0) {
          setErrorMessage("Please enter an amount greater than 0.");
          return;
        }else if (duration === "") {
          setErrorMessage("Please Select the Duration.");
          return;
        }else if (status === "") {
            setErrorMessage("Please Select the Status.");
            return;
          }

        setLoading(true);
        setErrorMessage(null)

        fetch(`${process.env.REACT_APP_SERVER_URL}/subscrtiptionmanage`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionname: name,
            price: amount,
            duration: duration,
            status: status
          }),
        })
          .then((res) => res.json())
          .then((savedsubscription) => {
            setLoading(false);
            setShowModal(false);
            getsubscription();
            toast.success("Subscription Added successfully!")
            setName("");
            setAmount("");
            setDuration("");
            setStatus("");
            // Handle the response or any further logic
          })
          .catch((error) => {
            setErrorMessage(error.message);
            setLoading(false);
          });
      };

      const onEdit = (subs) => {
        setMethod('edit')
        setShowModal(true);
        setName(subs.subscriptionname);
        console.log(subs.price, "subs.price")
        const priceWithoutSymbol = subs.price.replace(/\$/g, '');
         setAmount(priceWithoutSymbol);
        setDuration(subs.duration);
        setStatus(subs.status);
        setEditCurrentSubscription(subs);

      }

      const Editsubscription = () => {
        console.log("name", name);
        console.log("amount", typeof(amount));
        const intAmount = parseInt(amount);
        console.log("duration", duration);
        console.log("status", status);

        const subid = editcurrentsubscription.subid;
        console.log(subid, "subid")

        console.log(editcurrentsubscription, "status")

        if (name === "") {
            setErrorMessage("Please Enter the Subscription Name.");
            return;
          } else if (amount === "") {
            setErrorMessage("Please Enter the Amount.");
            return;
          }else if (intAmount <= 0) {
            setErrorMessage("Please enter an amount greater than 0.");
            return;
          }
           else if (duration == "") {
            console.log("ff")
            setErrorMessage("Please Select the Duration.");
            return;
          }else if (status == "") {
            console.log("gg")
              setErrorMessage("Please Select the Status.");
              return;
            }

        setLoading(true);
        setErrorMessage(null)

        fetch(`${process.env.REACT_APP_SERVER_URL}/subscrtiptionmanage/${subid}/editsubscription`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionname: name,
            price: amount,
            duration: duration,
            status: status,
            subid: subid
          }),
        })
          .then((res) => res.json())
          .then((savedsubscription) => {
            setLoading(false);
            setShowModal(false);
            getsubscription();
            toast.success("Subscription Edited successfully!")
            setName("");
            setAmount("");
            setDuration("");
            setStatus("");
            // Handle the response or any further logic
          })
          .catch((error) => {
            setErrorMessage(error.message);
            setLoading(false);
          });

      }




    return (

      <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
        <Toaster position="top-center" reverseOrder={false} />

        <main className="px-6 pt-8">

        <div className="flex justify-between mb-4">
          <div></div>

          {/* <button onClick={() => onAdd()} className="px-4 py-2 bg-blue-500 text-white rounded">
            Add
          </button> */}
        </div>


          <div className="overflow-x-auto relative  sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4">
                    No
                  </th>
                  <th scope="col" className="py-3 px-6">
                     Name
                  </th>
                  <th scope="col" className="py-3 px-6">
                     Amount
                  </th>

                  <th scope="col" className="py-3 px-6">
                    Duration
                  </th>

                  <th scope="col" className="py-3 px-6">
                   Status
                  </th>

                  <th scope="col" className="py-3 px-6">
                      Action
                  </th>
                </tr>
              </thead>
              <tbody>
              {subscription.map((subscription, index)=>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="p-4 w-4">
                        {(index+1)+(currentPage-1)*itemsPerPage}
                    </td>
                    <th scope="row" className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap dark:text-white">
                      <div className="text-base font-semibold">{subscription.subscriptionname}</div>
                    </th>
                    <td className="py-4 px-6">
                    £{subscription.price}
                    </td>
                    <td className="py-4 px-6">
                    {subscription.duration}
                    </td>

                    <td className="py-4 px-6">
                        <div className="flex items-center">
                            <div className={
                              subscription.status.toLowerCase() == 'active' ?  "h-2.5 w-2.5 rounded-full mr-2 bg-green-400"
                              : " h-2.5 w-2.5 rounded-full mr-2 bg-red-500"
                            }></div>  {subscription.status}
                        </div>
                    </td>


                    <td className="py-4 px-6">
                        <a href="#" onClick={() => onView(subscription)} type="button" style={{padding:'0px 10px 0px 0px'}} className="font-medium text-blue-600 dark:text-blue-500 hover:underline"> View</a>
                        <a href="#" onClick={() => onEdit(subscription)} type="button" style={{padding:'0px 10px 0px 0px'}} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                        {/* <a href="#" onClick={() => onDelete(subscription.subid)} type="button" style={{padding:'0px 10px 0px 0px'}} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</a> */}
                    </td>


                </tr>
              )}
            </tbody>
              </table>
              </div>

              <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={subscription.length}
            paginateBack={paginateBack}
            paginateFront={paginateFront}
            currentPage={currentPage}
            />


              {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div className="relative w-auto my-6 mx-auto max-w-4xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        {method === 'add' ? 'Add Subscription' : 'Edit Subscription'}
                        </h3>
                       <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"  onClick={() => onCancel()}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-8 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subscription Name <span style={{ color: 'red' }}>*</span></label>
                                <input type="text" value={name} onChange={(e)=>setName(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Subscription Name" />
                            </div>
                            <div className="col-span-12 sm:col-span-12">
                                <label for="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">£ Amount <span style={{ color: 'red' }}>*</span></label>
                                <input type="number" min={1} value={amount} onChange={(e)=>setAmount(e.target.value)}  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="£ Amount"/>
                            </div>

                            <div className="col-span-12 sm:col-span-12">
                            <label for="duration" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Duration <span style={{ color: 'red' }}>*</span></label>
                            <select id="countries" value={duration} onChange={(e)=>setDuration(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="">Select Duration</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly" >Yearly</option>

                              </select>
                                </div>

                                <div className="col-span-12 sm:col-span-12">
                            <label for="duration" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status <span style={{ color: 'red' }}>*</span></label>
                            <select id="countries" value={status} onChange={(e)=>setStatus(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="">Select Status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>


                              </select>
                                </div>


                        </div>
                    </div>
                    {errorMessage && (
                        <div className="mt-6 w-full px-2 py-1 bg-red-50 border border-red-100 rounded-md font-sans font-medium text-red-500 text-xs">
                        {errorMessage}
                        </div>
                    )}
                    {/*footer*/}
                    <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                      <button type="submit" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                      onClick={() => onCancel()}>Cancel</button>

                      {method === 'add' ? (
                        <button
                            disabled={loading}
                            onClick={() => Addsubscription()}
                            type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Add
                        </button>
                        ) : (
                        <button
                            disabled={loading}
                            onClick={() => Editsubscription()}
                            type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Update
                        </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}




{showModalview ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div className="relative w-auto my-6 mx-auto max-w-4xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            View Subscription
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"  onClick={() => setShowModalView(false)}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-8 gap-6">

                        <div className="col-span-12 sm:col-span-12">


                                <label  for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><b><span style={{fontSize:"16px"}}>Subscription Name: </span></b>  </label>
                                <span style={{fontSize:"16px"}}> {currentsubscription.subscriptionname}</span>






                              </div>
                              <div className="col-span-12 sm:col-span-12">
                                <label  for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><b><span style={{fontSize:"16px"}}>Amount: </span></b>  </label>
                                <span style={{fontSize:"16px"}}> £{currentsubscription.price}</span>

                              </div>
                              <div className="col-span-12 sm:col-span-12">
                                <label  for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><b><span style={{fontSize:"16px"}}>Duration:  </span></b> </label>
                                <span style={{fontSize:"16px"}}> {currentsubscription.duration}</span>

                              </div>
                              <div className="col-span-12 sm:col-span-12">
                                <label  for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><b><span style={{fontSize:"16px"}}>Status: </span></b>   </label>
                                <span style={{fontSize:"16px"}}> {currentsubscription.status}</span>

                              </div>


                        </div>
                    </div>
                    {errorMessage && (
                        <div className="mt-6 w-full px-2 py-1 bg-red-50 border border-red-100 rounded-md font-sans font-medium text-red-500 text-xs">
                        {errorMessage}
                        </div>
                    )}
                    {/*footer*/}
                    <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                      <button type="submit" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                      onClick={() => setShowModalView(false)}>Close</button>


                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}


        </main>

      </div>
    );

}

export default Subscriptionmanage;