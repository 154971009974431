const IntroScreen = ({ showNameScreen, questions }) => {
  return (
    <div className="flex flex-col w-full max-h-full justify-between">
      <div>
        <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2 px-8">
          <span>Interview</span>
          <span>·</span>
          <span>
            {questions.length}{" "}
            {questions.length <= 1 ? "question" : "questions"}
          </span>
        </div>
        <div className="font-sans font-semibold text-2xl text-gray-900 mb-5 px-8">
          Welcome to Haste
        </div>
        <div className="font-sans font-normal text-gray-700 text-sm leading-6 mb-5 px-8">
          You have been invited to answer a few questions. This is an
          asynchronous interview tool, which means that you will be able to
          watch each question first, before recording your response. <br></br>
          <br></br> You can record your responses in{" "}
          <span className="font-semibold text-gray-900">video</span> or{" "}
          <span className="font-semibold text-gray-900">audio-only</span>{" "}
          format, depending on your preference. For any issues please{" "}
          <a
            className="text-blue-700 text-semibold hover:underline"
            href="mailto:contact@usehaste.io"
          >
            contact
          </a>{" "}
          us. <br></br>
          <br></br> Thanks in advance. <br></br>
          <br></br>
          <span className="font-medium text-gray-900 mt-1">The Haste Team</span>
        </div>
      </div>
      <div className="absolute bottom-0 w-full border-t border-gray-200 p-4">
        <button
          onClick={() => showNameScreen()}
          className="w-full h-10 rounded-full bg-blue-600 flex space-x-1 justify-center items-center hover:bg-blue-700"
        >
          <span className="font-sans font-semibold text-white text-sm">
            Start
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default IntroScreen;
