const SuccessScreen = () => {
  return (
    <div className="w-full p-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-16 w-16 text-blue-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span className="mt-5 w-full font-sans font-medium text-gray-900 text-sm text-center">
        You have successfully submitted your response!
      </span>
      <span className="mt-2 w-full font-sans font-medium text-gray-500 text-xs text-center">
        You can now close this window.
      </span>
    </div>
  );
};

export default SuccessScreen;
