// Library Imports
import { useContext, useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";

// Component Imports
import SideMenu from "../../components/SideMenu";
import Dashboard from "./Dashboard";
import ActionDialog from "../../components/ActionDialog";
import { AuthContext } from "../../context/Auth";
import { useRef } from 'react';

const Settings = () => {
  const { currentUser } = useContext(AuthContext);
  const childRef = useRef();   // 

  const [userStatus, setUserStatus] = useState(1);
  const [subscription, setSubscription] = useState({});
  const [cancelSubscriptionDialog, setCancelSubscriptionDialog] =
    useState(false);
  const [renewSubscriptionDialog, setRenewSubscriptionDialog] = useState(false);
  const [loading, setLoading] = useState();

  // Get subscription data for the user.
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/users/${currentUser.uid}`)
      .then((res) => res.json())
      .then((user) =>
        setSubscription({
          type: user.subscriptionType,
          periodEnd: user.subscriptionPeriodEnd,
          status: user.subscriptionStatus,
        })
      );
  }, [cancelSubscriptionDialog, renewSubscriptionDialog]);

  // Upgrade susbcription by either creating a new one or renewing an existing one.
  const upgradeSubscription = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/subscriptions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser.uid,
        }),
      }
    );
    const { newSubscription, url } = await response.json();

    if (newSubscription) {
      closeRenewSubscriptionDialog(false);
      window.location.href = url;
      setLoading(false);
    } else {
      closeRenewSubscriptionDialog(false);
      toast.success("Subscription renewed!");
      setLoading(false);
    }
  };

  // Updated subscripton by cancelling end of month.
  const cancelSubscription = async () => {
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_SERVER_URL}/subscriptions/cancel?userId=${currentUser.uid}`,
      {
        method: "PATCH",
      }
    );
    setLoading(false);
    closeCancelSubscriptionDialog();
    toast.success("Subscription cancelled");
  };

  // Open cancel-subscription dialog
  const openCancelSubscriptionDialog = () => {
    setCancelSubscriptionDialog(true);
  };

  // Close cancel-subscription dialog
  const closeCancelSubscriptionDialog = () => {
    setCancelSubscriptionDialog(false);
  };

  // Open renew-subscription dialog
  const openRenewSubscriptionDialog = () => {
    setRenewSubscriptionDialog(true);
  };

  // Close renew-subscription dialog
  const closeRenewSubscriptionDialog = () => {
    setRenewSubscriptionDialog(false);
  };

  const handleChangeStatus = () => {
    childRef.current.childFunction();   
  }

  return (
    <div>
      <ActionDialog
        title={"Renew subscription"}
        subtitle={
          "If you renew your subsription, you will be able to create new interviews or receive more responses."
        }
        btnTitle={"Renew"}
        btnStyle={"bg-blue-600 hover:bg-blue-700"}
        isOpen={renewSubscriptionDialog}
        closeDialog={closeRenewSubscriptionDialog}
        action={upgradeSubscription}
        loading={loading}
        loadingStyle={"bg-blue-300 pointer-events-none"}
        loadingText={"Renewing"}
      />
      <ActionDialog
        title={"Cancel subscription"}
        subtitle={
          "If you cancel your subsription, you will still have access to your data, but you won't be able to receive new responses or create more interviews."
        }
        btnTitle={"Cancel"}
        btnStyle={"bg-red-600 hover:bg-red-700"}
        isOpen={cancelSubscriptionDialog}
        closeDialog={closeCancelSubscriptionDialog}
        action={cancelSubscription}
        loading={loading}
        loadingStyle={"bg-red-300 pointer-events-none"}
        loadingText={"Cancelling"}
      />
      <Toaster position="top-center" reverseOrder={false} />
      <SideMenu   ref={childRef} />
      <Dashboard
        onChangeStatus={handleChangeStatus}
        upgradeSubscription={upgradeSubscription}
        subscription={subscription}
        openCancelSubscriptionDialog={openCancelSubscriptionDialog}
        openRenewSubscriptionDialog={openRenewSubscriptionDialog}
      />
    </div>
  );
};

export default Settings;
