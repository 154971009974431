import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ReactHlsPlayer from "react-hls-player";
import ReactTooltip from "react-tooltip";

const AnswerDialog = ({
  isOpen,
  closeDialog,
  answerData,
  showAnswerIndex,
  onNextAnswer,
  onPreviousAnswer,
  interview,
  showIndex,
  openDeleteAnswerDialog,
}) => {
  const convertSeconds = (duration) => {
    if (duration <= 60) {
      const seconds = Math.round(duration);
      return `${seconds} sec.`;
    } else {
      let minutes = Math.floor(duration / 60);
      minutes = Math.round(minutes);

      return `${minutes} min`;
    }
  };

  return (
    <Transition
      as={Fragment}
      show={isOpen}
      enter="ease-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100 "
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        open={isOpen}
        onClose={() => closeDialog()}
        className="fixed z-40 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-20 z-20" />
          {answerData &&
            answerData.answers.map((answer, index) => {
              console.log(answer)
              return (

              <>
                {index === showAnswerIndex && (
                  <div className="w-full max-w-lg z-50 bg-white rounded-3xl">
                    <div
                      // className={`${
                      //   answer.type === "audio" ? "px-8 pt-6" : "h-auto"
                      // } w-full `}
                        className="px-8 pt-6"
                    >
                      <ReactHlsPlayer
                        // className={`${
                        //   answer.type === "audio" ? "h-10 w-full" : "h-full w-full"
                        // }  rounded-tl-3xl rounded-tr-3xl`}
                        //  className="rounded-2xl w-auto custom-max-h m-auto"
                         className=" w-full rounded-tl-3xl rounded-tr-3xl"
                        src={`https://stream.mux.com/${answer.playbackId}.m3u8`}
                        autoPlay={false}
                        controls={true}
                      />
                    </div>
                    <div className="md:p-8 p-6 pb-4 flex flex-col">
                      <div className="w-full p-6 rounded-xl bg-gray-50 flex flex-col space-y-2">
                        {interview.questions &&
                          interview.questions.map((question, index) => (
                            <>
                              {index === showIndex && (
                                <>
                                  <span className="font-sans font-medium text-xs text-gray-400 tracking-wide">
                                    Question {showIndex + 1} of{" "}
                                    {interview.questions.length}
                                  </span>
                                  <span className="font-sans font-medium text-sm text-gray-900">
                                    {question.title}
                                  </span>
                                </>
                              )}
                            </>
                          ))}
                      </div>
                      <div className="w-full mt-5 md:mt-10">
                        <div className="flex justify-start pb-3 ">
                          <span className="font-sans font-semibold text-gray-900 text-sm">
                            Responder Details
                          </span>
                        </div>
                      </div>
                      <div className="w-full mt-3">
                        <div className="flex justify-between pb-3 border-b border-gray-100">
                          <span className="font-sans font-medium text-gray-500 text-sm tracking-wide">
                            Name
                          </span>
                          <span className="font-sans font-medium text-gray-900 text-sm">
                            {answerData.responderName
                              ? answerData.responderName
                              : "Not Provided"}
                          </span>
                        </div>
                      </div>
                      <div className="w-full mt-3">
                        <div className="flex justify-between pb-3 border-b border-gray-100">
                          <span className="font-sans font-medium text-gray-500 text-sm tracking-wide">
                            Email
                          </span>
                          <span className="font-sans font-medium text-gray-900 text-sm">
                            {answerData.responderEmail
                              ? answerData.responderEmail
                              : "Not Provided"}
                          </span>
                        </div>
                      </div>

                      <div className="mt-5 md:mt-10 flex justify-between items-center">
                        <div className="flex items-center">
                          {answerData.type === "video" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              className="h-5 w-5  text-gray-700"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M19.606 6.995c-.076-.298-.292-.523-.539-.592C18.63 6.28 16.5 6 12 6s-6.628.28-7.069.403c-.244.068-.46.293-.537.592C4.285 7.419 4 9.196 4 12s.285 4.58.394 5.006c.076.297.292.522.538.59C5.372 17.72 7.5 18 12 18s6.629-.28 7.069-.403c.244-.068.46-.293.537-.592C19.715 16.581 20 14.8 20 12s-.285-4.58-.394-5.005zm1.937-.497C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5v-7l6 3.5-6 3.5z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5  text-gray-700"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                              />
                            </svg>
                          )}
                          <span className="font-sans font-medium text-xl text-gray-900 mx-3">
                            ·
                          </span>
                          <span className="font-sans font-medium text-sm text-gray-900">
                            {convertSeconds(answer.duration)}
                          </span>
                        </div>
                        <div className="flex items-center">
                          <div className="flex items-center">
                            <>
                              <button
                                onClick={() => onPreviousAnswer()}
                                data-tip="Previous answer"
                                className={` ${
                                  showAnswerIndex == 0
                                    ? "pointer-events-none"
                                    : "hover:bg-gray-100"
                                } h-9 px-3 rounded-full flex justify-center items-center `}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={` ${
                                    showAnswerIndex == 0
                                      ? "text-gray-400"
                                      : "text-gray-900"
                                  } h-5 w-5`}
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 19l-7-7 7-7"
                                  />
                                </svg>
                              </button>
                              <ReactTooltip
                                effect="solid"
                                backgroundColor="#475569"
                                className="tooltip"
                                arrowColor="transparent"
                              />
                            </>
                            <div className="mx-2 font-sans font-medium text-gray-900 text-sm">
                              {showAnswerIndex + 1} /{" "}
                              {answerData.answers.length}
                            </div>
                            <>
                              <button
                                onClick={() => onNextAnswer()}
                                data-tip="Next answer"
                                className={`${
                                  showAnswerIndex ==
                                  answerData.answers.length - 1
                                    ? "pointer-events-none"
                                    : "hover:bg-gray-100"
                                } h-9 px-3 rounded-full flex justify-center items-center `}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={`${
                                    showAnswerIndex ==
                                    answerData.answers.length - 1
                                      ? "text-gray-400"
                                      : "text-gray-900"
                                  } h-5 w-5 `}
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5l7 7-7 7"
                                  />
                                </svg>
                              </button>
                              <ReactTooltip
                                effect="solid"
                                backgroundColor="#475569"
                                className="tooltip"
                                arrowColor="transparent"
                              />
                            </>
                          </div>
                          <a
                           href="#"
                           onClick={(event) => {
                             event.preventDefault();
                             window.location.href = `mailto:${answerData.responderEmail}?subject=Hey ${answerData.responderName}! Thanks for your response to my Haste interview!`;
                           }}
                            // href={`mailto:${answerData.responderEmail}?subject=Hey ${answerData.responderName}! Thanks for your response to my Haste interview!`}
                            data-tip="Reply"
                            className="py-2 px-3 rounded-full hover:bg-gray-50 cursor-pointer group"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 fill-current text-gray-600 "
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <ReactTooltip
                              effect="solid"
                              backgroundColor="#475569"
                              className="tooltip"
                              arrowColor="transparent"
                            />
                          </a>
                          <button
                            onClick={() =>
                              openDeleteAnswerDialog(answerData._id)
                            }
                            data-tip="Delete answer"
                            className="py-2 px-3 rounded-full hover:bg-red-50 cursor-pointer group"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 text-gray-900 group-hover:text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                            <ReactTooltip
                              effect="solid"
                              backgroundColor="#475569"
                              className="tooltip"
                              arrowColor="transparent"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          )}
        </div>
      </Dialog>
    </Transition>
  );
};

export default AnswerDialog;
