import { useRef, useEffect } from "react";

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  // return <video ref={videoRef} autoPlay className="rounded-2xl" />;
  return  <video ref={videoRef} autoPlay playsInline muted className="rounded-2xl max-h-80" />;
  
  // <video className="rounded-2xl max-h-80" muted={true} autoplay={true} playsinline={true}>
  // <source ref={videoRef}/>
  // </video>
  // 
//   <video className="rounded-2xl max-h-80" autoplay playsinline muted>
//   <source ref={videoRef} />
// </video>;
};

export default VideoPreview;
