import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import MoveFolderCard from "./MoveFolderCard";
import ButtonLoader from "../../components/ButtonLoader";

function MoveFolderDialog({
  isOpen,
  closeDialog,
  interview,
  folders,
  selected,
  selectFolder,
  moveToFolder,
  loading,
}) {
  return (
    <Transition
      as={Fragment}
      show={isOpen}
      enter="ease-out duration-300"
      enterFrom="opacity-10"
      enterTo="opacity-100"
      leave="ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        open={isOpen}
        onClose={() => closeDialog()}
        className="fixed z-40 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-20 z-20" />
          <div className="w-11/12 md:w-full max-w-md bg-white z-50 rounded-2xl">
            <div className="w-full px-4 py-2 flex justify-between items-center border-b border-gray-200">
              <span className="font-sans font-semibold text-gray-900 text-sm">
                Move {interview.title}
              </span>
              <div
                onClick={() => closeDialog()}
                className="px-3 py-2 rounded-full hover:bg-gray-100 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="p-4 flex flex-col">
              <div className="w-full max-h-96 py-4 grid grid-cols-2 gap-2 overflow-auto no-scrollbar">
                {folders &&
                  folders.map((folder) => (
                    <MoveFolderCard
                      interview={interview}
                      selected={selected}
                      selectFolder={selectFolder}
                      folderName={folder.name}
                      folderId={folder._id}
                    />
                  ))}
              </div>
              <div className="mt-10 w-full flex justify-center space-x-2 items-center">
                <button
                  onClick={() => closeDialog()}
                  className="w-1/2 bg-gray-100 rounded-full h-10 font-sans font-medium text-gray-900 text-sm hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  onClick={() => moveToFolder()}
                  className={` ${
                    !selected || loading
                      ? "bg-blue-200 pointer-events-none"
                      : "bg-blue-600 hover:bg-blue-700"
                  } w-1/2  rounded-full h-10 font-sans font-semibold text-white text-sm flex justify-center items-center`}
                >
                  {loading ? <ButtonLoader text={"Moving"} /> : "Move"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default MoveFolderDialog;
