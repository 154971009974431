const IntroScreen = ({ showNameScreen, userSubscription }) => {
  return (
    <div className="flex flex-col w-full max-h-full justify-between">
      <div>
        <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2 pt-5 md:pt-8 px-8">
          <span>Interview</span>
          <span>·</span>
          {userSubscription && userSubscription.length > 0 && <span>Question 0/8</span>}
          {/* {userSubscription === "Pro" && <span>Unlimited Questions</span>} */}
        </div>
        <div className="font-sans font-semibold text-2xl text-gray-900 mb-3 md:mb-5 px-8">
          Create a new interview
        </div>
        <div className="font-sans font-normal text-gray-700 text-sm leading-6 mb-3 md:mb-5 px-8">
          {userSubscription && userSubscription.length > 0 && (
            <span>
              The maximum limit of questions you can record per interview is 8.
            </span>
          )}
          {/* {userSubscription === "Pro" && (
            <span>You can record unlimited number of questions.</span>
          )} */}
          Once the recordings have been done, you will be able to review them
          before finally submitting them.
          <br></br>
          <br></br> You can record your questions in{" "}
          <span className="font-semibold text-gray-900">video</span> format
          only. For any issues please{" "}
          <a
            className="text-blue-700 text-semibold hover:underline"
            href="mailto:contact@usehaste.io"
          >
            contact
          </a>{" "}
          us. <br></br>
          <br></br>
          <div className="w-full rounded-xl p-3 md:p-4 bg-gray-100 flex space-x-3 items-start ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 -mt-2 text-gray-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="font-sans font-medium text-gray-900 text-sm leading-6">
              Before you start recording please ensure your camera and
              microphone are not being blocked and have been enabled.
            </span>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full border-t border-gray-200 p-3 md:p-4">
        <button
          onClick={() => showNameScreen()}
          className="w-full h-10 rounded-full bg-blue-600 flex space-x-1 justify-center items-center hover:bg-blue-700"
        >
          <span className="font-sans font-semibold text-white text-sm">
            Start
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default IntroScreen;
