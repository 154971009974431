import { ReactMediaRecorder } from "react-media-recorder";
import VideoPreview from "../../components/VideoPreview";
import CountdownTimer from "../../components/CountdownTimer";
import TestTimer from "../../components/TestTimer";

const RecordQuestionScreen = ({
  onRecordingComplete,
  countdownTimer,
  turnOnCountdownTimer,
  turnOffCountdownTimer,
  timer,
  turnOnTimer,
  turnOffTimer,
  recordings,
  userSubscription,
}) => {
  return (
    <div>
      <ReactMediaRecorder
        onStop={(blobUrl, blob) => {
          onRecordingComplete(blobUrl, blob);
        }}
        audio={true}
        video={true}
        render={({
          error,
          status,
          startRecording,
          stopRecording,
          previewStream,
        }) => (
          <div>
            <div className="flex font-sans font-medium text-gray-500 text-xs space-x-1 mb-2 pt-8 px-8">
              <span>Interview</span>
              <span>·</span>
              {userSubscription && userSubscription.length > 0 && (
                <span>Question {recordings.length}/8</span>
              )}
              {/* {userSubscription === "Pro" && (
                <span>Question {recordings.length + 1}</span>
              )} */}
            </div>
            <div className="font-sans font-semibold text-xl text-gray-900 mb-5 px-8">
              Record your questions
            </div>
            <div className="flex flex-col">
              {status === "recording" ? (
                <div className="md:mt-16 max-w-full mx-8 md:h-44 rounded-xl flex justify-center items-center">
                  <VideoPreview stream={previewStream} />
                </div>
              ) : (
                <div className="max-w-full mx-8 h-80 rounded-xl bg-gray-900 flex justify-center items-center">
                  {countdownTimer && (
                    <CountdownTimer
                      onStartRecording={startRecording}
                      turnOnTimer={turnOnTimer}
                      countdownTimer={countdownTimer}
                    />
                  )}
                  {!countdownTimer && (
                    <div className="px-4 h-10  rounded-xl flex justify-center items-center space-x-2 text-white bg-gray-700">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span className="font-sans font-semibold text-white text-xs">
                        Max recording length: 10 min
                      </span>
                    </div>
                  )}
                </div>
              )}

              {/* Recording Timer */}
              {status === "recording" && (
                <div className="max-w-full mt-4 md:mt-20 mx-8 flex justify-between items-center">
                  <div className="flex space-x-1 items-center animate-pulse">
                    <div className="w-2 h-2 rounded-full bg-red-500 "></div>
                    <span className="font-sans font-medium text-gray-900 text-xs">
                      REC
                    </span>
                  </div>
                  <TestTimer timer={timer} onStopRecording={stopRecording} />
                </div>
              )}

              {/* Error message */}

              {error && (
                <div className="max-w-full mt-4 mx-8 h-10 px-4 flex space-x-2 items-center bg-red-50 rounded-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="font-sans font-medium text-red-500 text-xs">
                    {error}
                  </span>
                </div>
              )}
            </div>

            {/* Button */}

            <div className="absolute bottom-0 w-full border-t border-gray-200 p-4">
              {!(status === "recording") && (
                <button
                  onClick={() => {
                    turnOnCountdownTimer();
                  }}
                  className="w-full h-10 rounded-full flex space-x-3 justify-center items-center bg-blue-600 hover:bg-blue-700"
                >
                  <div className="w-5 h-5 rounded-full bg-white flex justify-center items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-600"></div>
                  </div>
                  <span className="font-sans font-semibold text-white text-sm">
                    Start recording
                  </span>
                </button>
              )}
              {status === "recording" && (
                <button
                  onClick={() => {
                    turnOffTimer();
                    turnOffCountdownTimer();
                    stopRecording();
                  }}
                  className="w-full h-10 rounded-full flex space-x-3 justify-center items-center bg-red-600 hover:bg-red-700"
                >
                  <div className="w-5 h-5 rounded-full bg-white flex justify-center items-center">
                    <div className="w-2 h-2 rounded-sm bg-red-600"></div>
                  </div>
                  <span className="font-sans font-semibold text-white text-sm">
                    Stop recording
                  </span>
                </button>
              )}
            </div>
          </div>
        )}
      />

      {/* End of React Media Recorder Component */}
    </div>
  );
};

export default RecordQuestionScreen;
