// Library Imports
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router-dom";
import ta from "time-ago";

// Component Imports
import VideoCard from "../../components/VideoCard";
import VideoLoader from "../../components/VideoLoader";
import NewInterviewBtn from "../../components/NewInterviewBtn";

const Dashboard = ({ folder, openDialog, loading }) => {
  const navigate = useNavigate();
  console.log(loading);
  return (
    <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
      <main className="flex-1 mt-16 md:mt-0 py-8 px-6 md:py-40 md:px-32 2xl:px-60">
        <div className="hidden md:flex fixed top-5 right-12  space-x-2">
          <>
            <button
              onClick={() => openDialog()}
              data-tip="Delete folder"
              className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-900 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
            <ReactTooltip
              effect="solid"
              backgroundColor="#475569"
              className="tooltip"
              arrowColor="transparent"
            />
          </>
          <NewInterviewBtn />
        </div>
        <div className="w-full">
          <div className="flex flex-col space-y-4 md:mb-20 mb-4">
            <div className="font-sans text-sm font-medium text-gray-400">
              <span
                className="hover:text-gray-600 cursor-pointer"
                onClick={() => navigate("/interviews")}
              >
                Personal Library
              </span>
              &nbsp; / &nbsp;
              <span>{folder.name}</span>
            </div>
            <div className="flex">
              <h1 className="font-sans text-3xl font-semibold text-gray-900">
                {folder.name}
              </h1>
              <div className="w-full flex md:hidden justify-end">
                <button
                  onClick={() => openDialog()}
                  data-tip="Delete folder"
                  className="h-10 px-4 rounded-full flex justify-center items-center hover:bg-gray-100 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-900 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
                <ReactTooltip
                  effect="solid"
                  backgroundColor="#475569"
                  className="tooltip"
                  arrowColor="transparent"
                />
                 <NewInterviewBtn />
              </div>
            </div>
          </div>
          {/* Interview Container */}
          <div className="w-full flex flex-col">
            <div className="w-full pb-4 border-b border-gray-100 flex justify-start font-sans font-medium text-gray-900">
              Interviews
            </div>

            {/* Data is loading */}
            {loading && <VideoLoader />}

            {/* Data has loaded and there are not interviews */}
            {!loading && folder.interviews && folder.interviews.length < 1 && (
              <div className="w-full bg-primary rounded-md md:py-32 py-20 px-4 mt-4">
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 text-gray-900 mb-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                    />
                  </svg>
                  <span className="font-sans text-lg text-gray-900 font-semibold mb-2">
                    This folder is currently empty.
                  </span>
                  <span className="font-sans text-sm text-gray-500 font-normal mb-8">
                    Move an interview into this folder to orginize your work.
                  </span>
                  <a
                    href="https://app.usehaste.io/answer/61d590ab644e14c90061c0c7"
                    target="_blank"
                  ></a>
                </div>
              </div>
            )}

            {/* Data has loaded and there are interviews */}
            {!loading && folder.interviews && folder.interviews.length > 0 && (
              <div className="mt-4 w-full pb-20 grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-5">
                {folder.interviews.map((interview) => (
                  <VideoCard
                    title={interview.title}
                    date={ta.ago(interview.time)}
                    thumbnailImage={interview.thumbnails.image}
                    thumbnailGif={interview.thumbnails.gif}
                    url={`/interviews/${interview._id}`}
                    live={interview.live}
                    responses={interview.answerSets.length}
                  />
                ))}
              </div>
            )}

            {/*
            {folder.interviews && folder.interviews.length > 0 ? (
              <div className="mt-4 w-full pb-20 grid grid-cols-3 2xl:grid-cols-4 gap-5">
                {folder.interviews.map((interview) => (
                  <VideoCard
                    title={interview.title}
                    date={ta.ago(interview.time)}
                    thumbnailImage={interview.thumbnails.image}
                    thumbnailGif={interview.thumbnails.gif}
                    url={`/interviews/${interview._id}`}
                    live={interview.live}
                    responses={interview.answerSets.length}
                  />
                ))}
              </div>
            ) : (
              <div className="w-full bg-primary rounded-md py-32 mt-4">
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 text-gray-900 mb-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                    />
                  </svg>
                  <span className="font-sans text-lg text-gray-900 font-semibold mb-2">
                    This folder is currently empty.
                  </span>
                  <span className="font-sans text-sm text-gray-500 font-normal mb-8">
                    Move an interview into this folder to orginize your work.
                  </span>
                  <a
                    href="https://app.usehaste.io/answer/61d590ab644e14c90061c0c7"
                    target="_blank"
                  ></a>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
