import { useState } from "react";
import { Switch } from "@headlessui/react";

function Toggle({ enabled, handleEnabled }) {
  return (
    <Switch
      checked={enabled}
      onChange={() => handleEnabled()}
      className={`${
        enabled ? "bg-green-500" : "bg-gray-300"
      } relative inline-flex items-center h-5 rounded-full w-10`}
    >
      <span
        className={`${
          enabled ? "translate-x-5" : "translate-x-0"
        } inline-block w-6 h-6 transform transition ease-in-out duration-200 bg-white rounded-full border border-gray-200`}
      />
    </Switch>
  );
}

export default Toggle;
