// Components Library
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/User";
import { Toaster,toast } from "react-hot-toast";
// Function Imports
import {
  auth,
  signInWithEmailAndPassword,
  signInWithPopup,
  googleProvider,
} from "../../firebase";

import Background from "./background.jpg";


const Login = () => {
  // Variables
  const navigate = useNavigate();

  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  // context
  const { userType, changeUserType } = useContext(UserContext);

  // Function 1 - Handle email input change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Function 2 - Handle password input change
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Function 3 - Email log-in
  const emailLogin = () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      setErrorMessage("Please Enter Email Address.");
      setLoading(false);
      return;
    } else if (!emailRegex.test(email)) {
      setErrorMessage("Please Enter a Valid Email Address.");
      setLoading(false);
      return;
    } else if (password == "") {
      setErrorMessage("Please Enter Password.");
      setLoading(false);
      return;
    }

    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/users/${res.user.uid}`)
          .then((res) => res.json())
          .then((user) => {
            if ("userType" in user && user.userType === "Admin") {
              changeUserType("admin");
              localStorage.setItem("userType", "admin");
              toast.dismiss();
              navigate("/admin");
            } else if (
              user.userType === "User" &&
              user.confirmation_status == "0"
            ) {
              setErrorMessage(
                "Your account is not active. Please check the received verification email for the registered email address."
              );
            } else {
              console.log("user data",user );
              console.log("user subscriptionStatus",  user.subscriptionStatus );
              changeUserType("user");
              localStorage.setItem("userType", "user");
              toast.dismiss();
              if(user.subscriptionStatus == 'Inactive'){
                navigate("/settings");
              }else{
                navigate("/");
              }

            }
          });
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  };

  // Function 4 - Google sign-up
  const googleLogin = () => {
    setLoading(true);
    signInWithPopup(auth, googleProvider)
      .then(async (userCredentials) => {
        console.log(
          process.env.REACT_APP_SERVER_URL,
          "process.env.REACT_APP_SERVER_URL"
        );
        await fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userCredentials.user.uid,
            email: userCredentials.user.email,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            console.log(res, "res.user.userType");

            if (res.user.userType === "Admin") {
              changeUserType("admin");
              localStorage.setItem("userType", "admin");
              toast.dismiss();
              navigate("/admin");
            } else if (res.status === 300) {
              setErrorMessage(res.message);
            } else {
              console.log("user subscriptionStatus",res.user.subscriptionStatus );
              changeUserType("user");
              localStorage.setItem("userType", "user");
              toast.dismiss();
              if(res.user.subscriptionStatus == 'Inactive'){
                navigate("/settings");
              }else{
                navigate("/");
              }



            }
          });
        // navigate("/");
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-gray-900 relative px-8 lg:px-0">
      <div className="absolute bottom-16 md:bottom-8 z-50 flex justify-center space-x-10 font-sans font-medium text-xs text-gray-300">
        <a
          href="https://www.iubenda.com/privacy-policy/45330845"
          target={"_blank"}
          className="cursor-pointer hover:underline hover:text-white"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/45330845/cookie-policy"
          target={"_blank"}
          className="cursor-pointer hover:underline hover:text-white"
        >
          Cookie Policy
        </a>
        <a
          href="https://www.iubenda.com/terms-and-conditions/45330845"
          target={"_blank"}
          className="cursor-pointer hover:underline hover:text-white"
        >
          Terms & Conditions
        </a>
      </div>
      <img
        className="object-cover lg:object-stretch h-full w-full absolute inset-0 pointer-events-none"
        src={Background}
      />

      <div className="w-full flex flex-col items-center z-50">
        {/* Container */}
        <div className="w-full max-w-lg h-full bg-white rounded-3xl shadow-sm p-8 sm:max-w-sm">
          <div
            onClick={() => googleLogin()}
            className="w-full bg-white border border-gray-200 h-10 rounded-full flex space-x-2 justify-center items-center mb-8 hover:border-gray-300 cursor-pointer group"
          >
            <svg
              className="w-4 h-4"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.1712 8.36824H17.5V8.33366H9.99996V11.667H14.7095C14.0225 13.6074 12.1762 15.0003 9.99996 15.0003C7.23871 15.0003 4.99996 12.7616 4.99996 10.0003C4.99996 7.23908 7.23871 5.00033 9.99996 5.00033C11.2745 5.00033 12.4341 5.48116 13.317 6.26658L15.6741 3.90949C14.1858 2.52241 12.195 1.66699 9.99996 1.66699C5.39788 1.66699 1.66663 5.39824 1.66663 10.0003C1.66663 14.6024 5.39788 18.3337 9.99996 18.3337C14.602 18.3337 18.3333 14.6024 18.3333 10.0003C18.3333 9.44158 18.2758 8.89616 18.1712 8.36824Z"
                fill="#FBC02D"
              />
              <path
                d="M2.62756 6.12157L5.36548 8.12949C6.10631 6.29533 7.90048 5.00033 10.0001 5.00033C11.2746 5.00033 12.4342 5.48116 13.3171 6.26657L15.6742 3.90949C14.1859 2.52241 12.1951 1.66699 10.0001 1.66699C6.79923 1.66699 4.0234 3.47408 2.62756 6.12157Z"
                fill="#E53935"
              />
              <path
                d="M9.99993 18.3336C12.1524 18.3336 14.1083 17.5099 15.587 16.1703L13.0078 13.9878C12.1712 14.6215 11.1312 15.0003 9.99993 15.0003C7.83243 15.0003 5.99201 13.6182 5.29868 11.6895L2.58118 13.7832C3.96034 16.482 6.76118 18.3336 9.99993 18.3336Z"
                fill="#4CAF50"
              />
              <path
                d="M18.1713 8.36759L18.1646 8.33301H17.5H10V11.6663H14.7096C14.3796 12.5984 13.78 13.4022 13.0067 13.9876C13.0071 13.9872 13.0075 13.9872 13.0079 13.9868L15.5871 16.1693C15.4046 16.3351 18.3333 14.1663 18.3333 9.99967C18.3333 9.44092 18.2758 8.89551 18.1713 8.36759Z"
                fill="#1565C0"
              />
            </svg>

            <span className="font-sans font-medium text-gray-900 text-sm ">
              Continue with Google
            </span>
          </div>
          <div className="w-full h-px bg-gray-200 flex justify-center items-center mb-8">
            <span className="px-4 bg-white font-sans font-normal text-sm text-gray-400">
              or
            </span>
          </div>

          <span className="font-sans text-gray-900 text-xs font-medium">
            Email Address <span style={{ color: "red" }}>*</span>
          </span>
          <input
            onChange={(event) => handleEmailChange(event)}
            placeholder="johndoe@gmail.com"
            className="h-12 w-full mt-2 mb-6 border border-gray-200 rounded-xl font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
          />
          <span className="font-sans text-gray-700 text-xs font-medium">
            Password <span style={{ color: "red" }}>*</span>
          </span>
          <input
            type="password"
            onChange={(event) => handlePasswordChange(event)}
            placeholder="·························"
            className="h-12 w-full mt-2 border border-gray-200 rounded-xl font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
          />
          {errorMessage && (
            <div className="mt-6 w-full px-2 py-1 bg-red-50 border border-red-100 rounded-md font-sans font-medium text-red-500 text-xs">
              {errorMessage}
            </div>
          )}
          <button
            onClick={() => emailLogin()}
            className={`${
              loading
                ? "bg-blue-300 pointer-events-none"
                : "bg-blue-600 hover:bg-blue-700"
            } mt-8 w-full h-10  text-white font-sans font-semibold text-sm rounded-full`}
          >
            Log in
          </button>

          <div className="flex mt-4 w-full justify-center">
            <span className="font-sans text-gray-700 text-xs font-medium">

              <span
                onClick={() => navigate("/forget_password")}
                className="text-blue-500 hover:underline cursor-pointer"
              >
               Forgot Password
              </span>

            </span>
          </div>

          <div className="flex mt-4 w-full justify-center">
            <span className="font-sans text-gray-700 text-xs font-medium">
              New to Haste?{" "}
           {/*   <span
                onClick={() => navigate("/register")}
                className="text-blue-500 hover:underline cursor-pointer"
              >
                Create an account
              </span>*/}

               <span
                onClick={() => window.location.href =     'https://buy.stripe.com/6oE6qVdkt71S59efYZ'  // 'https://buy.stripe.com/test_14k02gdcfgGXfqo4gg' free trial product link
              }
                className="text-blue-500 hover:underline cursor-pointer"
                   >
              Get started
          </span>
              .
          </span>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Login;
