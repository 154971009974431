// Library Imports
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

// Component Imports
import ErrorScreen from "./ErrorScreen";
import IntroScreen from "./IntroScreen";
import NameScreen from "./NameScreen";
import RecordQuestionScreen from "./RecordQuestionScreen";
import ReviewInterviewScreen from "./ReviewInterviewScreen";
import ReviewQuestion from "./ReviewQuestionScreen";
import SuccessScreen from "./SuccessScreen";
import ActionDialog from "../../components/ActionDialog";

import { AuthContext } from "../../context/Auth";
import { Toaster } from "react-hot-toast";

// import Background from "./background.jpg";

const Index = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [userSubscription, setUserSubscription] = useState();

  // Screen States
  const [errorScreen, setErrorScreen] = useState(false);
  const [introScreen, setIntroScreen] = useState(false);
  const [nameScreen, setNameScreen] = useState(false);
  const [recordQuestionScreen, setRecordQuestionScreen] = useState(false);
  const [reviewQuestionScreen, setReviewQuestionScreen] = useState(false);
  const [reviewInterviewScreen, setReviewInterviewScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  // Data States
  const [interviewName, setInterviewName] = useState();
  const [recordings, setRecordings] = useState([]);
  const [showIndex, setShowIndex] = useState(0);
  const [interviewId, setInterviewId] = useState();

  // Other States
  const [timer, setTimer] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [uploading, setUploading] = useState(false);

  // ----------
  // Functions
  // ----------

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/users/${currentUser.uid}`)
      .then((res) => res.json())
      .then((user) => {
        if (user.subscriptionStatus === "cancelled") {
          setErrorScreen(true);
        } else {
          setIntroScreen(true);
          setUserSubscription(user.subscriptionType);
        }
      });
  }, []);

  // Screen 1 Function
  const showNameScreen = () => {
    setIntroScreen(false);
    setNameScreen(true);
  };

  // Screen 2 Function
  const showRecordingScreen = (name) => {
    setInterviewName(name);
    setNameScreen(false);
    setRecordQuestionScreen(true);
  };

  // Screen 3 Function
  const onRecordingComplete = (blobUrl, blob) => {
    const newBlob = { blob, blobUrl, name: "" };
    setRecordings((recordings) => [...recordings, newBlob]); // Set the blob
    setRecordQuestionScreen(false); // Turn off recording screen
    setReviewQuestionScreen(true); // Turn on review screen
  };

  // Screen 4 - Button 1 - Re-take Interview
  const onRetakeAnswer = (blobUrl) => {
    setRecordings(
      recordings.filter((recording) => recording.blobUrl !== blobUrl)
    );
    setShowIndex(showIndex - 1);
  };

  // Screen 4 - Button 2 - Next Question
  const onNextQuestion = (blobUrl, questionName) => {
    const objIndex = recordings.findIndex(
      (recording) => recording.blobUrl === blobUrl
    );
    recordings[objIndex].name = questionName;

    setReviewQuestionScreen(false);
    setRecordQuestionScreen(true);
    setShowIndex(showIndex + 1);
  };

  // Screen 4 - Button 3 - Review Interview
  const onReviewInterview = (blobUrl, questionName) => {
    const objIndex = recordings.findIndex(
      (recording) => recording.blobUrl === blobUrl
    );
    recordings[objIndex].name = questionName;

    setReviewQuestionScreen(false);
    setReviewInterviewScreen(true);
    setShowIndex(showIndex + 1);
  };

  // Screen 5 - Submit interview

  // Add more questions

  const addMoreQuestions = () => {
    setReviewInterviewScreen(false);
    setRecordQuestionScreen(true);
  };

  // Create a new interview
  const submitInterview = async (e) => {
    e.preventDefault();
    setUploading(true);

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/interviews`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: currentUser.uid, title: interviewName }),
      }
    );

    const data = await response.json();
    setInterviewId(data);

    for (const recording of recordings) {
      await submitIndividualQuestion(data, recording.blob, recording.name);
    }

    // await Promise.all(
    //   recordings.map(async (recording) => {
    //     await submitIndividualQuestion(data, recording.blob, recording.name);
    //   })
    // );

    setReviewInterviewScreen(false);
    setSuccessScreen(true);
    setUploading(false);
  };

  const submitIndividualQuestion = async (interviewId, blob, questionName) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/questions?interviewId=${interviewId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title: questionName }),
      }
    );

    const { url } = await response.json();

    try {
      await fetch(url, {
        method: "PUT",
        body: blob,
        headers: { "content-type": blob.type },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Countdown timer
  const turnOnCountdownTimer = () => {
    setCountdownTimer(true);
  };

  const turnOffCountdownTimer = () => {
    setCountdownTimer(false);
  };

  // Recording Length Timer
  const turnOnTimer = () => {
    setTimer(true);
  };

  const turnOffTimer = () => {
    setTimer(false);
  };

  // Go back dialog
  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => {
    setDialog(false);
  };

  const navigateToDashboard = () => {
    navigate("/");
  };

  // Function to move video up
  const moveVideoUp = (index) => {
    const temp = recordings;
    const videoToMove = temp[index]; // Second video
    temp[index] = temp[index - 1];
    temp[index - 1] = videoToMove;

    setRecordings([]);
    temp.map((item) => setRecordings((recordings) => [...recordings, item]));
  };

  const moveVideoDown = (index) => {
    const temp = recordings;
    const videoToMove = temp[index]; // Second video
    temp[index] = temp[index + 1];
    temp[index + 1] = videoToMove;

    setRecordings([]);
    temp.map((item) => setRecordings((recordings) => [...recordings, item]));
  };

  return (
    <>
   
    <div className="w-screen h-screen relative flex bg-gray-800 justify-center items-center slide-in">
      <ActionDialog
        title={"Go back to dashboard"}
        subtitle={
          "If you continue, all information about this interview will be lost permanently."
        }
        isOpen={dialog}
        closeDialog={closeDialog}
        btnTitle={"Continue"}
        btnStyle={"bg-blue-600 hover:bg-blue-700"}
        action={navigateToDashboard}
      />
      <Toaster position="top-center" reverseOrder={false} />
      {!successScreen && (
      <div className="flex space-x-2 absolute top-4 left-4 items-center">
        <div
          onClick={() => openDialog()}
          className=" text-white px-4 h-9 rounded-full bg-gray-700 cursor-pointer flex space-x-1 items-center hover:bg-gray-600 z-30"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 17l-5-5m0 0l5-5m-5 5h12"
            />
          </svg>
          <span className="font-sans font-medium text-gray-200 text-xs">
            Dashboard
          </span>
        </div>
        <>
          <div
            data-tip="Start over"
            onClick={() => window.location.reload(true)}
            className="px-4 h-9 flex justify-center items-center rounded-full hover:bg-gray-700 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            >
              <path d="M3 2v6h6"></path>
              <path d="M3 13a9 9 0 103-7.7L3 8"></path>
            </svg>
          </div>
          <ReactTooltip
            effect="solid"
            backgroundColor="#475569"
            className="tooltip"
            arrowColor="transparent"
          />
        </>
      </div>
    )}
      <div className="w-11/12 md:w-full mt-5 md:mt-0 max-w-md h-auto question-container-height bg-white rounded-3xl shadow-sm relative z-50">
        {errorScreen && <ErrorScreen />}
        {introScreen && (
          <IntroScreen
            showNameScreen={showNameScreen}
            userSubscription={userSubscription}
          />
        )}
        {nameScreen && (
          <NameScreen
            showRecordingScreen={showRecordingScreen}
            userSubscription={userSubscription}
          />
        )}
        {recordQuestionScreen && (
          <RecordQuestionScreen
            onRecordingComplete={onRecordingComplete}
            countdownTimer={countdownTimer}
            turnOnCountdownTimer={turnOnCountdownTimer}
            turnOffCountdownTimer={turnOffCountdownTimer}
            turnOnTimer={turnOnTimer}
            turnOffTimer={turnOffTimer}
            timer={timer}
            recordings={recordings}
            userSubscription={userSubscription}
          />
        )}
        {reviewQuestionScreen && (
          <ReviewQuestion
            recordings={recordings}
            showIndex={showIndex}
            onReviewInterview={onReviewInterview}
            onRetakeAnswer={onRetakeAnswer}
            onNextQuestion={onNextQuestion}
            userSubscription={userSubscription}
          />
        )}
        {reviewInterviewScreen && (
          <ReviewInterviewScreen
            recordings={recordings}
            interviewName={interviewName}
            submitInterview={submitInterview}
            uploading={uploading}
            addMoreQuestions={addMoreQuestions}
            onRetakeAnswer={onRetakeAnswer}
            moveVideoUp={moveVideoUp}
            moveVideoDown={moveVideoDown}
          />
        )}
        {successScreen && <SuccessScreen interviewId={interviewId} />}
      </div>
    </div>
    </>
  );
};

export default Index;
