import AnswersCard from "./AnswersCard";
import InterviewsCard from "./InterviewsCard";
import NoteCard from "./NoteCard";

const Dashboard = ({
  note,
  edit,
  toggleEdit,
  editNote,
  updateNote,
  interviews,
  answerSets,
  subscriptionStatus,
  userType,
}) => {
  return (
    <div className="md:pl-64 flex flex-col flex-1 min-h-screen bg-gray-50">
      <main className="flex-1 mt-16 md:mt-0 py-8 md:py-16 px-6 md:px-24 2xl:px-80">
        <div className="w-full">
          <div className="hidden flex flex-col space-y-4 mb-20">
            <h1 className="font-sans text-3xl font-semibold text-gray-900"></h1>
          </div>
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col space-y-1 justify-start ">
              <span className="font-sans font-semibold text-gray-900 text-2xl">
                Hey there,{" "}
                <span className="text-blue-600 ">
                  {" "}
                  welcome to your Haste dashboard.
                </span>
              </span>
              {userType!='admin' ? <span className="font-sans font-semibold text-gray-900 text-2xl">
                Here is a quick snapshot of how your interviews are doing.
              </span> : '' }
            </div>
            {userType!='admin' ?
            <div className="md:h-96 overflow-visible mt-14 md:grid md:grid-cols-3 gap-5">
              <NoteCard
                note={note}
                edit={edit}
                toggleEdit={toggleEdit}
                editNote={editNote}
                updateNote={updateNote}
              />
              <InterviewsCard
                interviews={interviews}
                subscriptionStatus={subscriptionStatus}
              />
              <AnswersCard answerSets={answerSets} />
            </div>
            : "" }
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
