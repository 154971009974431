// Components Library
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/User";



// Function Imports
import {
  auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  googleProvider,
} from "../../firebase";

import Background from "./background.jpg";

import Api from "../../Api";

const ForgotPassword = () => {
  // Variables
  const navigate = useNavigate();

  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const [passwordChange, setPasswordChange] = useState(false);

  // context
  const { userType, changeUserType } = useContext(UserContext);

  // Function 1 - Handle email input change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

const api = Api();

  // Function 3 - Email log-in
  const emailLogin = async () => {

    setErrorMessage("");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      setErrorMessage("Please Enter Email Address.");
      setLoading(false);
      return;
    } else if (!emailRegex.test(email)) {
      setErrorMessage("Please Enter a Valid Email Address.");
      setLoading(false);
      return;
    }

    const userVal = await api.postApi('users/validateUser',{"email":email});

    console.log("userVal",userVal);

    if(userVal){




    setLoading(true);


    sendPasswordResetEmail(auth, email).then((res) => {
        setPasswordChange(true);
        setLoading(false);
        console.log(res)
    }).catch((error) =>{
      setErrorMessage(error.message)
      setLoading(false)
    }
    );
} else{
    setErrorMessage("Your email is not registered")
}

    // signInWithEmailAndPassword(auth, email, password)
    //   .then((res) => {
    //     fetch(`${process.env.REACT_APP_SERVER_URL}/users/${res.user.uid}`)
    //       .then((res) => res.json())
    //       .then((user) => {
    //         if ("userType" in user && user.userType === "Admin") {
    //           changeUserType("admin");
    //           localStorage.setItem("userType", "admin");
    //           navigate("/admin");
    //         } else if (
    //           user.userType === "User" &&
    //           user.confirmation_status == "0"
    //         ) {
    //           setErrorMessage(
    //             "Your account is not active. Please check the received verification email for the registered email address."
    //           );
    //         } else {
    //           changeUserType("user");
    //           localStorage.setItem("userType", "user");
    //           // navigate("/");
    //           
    //         }
    //       });
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setErrorMessage(error.message);
    //     setLoading(false);
    //   });

  };



  return (
    <div className="w-screen h-screen flex justify-center items-center bg-gray-900 relative px-8 lg:px-0">

      <img
        className="object-cover lg:object-stretch h-full w-full absolute inset-0 pointer-events-none"
        src={Background}
      />

      <div className="w-full flex flex-col items-center z-50">
        {/* Container */}
        <div className="w-full max-w-lg h-full bg-white rounded-3xl shadow-sm p-8 sm:max-w-sm">

          <div className="w-full  flex justify-center items-center mb-8">
            <h3 className="font-sans text-gray-900 text-xs md:text-base font-medium font-bold"> Forgot Password</h3>
          </div>

          <span className="font-sans text-gray-900 text-xs font-medium">
            Email Address <span style={{ color: "red" }}>*</span>
          </span>
          <input
            onChange={(event) => handleEmailChange(event)}
            placeholder="johndoe@gmail.com"
            className="h-12 w-full mt-2 border border-gray-200 rounded-xl font-sans px-4 font-medium text-gray-900 text-sm hover:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200 focus:border-blue-600"
          />

     {errorMessage && (
            <div className="mt-6 w-full px-2 py-1 bg-red-50 border border-red-100 rounded-md font-sans font-medium text-red-500 text-xs">
              {errorMessage}
            </div>
          )}

{passwordChange && (
              <div className="mt-6 w-full  py-3 px-4 bg-green-50 rounded-lg flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 fill-current text-green-700"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
                </svg>
                <span className="font-sans font-medium text-green-700 text-sm">
                  An email will be send out to you. Please click on the link to
                  reset your password.
                </span>
              </div>
            )}

          <button
            onClick={() => emailLogin()}
            className={`${
              loading
                ? "bg-blue-300 pointer-events-none"
                : "bg-blue-600 hover:bg-blue-700"
            } mt-8 w-full h-10  text-white font-sans font-semibold text-sm rounded-full`}
          >
             {passwordChange ? "Re-send" : "Reset"} Password
          </button>

          <div className="flex mt-4 w-full justify-center">
            <span className="font-sans text-gray-700 text-xs font-medium">
            Back to {" "}
              <span
                onClick={() => navigate("/login")}
                className="text-blue-500 hover:underline cursor-pointer"
              >
                Login
              </span>
              .
            </span>
          </div>

        </div>
      </div>
    </div>
  );

};

export default ForgotPassword;