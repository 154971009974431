import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-gray-50">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-20 w-20 text-red-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span className="mt-4 font-sans font-medium text-gray-900 text-lg">
        Oops. This page was not found.
      </span>
      <span className="mt-2 font-sans font-normal text-gray-600 text-sm max-w-sm text-center leading-6">
        It seems like you entered a wrong address. Head to the homepage and give
        it another try!
      </span>
      <a
        href="https://usehaste.io"
        className="mt-6 px-4 py-2 rounded-full bg-blue-600 hover:bg-blue-700 flex space-x-1"
      >
        <span className="font-sans font-semibold text-white text-xs">
          Go back to Haste
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 7l5 5m0 0l-5 5m5-5H6"
          />
        </svg>
      </a>
    </div>
  );
};

export default NotFound;
